import React, { useState } from "react";
import Base from "../Base";
import { Steps, Button, message } from "antd";
import "./StripeGuidePageContainer.css";

const { Step } = Steps;

const StripeGuidePage = () => {
  const [currentStep, setStep] = useState(0);

  const current = currentStep;

  const steps = [
    {
      title: "Stripe, c'est quoi?",
      content: "First-content",
    },
    {
      title: "Activer mon compte Stripe",
      content: "Second-content",
    },
  ];

  const next = () => {
    setStep(currentStep + 1);
  };

  const prev = () => {
    setStep(currentStep - 1);
  };

  return (
    <Base className="home-base" breadcrumb={[{ label: "Accueil", to: "/" }]}>
      <div className="page-container stand-guide center-container">
        <div>Activer les paiements sur Mafoire.com</div>
        <div className="stripe-steps-container">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      </div>
    </Base>
  );
};

export default StripeGuidePage;
