import React from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import Base from '../Base'
import PaidCarts from '../../common/carts/PaidCartsContainer'
import InProgressCarts from '../../common/carts/InProgressCartsContainer'
import './CartsPage.css'

const CartsPage = ({ match }) => (
  <Base
    customClass="no-margin"
    breadcrumb={[{ label: 'Accueil', to: '/' }, { label: 'Commandes', to: '/commandes/payees' }]}
  >
    <div className="paid-carts-page center-container">
      <div className="orders-sub-menu">
        <React.Fragment>
          <NavLink className="orders-sub-page-link left" exact to={`${match.url}/payees`}>
            Commandes payées
          </NavLink>
          <NavLink className="orders-sub-page-link" exact to={`${match.url}/en-cours`}>
            Commandes en cours
          </NavLink>
        </React.Fragment>
      </div>
      <Switch>
        <Route exact path={`${match.url}/payees`} render={() => <PaidCarts />} />
        <Route path={`${match.url}/en-cours`} render={() => <InProgressCarts />} />
      </Switch>
    </div>
  </Base>
)

export default CartsPage
