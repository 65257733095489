import { connect } from "react-redux";
import React, { useEffect, useCallback } from "react";
import { Cart, Loader } from "@mafoire/components";
import { Empty } from "antd";
import { loadPaidCarts, editCartStatus } from "../../../domains/ecommerce/actions";
import { CHF_SYMBOL, DEFAULT_CURRENCY_CODE, EURO_SYMBOL } from "../../../helpers/constants";

const PaidCarts = ({ paidCarts, loadPaidCarts, editCartStatus }) => {
    useEffect(() => {
        loadPaidCarts();
    }, []);

    const getCurrencySymbol = useCallback(cart => {
        return cart.stand.departments
            .filter(d => d.currency_code !== undefined)
            .find(d => d.currency_code !== DEFAULT_CURRENCY_CODE) !== undefined
            ? CHF_SYMBOL
            : EURO_SYMBOL;
    }, []);

    return !paidCarts ? (
        <Loader fullPage />
    ) : paidCarts.length === 0 ? (
        <Empty description="Aucune commande n'a été payée" />
    ) : (
        paidCarts.map(paidCart => (
            <Cart
                asOwner
                key={paidCart.id}
                cart={paidCart}
                updateCartStatus={value => editCartStatus(paidCart.id, value)}
                currencySymbol={getCurrencySymbol(paidCart)}
            />
        ))
    );
};

const mapStateToProps = state => ({
    paidCarts: state.ecommerce.paidCarts,
});

const mapDispatchToProps = {
    loadPaidCarts,
    editCartStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaidCarts);
