import React, { useState } from 'react'
import { Button, InputNumber } from 'antd'
import './ShippingFeesGapsForm.css'

const ShippingFeesGapsForm = ({ handleGap, setDisplayInputs, gaps, setGapsEmpty, unity }) => {

  const [startValue, setStartValue] = useState(null)
  const [endValue, setEndValue] = useState(null)
  const [feeValue, setFeeValue] = useState(null)
  const [startEmpty, setStartEmpty] = useState(false)
  const [feeEmpty, setFeeEmpty] = useState(false)

  const lastGap = gaps[gaps.length - 1]

  const handleGapInput = () => {
    handleGap(startValue, endValue, feeValue)
    setStartValue(null)
    setEndValue(null)
    setFeeValue(null)
    setDisplayInputs(false)
    setGapsEmpty(false)
  }

  const formNotComplete = () => {
    setStartEmpty(true)
    setFeeEmpty(true)
  }

  const handleCancel = () => {
    setStartValue(null)
    setEndValue(null)
    setFeeValue(null)
    setDisplayInputs(false)
  }

  const handleStartValue = e => {
    setStartValue(e)
    setStartEmpty(false)
  }

  const handleFeeValue = e => {
    setFeeValue(e)
    setFeeEmpty(false)
  }

  return (
    <div className="gap-handler">
      <div className="shipping-fees-gap-form">
        <div className="gap-bounds-values">
          <div className="bounds-value start">
            De
            <InputNumber
              onChange={event => handleStartValue(event)}
              value={startValue}
              min={unity === 'amount' ? gaps.length === 0 ? 0.01 : (lastGap.end_value + 0.01)
              : gaps.length === 0 ? 1 : (lastGap.end_value + 1)}
            />
          </div>
          <div className="bounds-value end">
            à  
            <InputNumber
              onChange={event => setEndValue(event)}
              value={endValue}
              min={startValue + 1}
            />
          </div>
        </div>
        <div className="gap-value">
          Frais
          <InputNumber
            onChange={event => handleFeeValue(event)}
            value={feeValue}
            min={0}
          />
        </div>
        <div className="gap-input-actions">
          <Button className="gap-cancel-button" onClick={handleCancel}>
            Annuler
          </Button>
          <Button 
            className="gap-validation-button"
            type="primary"
            onClick={startValue ===  null || feeValue === null ? (() => formNotComplete()) : () => handleGapInput()} >
              valider
          </Button>
        </div>
      </div>
      {startEmpty || feeEmpty ? (
        <div className="alerts-container" style={{color: 'red'}}>
          Les champs "De" et "Frais" sont obligatoires
        </div>
      ): (
        null  
      )}
    </div>
  )
}
  
  export default ShippingFeesGapsForm