import React, { useState, useEffect } from 'react'
import chevronRight from '../../../assets/images/chevron_right.png'
import chevronLeft from '../../../assets/images/chevron_left.png'
import SettingsModal from './SettingsModal'
import CatalogueModal from './CatalogueModal'
import HomeModal from './HomeModal'
import './HelpModal.css'

const HelpModal = ({ stand, showModal, avatarsArray, settings, catalogue, home }) => {
  let [slideIndex] = useState(1)
  let [countSlide, setCountSlide] = useState(1)
  useEffect(() => {
    countSlides()
  }, [])

  const changeSlide = n => {
    slideIndex = slideIndex += n
    showSlides(slideIndex)
  }

  const currentSlide = n => {
    showSlides((slideIndex = n))
  }

  const countSlides = () => {
    let modalSlides = document.getElementsByClassName('help-modal-slide')
    setCountSlide(modalSlides.length)
    showSlides(modalSlides.length)
  }

  const showSlides = n => {
    let i
    let slides = document.getElementsByClassName('help-modal-slide')
    let dots = document.getElementsByClassName('dot')

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none'
    }

    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '')
    }
    if (n > slides.length) {
      slideIndex = 1
    }
    if (n < 1) {
      slideIndex = slides.length
    }
    slides[slideIndex - 1].style.display = 'block'
    dots[slideIndex - 1].className += ' active'
  }

  return (
    <div className="help-modal">
      {settings && (
        <SettingsModal stand={stand} showModal={showModal} avatarsArray={avatarsArray} />
      )}
      {catalogue && <CatalogueModal stand={stand} showModal={showModal} />}
      {home && <HomeModal stand={stand} showModal={showModal} />}

      <div className="prev" onClick={() => changeSlide(-1)}>
        <img src={chevronLeft} alt="" style={{ height: '22px' }} />
      </div>

      <div className="next" onClick={() => changeSlide(1)}>
        <img src={chevronRight} alt="" style={{ height: '22px' }} />
      </div>

      <div id="bullet-points">
        {Array.from(Array(countSlide), (e, i) => {
          return <span key={i} className="dot" onClick={() => currentSlide(i + 1)}></span>
        })}
      </div>
    </div>
  )
}

export default HelpModal
