import axios from 'axios'
import {
    getProfile
} from './local-storage'

export const http = config =>
    new Promise((resolve, reject) => {
        const options = {
            method: config.method,
            url: `${window.process.env.APP_MAFOIRE_API_HOST}${config.url}`,
            headers: {
                ...config.headers,
                accept: 'application/json',
            },
            data: config.payload,
            validateStatus: status => status >= 200 && status < 400,
        }

        axios
            .request(options)
            .then(res => {
                // if we have a error array in the response: reject
                if (res.data.error) reject(res)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })

export const httpWithAuth = async (url, data, config = {}) => {
    const profile = getProfile()
    if (profile) {
        const options = {
            method: 'GET',
            url: `${window.process.env.APP_MAFOIRE_API_HOST}${url}`,
            data,
            validateStatus: status => status >= 200 && status < 400,
            ...config,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
                accept: 'application/json',
                ...config.headers,
                'X-Socket-ID': window.echo ? window.echo.connector.socket.id : null,
            },
        }
        return (await axios.request(options)).data
    } else {
        throw new Error("Can't access profile stored locally")
    }
}

/**
 * As `httpWithAuth` but better
 */
export const apiWithAuth = async (url, options = {}) => {
    const profile = getProfile()
    if (profile) {
        const config = {
            method: 'GET',
            url: `${window.process.env.APP_MAFOIRE_API_HOST}/api/${url}`,
            validateStatus: status => status >= 200 && status < 400,
            ...options,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
                accept: 'application/json',
                ...options.headers,
            },
        }
        return (await axios.request(config)).data
    } else {
        throw new Error("Can't access profile stored locally")
    }
}

export const apiWithoutAuth = async (url, options = {}) => {
    const config = {
        method: 'GET',
        url: `${window.process.env.APP_MAFOIRE_API_HOST}/api/${url}`,
        validateStatus: status => status >= 200 && status < 400,
        ...options,
        headers: {
            accept: 'application/json',
            ...options.headers,
        },
    }
    return (await axios.request(config)).data
}