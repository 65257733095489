import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Select, Divider, Row, Col, Button, message, Spin,Alert } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getIntent, updateCard, getCurrentCard } from '../../../domains/accounts/actions'
import {
  editStand,
  loadSubscriptionEstimate,
  loadActiveDepartments,
  editStandsAndSubs,
} from '../../../domains/stands/actions'
import { loadThemes } from '../../../domains/themes/actions'
import CardSetupForm from './editSubscription/CardSetupForm'
import StandForm from './editSubscription/standForm'
import './EditAccountSubscriptionPage.css'
import {getProfile} from '../../../helpers/local-storage'

const stripePromise = loadStripe(window.process.env.APP_STRIPE_PUBLIC_KEY)

const { Option } = Select
const deptsChildern = []
const editedStands = []

const EditAccountubScriptionPage = ({
  intent,
  stands,
  getIntent,
  updateCard,
  editStandsAndSubs,
  loadSubscriptionEstimate,
  loadActiveDepartments,
  activeDepartments,
  estimate,
  estimateLoading,
  getCurrentCard,
  paymentMethod,
  loadThemes,
  themes,
  editStand,
  account,
}) => {
  const [myStands, setMyStands] = useState(null)
  const [initialEstimate, setInitialEstimate] = useState(1)
  const [newEstimate, setNewEstimate] = useState()
  const [changeCard, setChangeCard] = useState(false)
  const [toggleChangeSubs, setToggleChangeSubs] = useState(false)
  const estimateRef = useRef(null)
  const [standToBeAdded, setStandToBeAdded] = useState({ departments: [], themes: [] })
  const [displayAddStand, setDisplayAddStand] = useState(false)

  const upper = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    loadActiveDepartments()
    getIntent()
    getCurrentCard()
    loadThemes()
  }, [])

  useEffect(() => {
    if (initialEstimate === 1 && !!estimate && estimate.HT > 0) {
      setInitialEstimate(estimate)
    }
  })

  useEffect(() => {
    setMyStands(stands)
    loadSubscriptionEstimate(activeStandsOnly(stands))
  }, [stands])

  useEffect(() => {
    if (!!activeDepartments) {
      activeDepartments.forEach((dept) => {
        deptsChildern.push(<Option key={dept.code}>{dept.name}</Option>)
      })
    }
  }, [activeDepartments])

  const handleChange = (type, id) => (value) => {
    setMyStands(
      myStands.map((stnd) => {
        if (stnd.id === id) {
          if (type === 'departments') {
            stnd.departments = activeDepartments.filter((dprt) =>
              value.includes(dprt.code.toString())
            )
          } else if (type === 'pack') {
            stnd.pack = value
          }else if( type === 'status'){
            if (parseInt(stnd.status) === 3) {
              stnd.status = '0'
            } else stnd.status = 3
          }
        }
        return stnd
      })
    )
    let stand = myStands.find((stnd) => stnd.id === id)
    editedStands.push(stand)

    getEstimate(myStands)
    setNewEstimate(true)
    if (estimateRef.current) {
      estimateRef.current.focus()
    }
  }
  const activeStandsOnly = (stnds) => {
    return stnds ? stnds.filter((stnd) => stnd.status !== 3) : null
  }
  const getEstimate = (stands) => {
    loadSubscriptionEstimate(activeStandsOnly(stands))
  }

  const confirmAddStand = () => {
    if (
      !standToBeAdded.name ||
      !standToBeAdded.pack ||
      !standToBeAdded.themes ||
      !standToBeAdded.departments
    ) {
      message.error('Informations incomplètes du stand')
    } else {
      myStands.push(standToBeAdded)
      setMyStands(myStands)
      setStandToBeAdded({ departments: [] })
      setNewEstimate(true)
      getEstimate(myStands)
      if (estimateRef.current) {
        estimateRef.current.focus()
      }
    }
  }

  const confirmSubsChanges = () => {
    editStandsAndSubs(myStands)
  }

  // const toggleStandStatus = (data) => {
  //   if (data.id) {
  //     let standToBeEdited = myStands.find((stnd) => {
  //       return stnd.id === data.id
  //     })
  //     if (parseInt(standToBeEdited.status) === 3) {
  //       standToBeEdited.status = '0'
  //     } else standToBeEdited.status = 3
  //     editStand(standToBeEdited)
  //     setNewEstimate(true)
  //   }
  // }

  const isDisabled = (stnd) => {
    return parseInt(stnd.status) === 3
  }
  const profile = getProfile();

  return (
    <React.Fragment>
      {!profile.hasPayementMethod && <Alert
        message={'Pour utiliser pleinement l\'application Mafoire.com, merci de renseigner votre carte bancaire ci-dessous. '}
        banner
        closable
      />}
      <div className="add-account-wrapper center-container">
        <div className="form-title-wrapper">
          <h3>Modifier votre abonnement Mafoire.com</h3>

          <Divider orientation="left">Moyen de paiement</Divider>

          <div>
            <p>
              Il s'agit du moyen de paiement qui sera utilisé lors des prochaines échéances, vous
              pouvez le modifier à tout moment.
            </p>
            <React.Fragment>
              {paymentMethod && (
                <div>
                  <Row gutter={[20, 16]}>
                    {paymentMethod.card && (
                      <Col span={15}>
                        <p>
                          <img
                            width="20px"
                            src="https://image.flaticon.com/icons/svg/633/633611.svg"
                            alt="Méthode de paiement"
                          />
                          {'       '}
                          {upper(paymentMethod.card.brand)} - *********** {paymentMethod.card.last4} -{' '}
                          {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                        </p>
                      </Col>
                    )}
                    <Col span={2}>
                      <Button
                        onClick={() => {
                          setChangeCard(!changeCard)
                        }}
                      >
                        {' '}
                        {paymentMethod.card ? 'Changer de carte ' : 'Ajouter une carte '}
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              {paymentMethod === '' && (
                <div>
                  <Row gutter={[20, 16]}>
                    <Col span={15}>
                      <p>
                        <img
                          width="20px"
                          src="https://image.flaticon.com/icons/svg/633/633611.svg"
                          alt="Carte de crédit"
                        />{' '}
                        Vous n'avez pas encore renseigné de carte de crédit.
                      </p>
                    </Col>
                    <Col span={2}>
                      <Button
                        onClick={() => {
                          setChangeCard(!changeCard)
                        }}
                      >
                        Ajouter une carte de crédit
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              {intent && changeCard && (
                <React.Fragment>
                  <Elements stripe={stripePromise}>
                    <CardSetupForm intent={intent.client_secret} updateCard={updateCard} />
                  </Elements>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
          <Divider orientation="left">Mensualité de votre abonnement</Divider>
          {initialEstimate &&
            (estimateLoading && initialEstimate === 1 ? (
              <Spin />
            ) : (
              <Row gutter={[20, 16]}>
                <Col span={15}>
                  Votre mensualité actuelle: {isNaN(initialEstimate.TTC) ? 0 : initialEstimate.TTC}{' '}
                  € TTC {account && account.vat_free ? ' - TVA 0%' : ''}
                </Col>
                <Col span={8}>
                  <Button onClick={() => setToggleChangeSubs(!toggleChangeSubs)}>
                    {' '}
                    Modifier votre abonnement{' '}
                  </Button>
                </Col>
              </Row>
              ))}
        </div>

        {toggleChangeSubs && (
          <React.Fragment>
            {myStands &&
              myStands.map((data) => (
                <div className="stand-card-wrapper" key={data.name}>
                  <Button
                    className="desactivate-button"
                    type={isDisabled(data) ? 'default' : 'dashed'}
                    onClick={() => {
                        handleChange('status', data.id)(null)
                    }}
                    danger
                  >
                    {isDisabled(data) ? 'Activer' : 'Désactiver'}
                  </Button>

                  <Row className="first-card-row">
                    <Col className="theme-col">
                      <div className="theme-col-content">
                        <div className="item-card-block name-block">
                          <h2>{data.name}</h2>
                          <div className="item-card-block description-block">
                            {/*
                              data.description
                              */}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col className="theme-col">
                      Pack :{' '}
                      <Select
                        style={{ width: 120 }}
                        onChange={handleChange('pack', data.id)}
                        value={data.pack}
                        disabled={isDisabled(data)}
                      >
                        <Option value="unlimited">Pack Contacts + Option E-Commerce - 250€</Option>
                        <Option value="ecommerce">Pack E-Commerce - 80€</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row syle={{ marginTop: '10px' }} className="first-card-row" gutter={[20, 16]}>
                    <Col offset={5} span={3}>
                      Départements :{' '}
                    </Col>
                    <Col span={12}>
                      <Select
                        mode="multiple"
                        style={{ width: 320 }}
                        placeholder="Sélectionnez un département"
                        value={data.departments.map((dept) => dept.code)}
                        onChange={handleChange('departments', data.id)}
                        allowClear={false}
                        disabled={isDisabled(data)}
                      >
                        {deptsChildern}
                      </Select>
                    </Col>

                    <Col className="theme-col"></Col>
                  </Row>
                </div>
              ))}

            <Row>
              <div
                style={{
                  float: 'right',
                  marginBottom: '20px',
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setDisplayAddStand(true)
                  }}
                >
                  Ajouter un stand
                </Button>
              </div>
            </Row>
            <Row>
              {displayAddStand && (
                <StandForm
                  valider={confirmAddStand}
                  themes={themes}
                  activeDepartments={activeDepartments}
                  standToBeAdded={standToBeAdded}
                  setStandToBeAdded={setStandToBeAdded}
                />
              )}
            </Row>
            {newEstimate &&
              (estimateLoading ? (
                <Spin size="large" />
              ) : (
                <React.Fragment>
                  <div ref={estimateRef} tabIndex={0}>
                    {`Prévisualisation de votre prochaine mensualité type : ${estimate.HT}€ HT -  ${estimate.TTC}€ TTC `}
                    <Button onClick={confirmSubsChanges}> Confirmer les modifications</Button>
                  </div>
                </React.Fragment>
              ))}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
const mapDispatchToProps = {
  editStand,
  getIntent,
  updateCard,
  loadSubscriptionEstimate,
  loadActiveDepartments,
  getCurrentCard,
  editStandsAndSubs,
  loadThemes,
}
const mapStateToProps = (state) => ({
  intent: state.account.intent,
  stands: state.stands.stands,
  account: state.account.account,
  activeDepartments: state.stands.activeDepartments,
  estimate: state.stands.estimate,
  estimateLoading: state.stands.estimateLoading,
  paymentMethod: state.account.card,
  themes: state.themes.themes,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountubScriptionPage)
