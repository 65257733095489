import { connect } from 'react-redux'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Base from '../Base'
import EditAccountForm from '../../common/account/EditAccountForm'
import { editAccount } from '../../../domains/accounts/actions'
import { uploadMedia } from '../../../domains/medias/actions'

const EditAccountPage = ({
  account,
  businessTypes,
  editAccount,
  redirectToHomePage,
  mediaLoading,
  uploadMedia,
  editAccountIsLoading,
  currentMedia,
}) => (
  <Base
    breadcrumb={[
      { label: 'Accueil', to: '/' },
      { label: 'Mon compte', to: '/mon-compte' },
      account
        ? {
            label: 'Editer les informations du compte',
            to: `/mon-compte/${account.id}/editer`,
          }
        : {},
    ]}
  >
    <EditAccountForm
      account={account}
      businessTypes={businessTypes}
      editAccount={editAccount}
      mediaLoading={mediaLoading}
      uploadMedia={uploadMedia}
      editAccountIsLoading={editAccountIsLoading}
      currentMedia={currentMedia}
    />
    {redirectToHomePage && <Redirect to={'/mon-compte'} />}
  </Base>
)

export default connect(
  state => ({
    businessTypes: state.account.businessTypes,
    account: state.account.account,
    redirectToHomePage: state.account.redirectToHomePage,
    editAccountIsLoading: state.account.editAccountIsLoading,
    mediaLoading: state.medias.mediaLoading,
    currentMedia: state.medias.currentMedia,
  }),
  {
    editAccount,
    uploadMedia,
  }
)(EditAccountPage)
