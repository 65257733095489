import { connect } from "react-redux";
import React, { useEffect, useCallback } from "react";
import { Cart, Loader } from "@mafoire/components";
import { Empty } from "antd";
import { loadCarts } from "../../../domains/ecommerce/actions";
import { CHF_SYMBOL, DEFAULT_CURRENCY_CODE, EURO_SYMBOL } from "../../../helpers/constants";

const InProgressCarts = ({ carts, loadCarts }) => {
    useEffect(() => {
        loadCarts();
    }, []);

    const getCurrencySymbol = useCallback(cart => {
        return cart.stand.departments
            .filter(d => d.currency_code !== undefined)
            .find(d => d.currency_code !== DEFAULT_CURRENCY_CODE) !== undefined
            ? CHF_SYMBOL
            : EURO_SYMBOL;
    }, []);

    return !carts ? (
        <Loader fullPage />
    ) : carts.length === 0 ? (
        <Empty description="Vous n'avez pas encore de commande en cours" />
    ) : (
        carts.map(cart => (
            <Cart asOwner key={cart.id} cart={cart} currencySymbol={getCurrencySymbol(cart)} />
        ))
    );
};

const mapStateToProps = state => ({
    carts: state.ecommerce.carts,
});

const mapDispatchToProps = {
    loadCarts,
};

export default connect(mapStateToProps, mapDispatchToProps)(InProgressCarts);
