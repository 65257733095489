import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tag, Button, Icon, Row, Popover, Switch, Modal } from "antd";
import Stand01 from "../../../assets/images/stands/stand_01.png";
import Stand02 from "../../../assets/images/stands/stand_02.png";
import Stand03 from "../../../assets/images/stands/stand_03.png";
import Stand04 from "../../../assets/images/stands/stand_04.png";
import Stand05 from "../../../assets/images/stands/stand_05.png";
import Stand06 from "../../../assets/images/stands/stand_06.png";
import Stand07 from "../../../assets/images/stands/stand_07.png";
import Stand08 from "../../../assets/images/stands/stand_08.png";
import ShippingFeesSettings from "./ShippingFeesSettings";
import "./StandItemCard.css";
import { CHF_SYMBOL, DEFAULT_CURRENCY_CODE, EURO_SYMBOL } from "../../../helpers/constants";

const packNames = {
    lead: "Contacts",
    ecommerce: "E-commerce",
    unlimited: "Contacts avec option e-commerce",
};

const tooltipMsg = {
    0: "",
    1: "Kbis non valide.",
    2: "CGV sont à renseigner dans les documents légaux du stand.",
    3: "Kbis non valide et les CGV sont à renseigner dans les documents légaux du stand.",
};

const getStatus = statusId => {
    switch (statusId) {
        case "0":
            return (
                <span className="status-dot creating">
                    En cours de création
                    <Icon
                        className="status-icon"
                        theme="twoTone"
                        twoToneColor="#eb2f96"
                        type="build"
                    />
                </span>
            );

        case "1":
            return (
                <span className="status-dot awaiting">
                    En attente de validation
                    <Icon
                        className="status-icon"
                        theme="twoTone"
                        twoToneColor="#f9ac4d"
                        type="info-circle"
                    />
                </span>
            );

        case "2":
            return (
                <span className="status-dot active">
                    Actif
                    <Icon
                        className="status-icon"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        type="check-circle"
                    />
                </span>
            );

        case "3":
            return <span className="status-dot active">Désactivé</span>;

        default:
            return (
                <span className="status-dot creating">
                    En cours de création
                    <Icon className="status-icon" type="build" />
                </span>
            );
    }
};

const typeImages = [null, Stand01, Stand02, Stand03, Stand04, Stand05, Stand06, Stand07, Stand08];

const StandItemCard = ({ data, statusLoading, editStand, account }) => {
    const currencySymbol =
        data.departments
            .filter(d => d.currency_code !== undefined)
            .find(d => d.currency_code !== DEFAULT_CURRENCY_CODE) !== undefined
            ? CHF_SYMBOL
            : EURO_SYMBOL;

    const publish = () => {
        const stand = {
            ...data,
            status: 1,
        };
        editStand(stand);
    };

    const canBePublished = () => {
        if (account.has_ecommerce && !account.kbis_status && !data.cgv_text) {
            return 3;
        } else if (account.has_ecommerce && !data.cgv_text) {
            return 2;
        } else if (account.has_ecommerce && !account.kbis_status) {
            return 1;
        } else {
            return 0;
        }
    };

    const [deliveries, setDeliveries] = useState([]);
    useEffect(() => {
        setDeliveries(JSON.parse(data.delivery_mode));
    }, [data]);

    let typeImage = null;
    if (data.type_id) typeImage = typeImages[parseInt(data.type_id)];
    let popoverProps = {};
    if (canBePublished()) {
        popoverProps = {
            content: tooltipMsg[canBePublished()],
        };
    } else {
        popoverProps = {
            visible: false,
        };
    }

    let hasCaC = false;
    let hasDelivery = false;
    deliveries.forEach(item => {
        if (item === "clickAndCollect") hasCaC = true;
        if (item === "delivery") hasDelivery = true;
    });
    const onlyCaC = deliveries.length === 1 && deliveries[0] === "clickAndCollect";
    const onlyDelivery = deliveries.length === 1 && deliveries[0] === "delivery";
    const options = ["clickAndCollect", "delivery"];

    const changeDeliveryMode = (value, mode) => {
        let currentDeliveries;
        if (value) {
            currentDeliveries = [...deliveries, mode];
            setDeliveries(currentDeliveries);
        } else {
            currentDeliveries = deliveries.filter(e => e !== mode);
            setDeliveries(currentDeliveries);
        }
        const stand = {
            ...data,
            delivery_mode: JSON.stringify(currentDeliveries),
        };
        editStand(stand);
    };

    const [displayModal, setDisplayModal] = useState(false);

    const deliveryModes = data && JSON.parse(data.delivery_mode);
    const deliveryFinder = deliveryModes && deliveryModes.findIndex(item => item === "delivery");

    return (
        <div className="stand-card-wrapper">
            <Row className="first-card-row">
                <div className="cart-section left">
                    <div className="theme-col-content">
                        <div className="item-card-block name-block">
                            <Link to={"/stands/" + data.id}>
                                <h2>{data.name}</h2>
                            </Link>
                            <div className="item-card-block description-block">
                                {data.description}
                            </div>
                        </div>
                        <div className="item-card-block logo-block">
                            <div className="logo-image">
                                {data.image && <img src={data.image} alt={data.name} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cart-section center">
                    <div className="item-card-block stand-type">
                        <div className="logo-image">
                            {typeImage && <img src={typeImage} alt="Stand type" />}
                        </div>
                    </div>
                    <div className="item-card-block title-dep-group">
                        {data.departments.length === 0 ? (
                            <div className="info">
                                <Icon
                                    className="status-icon"
                                    theme="twoTone"
                                    twoToneColor="#f9ac4d"
                                    type="info-circle"
                                />
                                Aucun département attribué
                            </div>
                        ) : (
                            <div className="dep-tags-label-block">
                                <div  className="dep-label">Département(s) :</div>
                                {data.departments.map(dep => (
                                    <Tag className="ant-custom-tag" key={dep.id}>
                                        {dep.is_special ? dep.name : dep.code}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="cart-section right">
                    <h2>Moyen(s) de livraison</h2>
                    <div className="delivery-container">
                        <div className="delivery-status">
                            <Switch
                                value={options[0]}
                                checked={hasCaC}
                                onChange={value => changeDeliveryMode(value, options[0])}
                                disabled={onlyCaC}
                            />
                            Click & Collect
                        </div>
                        <div className="delivery-status">
                            <Switch
                                value={options[1]}
                                checked={hasDelivery}
                                onChange={value => changeDeliveryMode(value, options[1])}
                                disabled={onlyDelivery}
                            />
                            Livraison
                        </div>
                    </div>
                    {data.has_ecommerce ? (
                        <div className="shipping-fees-container">
                            <Modal
                                visible={displayModal}
                                closable={false}
                                footer={null}
                                centered={true}
                                className="shipping-fees-modal"
                            >
                                <ShippingFeesSettings
                                    stand={data}
                                    setDisplayModal={setDisplayModal}
                                />
                            </Modal>
                            <div
                                className="shipping-fees-label"
                                style={!data.shipping_fee ? { justifyContent: "flex-start" } : null}
                            >
                                <div className="h3-container">
                                    <h3>Frais de livraison</h3>
                                    {data.shipping_fee ? (
                                        <span className="fees-set">Configurés</span>
                                    ) : null}
                                </div>
                                <Button
                                    className="fees-button"
                                    onClick={() => setDisplayModal(true)}
                                    disabled={deliveryFinder === -1 ? true : false}
                                    style={
                                        deliveryFinder === -1
                                            ? {
                                                  backgroundColor: "rgb(242, 242, 242)",
                                                  color: "rgb(172, 172, 172)",
                                              }
                                            : null
                                    }
                                >
                                    {data.shipping_fee ? "Modifier" : "Configurer"}
                                </Button>
                            </div>
                            {data.shipping_fee ? (
                                <div className="fees-settings">
                                    <div className="fees-item">
                                        <span className="fees-item-label">
                                            Unité de référence :{" "}
                                        </span>
                                        <span className="fees-item-value">
                                            {data.shipping_fee.unity === "amount"
                                                ? "montant du panier"
                                                : "quantité"}
                                        </span>
                                    </div>
                                    <div className="fees-item">
                                        <span>Nature des frais : </span>
                                        <span>
                                            {data.shipping_fee.fee === "fixed"
                                                ? "fixes"
                                                : "pourcentage"}
                                        </span>
                                    </div>
                                    <div className="fees-list">
                                        <span className="fees-item">Intervalles :</span>
                                        {data.shipping_fee.gaps.map(gap => {
                                            return (
                                                <div className="gap" key={gap.id}>
                                                    {!gap.end_value ? (
                                                        <React.Fragment>
                                                            <span>
                                                                À partir de {gap.start_value}{" "}
                                                                {data.shipping_fee.unity ===
                                                                "amount"
                                                                    ? currencySymbol
                                                                    : "articles"}
                                                                ,{" "}
                                                            </span>
                                                            {!gap.fees_value ? (
                                                                <span>
                                                                    la livraison est gratuite{" "}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {gap.fees_value}{" "}
                                                                    {data.shipping_fee.fee ===
                                                                    "fixed"
                                                                        ? `${currencySymbol} de frais fixes`
                                                                        : "% du montant du panier"}{" "}
                                                                </span>
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <span>de {gap.start_value} </span>
                                                            <span>
                                                                à {gap.end_value}{" "}
                                                                {data.shipping_fee.unity ===
                                                                "amount"
                                                                    ? `${currencySymbol}`
                                                                    : "articles"}
                                                                ,{" "}
                                                            </span>
                                                            {!gap.fees_value ? (
                                                                <span>
                                                                    la livraison est gratuite{" "}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {gap.fees_value}{" "}
                                                                    {data.shipping_fee.fee ===
                                                                    "fixed"
                                                                        ? `${currencySymbol} de frais fixes`
                                                                        : "% du montant du panier"}{" "}
                                                                </span>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className="no-shipping-fees-alert">
                                    <InfoCircleOutlined />
                                    <span>Frais de livraison non configurés</span>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            </Row>
            <Row className="status-row">
                <div className="status-line">
                    <div className="status-block">
                        <div className="pack-text">{packNames[data.pack] || "Aucun"}</div>
                        <span className="statut-text">
                            Statut : {getStatus(data.status.toString())}
                        </span>
                    </div>

                    <div className="save-button">
                        {data.status === 0 && (
                            <Popover {...popoverProps}>
                                <Button
                                    onClick={publish}
                                    type="primary"
                                    disabled={!!canBePublished()}
                                >
                                    Publier {statusLoading === data.id && <Icon type="loading" />}
                                </Button>
                            </Popover>
                        )}
                    </div>

                    <div className="edit-button-block">
                        <div className="edit-button-label">Personnaliser votre stand</div>
                        <Button type="primary" className="custom-button">
                            <Link to={"/stands/" + data.id}>
                                <Icon type="edit" />
                            </Link>
                        </Button>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default StandItemCard;
