import React from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import InfosRequestForm from "../../common/infosRequest/InfosRequestForm";
import { infosRequestAction } from "../../../domains/profile/actions";
import Base from "../Base";

const WrappedInfosRequestForm = Form.create()(InfosRequestForm);

const InfosRequestPage = ({ infosRequestAction }) => {
    return (
        <Base
            breadcrumb={[
                { label: "Accueil", to: "/" },
                { label: "Demande d'informations", to: "/infos-request" },
            ]}
        >
            <div className="off-site-page">
                <WrappedInfosRequestForm infosRequestAction={infosRequestAction} />
            </div>
        </Base>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    infosRequestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfosRequestPage);
