import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Base from '../Base'
import { LoginPageButton } from '../../common/login/NewAccountButtons'

import './LoginPageContainer.css'

class AccountConfirmationPage extends Component {
  render() {
    return(      
      <Base 
        breadcrumb={[{ label: 'Confirmation', to: '/login/creer' }]}>
          <div class="off-site-page">
            <div class="block login-form">
              <div className="title">Votre compte a bien été créé.</div>
              <div className="form-item-title">
                <p>Vous recevrez très prochainement un email contenant vos identifiants de connexion.</p>
              </div>
              <div className="login-page-button">
                <LoginPageButton />
              </div>
            </div>
          </div>
      </Base>
    )
  }
}

export default withRouter(AccountConfirmationPage)
