import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { login } from '../../../domains/profile/actions'
import LoginForm from '../../common/login/LoginForm'
import Base from '../Base'

import './LoginPageContainer.css'

class LoginPage extends Component {
  state = {
    hasSmallScreen: window.matchMedia('only screen and (max-width: 959px)').matches,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({
      hasSmallScreen: window.matchMedia('only screen and (max-width: 959px)').matches,
    })
  }

  handleSubmit = values => {
    this.props.login(values)
  }

  saveLoginFormRef = formRef => {
    this.loginFormRef = formRef
  }

  render() {
    const { hasSmallScreen } = this.state

    return (
      <Base breadcrumb={[{ label: 'Page de connexion', to: '/login' }]}>
        <div className="off-site-page">
          <div className="block login-form">
            <div className="title">Connexion</div>
            {hasSmallScreen ? (
              <div className="connexion-warning">
                <p>
                  La résolution d'écran de votre appareil est trop étroite pour accéder correctement
                  à l'application.
                </p>
                <p>
                  Connectez-vous avec un ordinateur ou une tablette
                </p>
              </div>
            ) : (
              <LoginForm handleSubmit={this.handleSubmit} {...this.props} />
            )}
            {/* <LoginForm
              handleSubmit={this.handleSubmit.bind(this)}
              wrappedComponentRef={this.saveLoginFormRef.bind(this)}
              {...this.props}
            /> */}
          </div>
          <div className="block new-account">
            <div className="title">Vous n'avez pas encore de compte ?</div>
              <Link to="/infos-request" className="exposant-add-button">
                  Demander des informations
              </Link>
          </div>
        </div>
      </Base>
    )
  }
}

export default connect(state => ({ profile: state.profile }), {
  login,
})(LoginPage)
