import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import { Icon, Modal, Checkbox, Button } from 'antd'
import MafoireLogo from '../../../assets/images/mafoire-logo.png'
import { termsServiceAccepted } from '../../../domains/profile/actions'
import './HomePageContainer.css'

const HomePage = ({ profile, termsServiceAccepted }) => {
  const [termsServiceApproved, setTermsServiceApproved] = useState(false)
  const [notAcceptedYet, setNotAcceptedYet] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (profile && profile.account ) {
      setDisplayModal(profile.account.cgv_is_accepted === 0 ? true : false)
    }
  }, [])

  const handleCheckBox= () => {
    setTermsServiceApproved(!termsServiceApproved)
    setNotAcceptedYet(false)
  }

  const handleTermsAccepted = async () => {
    setLoading(true)
    await termsServiceAccepted()
    setLoading(false)
    setDisplayModal(false)
  }

  return (
    <Base className="home-base" breadcrumb={[{ label: 'Accueil', to: '/' }]}>
      <div className="page-container home center-container">
        <Modal
          className="terms-service-modal"
          closable={false}
          visible={displayModal}
          okText="Enregistrer"
          centered={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          <div className="agreement-section">
            <img src={MafoireLogo} alt=''/>
            Pour utiliser la plateforme, veuillez accepter les conditions générales d'utilisation de mafoire.com
             <div className="agreement-checkbox-section">
              <Checkbox onChange={() => handleCheckBox()}/>
              <div >
                <span>J'accepte les </span>
                <a
                  href="https://mafoire-medias.s3.eu-central-1.amazonaws.com/CGV_exposants_MF.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  conditions générales d'utilisation
                </a>
              </div>
            </div>
            {notAcceptedYet ? (
              <span className="error-message">Cochez la case pour enregistrer</span>
            ) : null}
          </div>
          <div className="actions-container">
            <Button loading={loading} onClick={!termsServiceApproved ? () => setNotAcceptedYet(true) : () => handleTermsAccepted()}>
              Enregistrer
            </Button>
          </div>
        </Modal>
        <div className="home-text-container">
          <h2>Bienvenue sur Mafoire.com</h2>
          <p>
            Grâce à votre application, vous allez pouvoir configurer votre stand, gérer vos
            informations personnelles, suivre vos vendeurs et leurs conversations ainsi qu'organiser
            votre espace e-commerce.
          </p>
        </div>
        <div className="steps-video-container">
          <div className="steps-container">
            <div className="step-container">
              <div className="step-icon-container">
                <span className="step-number">1</span>
                <Icon className="step-icon" type="play-square" />
              </div>
              <div className="step-text-container">
                <h2>Comment ça marche ?</h2>
                <p>
                  Prenez le temps de regarder cette courte vidéo qui explique le fonctionnement de
                  Mafoire.com.
                </p>
              </div>
            </div>
            <div className="step-container">
              <div className="step-icon-container">
                <span className="step-number">2</span>
                <Icon className="step-icon" type="shop" />
              </div>
              <div className="step-text-container">
                <h2>Personnalisation de mon stand</h2>
                <p>
                  Cliquez sur l’onglet "Configuration" du menu en haut de la page, et choisissez
                  "Stands". Vous serez guidé à toutes les étapes.
                </p>
              </div>
            </div>
            <div className="step-container">
              <div className="step-icon-container">
                <span className="step-number">3</span>
                <Icon className="step-icon" type="shopping" />
              </div>
              <div className="step-text-container">
                <h2>Activation de mon e-commerce</h2>
                <p>
                  Si vous avez choisi l'option e-commerce, vous devez activer un compte Stripe.
                  {/* <span className="stripe-modal-button">
                    Cliquez ici
                  </span>
                  pour tout savoir sur Stripe et son activation. */}
                </p>
              </div>
            </div>
          </div>
          <div className="video-container">
            <iframe
              className="home-video"
              width="480"
              height="270"
              src="https://www.youtube.com/embed/vGpS_-0s8Os"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="Vidéo de présentation"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default connect(
  state => ({
    profile: state.profile.profile,
  }),
  {termsServiceAccepted}
)(HomePage)
