import React from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import CardSection from './CardSection'
import { message } from 'antd'
import { getProfile } from '../../../../helpers/local-storage'

export default function CardSetupForm({ intent, updateCard }) {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const result = await stripe.confirmCardSetup(intent, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Mafoire.com',
        },
      },
    })

    if (result.error) {
      message.error(result.error.message)
     } else {
      updateCard(result.setupIntent.payment_method).then((_)=>{
        let profile = getProfile();
        profile = {
          ...profile,
          hasPayementMethod: true,
        }
        localStorage.setItem('profile', JSON.stringify(profile))
        window.location.reload()
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button
        style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}
        className="ant-btn ant-btn-primary"
        disabled={!stripe}
      >
        Confirmer
      </button>
    </form>
  )
}
