import {
    httpWithAuth
} from '../helpers/http'

export const getAgents = () => httpWithAuth(`/api/agents`)

export const updateAgent = formDataAgent =>
    httpWithAuth(`/api/agents/${formDataAgent.get('id')}`, formDataAgent, {
        method: 'POST',
    })

export const createAgent = formDataAgent =>
    httpWithAuth(`/api/agents`, formDataAgent, {
        method: 'POST',
    })