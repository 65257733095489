import {
    LOGIN_REQUEST,
    LOGIN_ERROR,
    LOAD_PROFILE_REQUEST,
    LOAD_PROFILE_SUCCESS,
    PROFILE_EDITED,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_ERROR,
    PROFILE_HOME_LOADED,
    TERMS_SERVICE_ACCEPTED,
    INFOS_REQUEST_SEND
} from './actionTypes'

const defaultState = {
    profile: {},
    isLoging: false,
    isSigninUp: false,
    isSaved: true,
    credentialsSaving: false,
    contactInformationsSaving: false,
    isAuthenticated: false,
    redirectToHomePage: false,
    connexionFormIsLoading: false,
    infosRequestIsSending: null
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_PROFILE_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                    isAuthenticated: false,
                    isLoging: true,
                }
            }

        case LOAD_PROFILE_SUCCESS:
            {
                return {
                    ...state,
                    isSaved: true,
                    isLoging: false,
                    isAuthenticated: true,
                    profile: payload,
                }
            }

        case LOGIN_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                    isLoging: true,
                    isAuthenticated: false,
                    message: false,
                }
            }

        case LOGIN_ERROR:
            {
                return {
                    ...state,
                    isSaved: true,
                    isLoging: false,
                    isAuthenticated: false,
                    message: payload,
                }
            }

        case LOGOUT_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                }
            }

        case LOGOUT_SUCCESS:
            {
                return {
                    ...state,
                    profile: {},
                    isSaved: true,
                    isAuthenticated: false,
                }
            }

        case SUBSCRIPTION_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                    isSigninUp: true,
                    isAuthenticated: false,
                    message: false,
                }
            }

        case SUBSCRIPTION_SUCCESS:
            {
                return {
                    ...state,
                    isSaved: true,
                    isSigninUp: false,
                    isAuthenticated: true,
                }
            }

        case SUBSCRIPTION_ERROR:
            {
                return {
                    ...state,
                    isSaved: true,
                    isSigninUp: false,
                    isAuthenticated: false,
                    message: payload,
                }
            }

        case PROFILE_HOME_LOADED:
            {
                return {
                    ...state,
                    redirectToHomePage: false,
                }
            }

        case PROFILE_EDITED:
            {
                return {
                    ...state,
                    isSaved: true,
                    profile: payload,
                    redirectToHomePage: true,
                    connexionFormIsLoading: false,
                }
            }

        case TERMS_SERVICE_ACCEPTED:
            {
                let profile = state.profile
                profile.account["cgv_is_accepted"] = 1
                return {
                    ...state,
                    profile
                }
            }

        case INFOS_REQUEST_SEND:
            return {
                ...state
            }

        default:
    }

    return state
}