import React from 'react'
import PdfIcon from '../assets/images/pdf-icon.svg'
import DocIcon from '../assets/images/doc-icon.svg'

const checkFile = (file, message, filetypesAllowed) => {
    const isImage =
        file.type.indexOf('image/png') !== -1 ||
        file.type.indexOf('image/jpg') !== -1 ||
        file.type.indexOf('image/jpeg') !== -1 ||
        file.type.indexOf('image/gif') !== -1
    const isPdf = file.type.indexOf('pdf') !== -1
    const isVideo = file.type.indexOf('video') !== -1
    const isDoc = file.type.indexOf('doc') !== -1
    const isImageAllowed = filetypesAllowed.indexOf('image') !== -1
    const isPdfAllowed = filetypesAllowed.indexOf('pdf') !== -1
    const isVideoAllowed = filetypesAllowed.indexOf('video') !== -1
    const isDocAllowed = filetypesAllowed.indexOf('doc') !== -1

    const isAMatch =
        (isImage && isImageAllowed) ||
        (isPdf && isPdfAllowed) ||
        (isVideo && isVideoAllowed) ||
        (isDoc && isDocAllowed)

    if (!isImage && isImageAllowed && !isPdfAllowed && !isVideoAllowed && !isDocAllowed) {
        message.error('Vous ne pouvez uploader que des images')
    }
    if (!isImage && !isPdf && !isVideo && !isDoc && isPdfAllowed && isVideoAllowed && isDocAllowed) {
        message.error('Format de fichier non autorisé')
    }
    if (!isVideo && isVideoAllowed && !isPdfAllowed && !isImageAllowed) {
        message.error('Vous ne pouvez uploader que des vidéos')
    }
    const sizeLimit = file.size / 1024 / 1024 < window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT
    if (!sizeLimit) {
        message.error(
            'Vous ne pouvez pas uploader de fichiers dont le poids dépasse ' +
            window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT +
            'Mo.'
        )
    }
    return isAMatch && sizeLimit
}

const isImage = url => {
    const lowerCasedUrl = url.toLowerCase()
    return ['.jpeg', '.jpg', '.png', '.gif'].some(extension => lowerCasedUrl.includes(extension))
}

const isVideo = url => {
    const lowerCasedUrl = url.toLowerCase()
    return ['.mp4', '.avi', '.3gp', '.mpeg', '.ogg', '.mov', '.qt'].some(extension =>
        lowerCasedUrl.includes(extension)
    )
}

const isPdf = url => url.toLowerCase().includes('.pdf')

const isDoc = url => {
    const lowerCasedUrl = url.toLowerCase()
    return ['.ppt', '.doc', '.docx', '.pptx', '.odt'].some(extension =>
        lowerCasedUrl.includes(extension)
    )
}

const getFileExtension = fileName => fileName.match(/\.[0-9a-z]+$/i)[0].substring(1)

/**
 *
 * @param {*} videoUrl
 * @returns Promise<string>
 */
const createThumbnailUrl = videoUrl => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')
    video.src = videoUrl
    return new Promise(resolve => {
        video.addEventListener('loadeddata', () => {
            canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            return resolve(canvas.toDataURL())
        })
    })
}

const renderMediaPreview = (media, isLocalMedia = false) => {
    if (isImage(isLocalMedia ? media.name : media.url))
        return <img src = {
            isLocalMedia ? media.url : media.url
        }
    alt = {
        media.name
    }
    />
    if (isVideo(isLocalMedia ? media.name : media.url) && media.thumbnail_url)
        return <img src = {
            isLocalMedia ? media.thumbnail_url : media.thumbnail_url
        }
    alt = {
        media.name
    }
    />
    if (isPdf(isLocalMedia ? media.name : media.url))
        return <img className = "icon"
    src = {
        PdfIcon
    }
    alt = {
        media.name
    }
    />
    if (isDoc(isLocalMedia ? media.name : media.url))
        return <img className = "icon"
    src = {
        DocIcon
    }
    alt = {
        media.name
    }
    />
}

/**
 * Get the value of the seeked param from the current location if not specified
 * @param {string} seekedParam
 * @param {Object} location
 * @returns {(string|undefined)} Returns the value or undefined if param doesn't exists
 */
const getURLParam = (seekedParam, location = window.location) => {
    for (let param of location.search.substr(1).split('&')) {
        const [key, val] = param.split('=')
        if (key === seekedParam) return val
    }
}

/**
 * Returns a date object with year month or day added or substracted depending on options
 * @param {Date} date
 * @param {Object} options
 */
const changeDate = (date, options) => {
    let d = new Date(date)
    Object.entries(options).forEach(([k, v]) => {
        if (k === 'year' || k === 'years') {
            d = new Date(d.setFullYear(date.getFullYear() + v))
        } else if (k === 'month' || k === 'months') {
            d = new Date(d.setMonth(date.getMonth() + v))
        } else if (k === 'day' || k === 'days') {
            d = new Date(d.setDate(date.getDate() + v))
        }
    })
    return d
}

const formatDate = date => {
    if (typeof date === 'string') date = new Date(date)
    const y = String(date.getFullYear())
    const m = String(date.getMonth() + 1)
    const d = String(date.getDate())
    return `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`
}

const isSubscribed = data =>
    !!data && !!data.subscribed_until && new Date(data.subscribed_until) > new Date()

export {
    checkFile,
    isImage,
    isVideo,
    isPdf,
    isDoc,
    getFileExtension,
    createThumbnailUrl,
    renderMediaPreview,
    getURLParam,
    changeDate,
    formatDate,
    isSubscribed,
}