import React from 'react'
import { connect } from 'react-redux'
import { StandContact } from '@mafoire/components'

const StandContactContainer = ({ stand, profile, asOwner }) => (
  <StandContact stand={stand} profile={profile} disableForm={asOwner} />
)

const mapStateToProps = state => ({
  stand: state.stands.stand,
  profile: state.profile.profile,
})

export default connect(mapStateToProps)(StandContactContainer)
