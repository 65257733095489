import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Try to unregister old service workers registrations
navigator.serviceWorker &&
    navigator.serviceWorker
        .getRegistrations()
        .then(registrations => registrations.forEach(registration => registration.unregister()));

if (module.hot) {
    module.hot.accept();
}

ReactDOM.render(<App />, document.getElementById("root"));
