import React, { Component } from 'react'
import {
  Form,
  Input,
  Icon,
  Button,
  Divider,
  Checkbox,
  Row,
  Select,
  Switch,
  Upload,
  message,
  Avatar,
} from 'antd'
import { NavLink } from 'react-router-dom'
import { checkFile } from '../../../helpers/functions'
import './AgentForm.css'

const Option = Select.Option

class AddAgentForm extends Component {
  state = {
    addressCheckState: false,
    phoneCheckState: false,
    checkedStands: [],
    file: null,
    fileLocalUrl: null,
  }

  componentDidUpdate(_, prevState) {
    if (this.state.file && prevState.file !== this.state.file) {
      this.computePreview()
    }
  }

  storeFile = file => {
    this.setState({ file })
  }

  computePreview = async () => {
    this.setState(prevState => ({ fileLocalUrl: URL.createObjectURL(prevState.file) }))
  }

  handleSubmit = event => {
    event.preventDefault()
    const { file } = this.state
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const datas = { stands: this.state.checkedStands, ...values, file: file }
        this.props.addAgent(datas)
      }
    })
  }

  onChange = checkedList => {
    const checkedStands = checkedList.map(id => {
      const item = this.state.checkedStands.find(stand => stand.id === id)
      if (item) {
        return item
      }
      return { id, departments: JSON.stringify([]) }
    })
    this.setState({
      checkedStands,
    })
  }

  onSelectChange = (value, standId) => {
    const checkedStands = this.state.checkedStands.map(item => {
      if (item.id === standId) {
        return { ...item, departments: JSON.stringify(value) }
      }
      return item
    })
    this.setState({
      checkedStands,
    })
  }

  render() {
    const { form, stands, maxFileSize, newAgentIsSaving, errorMessage } = this.props
    const { file, fileLocalUrl } = this.state

    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <div className="agent-form-wrapper">
        <Form className="add-agent-form" onSubmit={this.handleSubmit} {...formItemLayout}>
          <Form.Item className="name-input" label="Nom du vendeur">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: "Veuillez renseigner le nom du vendeur'",
                },
              ],
            })(<Input type="text" placeholder="Stéphane Vendeur" autoFocus />)}
          </Form.Item>
          <span className="tip">
            <i>
            *Attention : une adresse email unique par vendeur
            </i>
          </span>
          <Form.Item label="E-mail" className="email-form-input">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: "Veuillez confirmer l'e-mail du vendeur",
                },
              ],
            })(<Input type="text" placeholder="jean-pierre@gmail.com" />)}
          </Form.Item>
          <Form.Item className="password-tip" label="Mot de passe">
            <span>
              Il sera envoyé par email au vendeur qui pourra ensuite le modifier sur son profil.
            </span>
          </Form.Item>
          <Form.Item label="Actif">
            {getFieldDecorator('status', {
              valuePropName: 'checked',
              getValueFromEvent: checked => (checked ? 1 : 0),
            })(<Switch />)}
          </Form.Item>

          <div className="picture">
            <div>
              <div className="avatar-label">Avatar:</div>
              {file && fileLocalUrl ? (
                <div className="profile-avatar">
                  <img src={fileLocalUrl} alt={file.name} />
                </div>
              ) : (
                <Avatar size={70} className="profile-avatar" icon="user" />
              )}
            </div>

            <Upload
              name="file"
              beforeUpload={image => {
                if (checkFile(image, message.error, ['image'], maxFileSize)) this.storeFile(image)
                return false
              }}
              disabled={this.state.loading}
              showUploadList={false}
              customRequest={() => false}
            >
              <Button type="primary">
                <Icon type="upload" />
                <span className="label">Télécharger un fichier</span>
              </Button>
            </Upload>
          </div>

          <Divider className="stands-bound">Stands et départements potentiels</Divider>
          <div className="checkbox-section">
            <Checkbox.Group className="checkbox-group-section" onChange={this.onChange}>
              <Row className="checkbox-section-row">
                {stands &&
                  stands.map(stand => (
                    <div key={stand.id} className="checkbox-tag-block">
                      <Checkbox value={stand.id} onChange={this.onCheckedStands}>
                        {stand.name}
                      </Checkbox>
                      <Select
                        mode="tags"
                        showArrow
                        placeholder="Attribuer un département"
                        style={{
                          display: this.state.checkedStands.some(item => stand.id === item.id)
                            ? 'block'
                            : 'none',
                        }}
                        onChange={value => this.onSelectChange(value, stand.id)}
                      >
                        {stand.departments.map(dep => (
                          <Option key={dep.id} className="stand-county-tag" value={dep.code}>
                            {dep.code} {dep.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ))}
              </Row>
            </Checkbox.Group>
          </div>
          <Form.Item {...tailFormItemLayout}>
            {errorMessage && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            <div className="add-agent-form-buttons">
              <Button className="add-agent-cancel" type="secondary">
                <NavLink to="/vendeurs">Annuler</NavLink>
              </Button>
              <Button className="add-exposant" type="primary" disabled={newAgentIsSaving} htmlType="submit">
                Enregistrer{newAgentIsSaving && <Icon type="loading" />}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default AddAgentForm
