import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Empty } from 'antd'
import Base from '../Base'
import { loadLeadsContacts, checkLeadContact } from '../../../domains/visitors/actions'
import { LeadsContactsTable, Loader } from '@mafoire/components'
import './leadsContactsPageContainer.css'

const LeadsContactsPage = ({ leadsContacts, loadLeadsContacts, checkLeadContact }) => {
  useEffect(() => {
    loadLeadsContacts()
  }, [])

  return (
    <Base
      customClass="no-margin"
      breadcrumb={[
        { label: 'Accueil', to: '/' },
        { label: 'Leads', to: '/leads' },
        { label: 'Contacts', to: '/contacts' },
      ]}
    >
      <div className="visitors-page center-container">
        {!leadsContacts ? (
          <Loader fullPage />
        ) : leadsContacts.length === 0 ? (
          <Empty description="Vous n'avez pas encore de messages de vos leads" />
        ) : (
          <LeadsContactsTable leadsContacts={leadsContacts} checkLeadContact={checkLeadContact} />
        )}
      </div>
    </Base>
  )
}

const mapStateToProps = (state) => ({
  leadsContacts: state.visitors.leadsContacts,
})

const mapDispatchToProps = {
  loadLeadsContacts,
  checkLeadContact,
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsContactsPage)
