import {
    LOAD_THEMES_REQUEST,
    LOAD_THEMES_SUCCESS,
} from './actionTypes';

import {
    filter,
    map,
    sortBy
} from 'lodash';

const defaultState = {
    themes:  [],
    themesLoading: false,
    parentThemes: [],
    isSaved: true,
    editingThemes: [],
    themesDeleting: []
};

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action;

    switch (type) {

        case LOAD_THEMES_REQUEST:
            {
                return {
                    ...state,
                    themesLoading: true
                };
            }

        case LOAD_THEMES_SUCCESS:
            {
                if (payload && !payload.code) {
                    map(payload, item => {
                        item.isSaved = true;
                    });
                }
                const parentThemes = sortBy(filter(payload, {
                    parent_id: 0
                }), 'name');
                return {
                    ...state,
                    themes: sortBy(payload, 'name'),
                    parentThemes,
                    themesLoading: false
                };
            }

        default:
    }

    return state;
};