import React from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import { Redirect } from 'react-router-dom'
import EditConnexionAccountForm from '../../common/account/EditConnexionAccountForm'
import { editProfile } from '../../../domains/profile/actions'

const EditConnexionAccountPage = ({ redirectToHomePage, profile, editProfile }) => {
  return (
    <Base
      breadcrumb={[
        { label: 'Accueil', to: '/' },
        { label: 'Mon compte', to: '/mon-compte' },
        {
          label: 'Editer les identifiants de connexion',
        },
      ]}
    >
      <EditConnexionAccountForm
        profile={profile}
        editProfile={editProfile}
        maxFileSize={window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT}
      />
      {redirectToHomePage && <Redirect to="/mon-compte" />}
    </Base>
  )
}

export default connect(
  state => ({
    profile: state.profile.profile,
    redirectToHomePage: state.profile.redirectToHomePage,
  }),
  {
    editProfile,
  }
)(EditConnexionAccountPage)
