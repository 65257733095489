import React from 'react'
import Echo from 'laravel-echo'
import * as io from 'socket.io-client'
import { Icon, notification } from 'antd'
import { getProfile } from './helpers/local-storage'
import ChatFeature from './domains/chat'
import history from './helpers/history'

const notify = options =>
  notification.open({
    style: {
      cursor: 'pointer',
      borderRadius: 4,
    },
    ...options,
  })

const echoListener = (action,store) => {
  const profile = getProfile()
  if (profile) {
    window.echo = new Echo({
      broadcaster: 'socket.io',
      host: window.process.env.APP_ECHO_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${profile.access_token}`,
        },
      },
      encrypted: true,
      client: io,
    })
    
    window.echo
      .private(`account:${action.payload.account_id}`)
      .listen('mediaValidated', ({ media }) => {
        // Un de vos média a été validé par un admin
        notify({
          message: 'Votre média a été validé',
          description: 'Consultez ce média sur la page du stand.',
          icon: <Icon type="check-circle" theme="twoTone" />,
          onClick: () => history.push('/stands'),
        })
      })
      .listen('mediaDeleted', ({ media }) => {
        // Un de vos média a été invalidé par un admin
        notify({
          message: "Un admin a supprimé l'un de vos médias",
          description: 'Les médias doivent être conformes aux règles de mafoire.com.',
          icon: <Icon type="close-circle" theme="twoTone" />,
        })
      })
      .listen('productValidated', ({ product }) => {
        // Un de vos produit a été validé par un admin
        notify({
          message: 'Votre produit a été validé',
          description: 'Consultez ce produit sur la page du stand.',
          icon: <Icon type="check-circle" theme="twoTone" />,
          onClick: () => history.push(`/stands/${product.stand_id}/ecommerce`),
        })
      })
      .listen('productDeleted', ({ product }) => {
        // Un de vos produit a été invalidé par un admin
        notify({
          message: "Un admin a supprimé l'un de vos produits",
          description: 'Les produits doivent être conformes aux règles de mafoire.com.',
          icon: <Icon type="close-circle" theme="twoTone" />,
        })
      })
      .listen('standCreated', ({ stand }) => {
        // Un stand a été créé par un admin
        notify({
          message: 'Un stand a été créé',
          description: 'Consultez la page du stand.',
          icon: <Icon type="plus-circle" theme="twoTone" />,
          onClick: () => history.push(`/stands/${stand.id}`),
        })
      })
      .listen('standValidated', ({ stand }) => {
        // Un de vos stand a été validé par un admin
        // Ne reçoit pas le stand
        notify({
          message: 'Votre stand a été validé',
          description: 'Consultez la page du stand.',
          icon: <Icon type="check-circle" theme="twoTone" />,
          onClick: () => history.push(`/stands/${stand.id}`),
        })
      })
      .listen('standNotValidated', ({ stand }) => {
        // Un de vos stand a été invalidé par un admin
        // Ne reçoit pas le stand
        notify({
          message: 'Votre stand a été invalidé',
          description: 'Consultez la page du stand.',
          icon: <Icon type="close-circle" theme="twoTone" />,
          onClick: () => history.push(`/stands/${stand.id}`),
        })
      })
      .listen('visitorMessage', payload => {
        store.dispatch(ChatFeature.actions.receiveMessage(payload));
      })
  }
}

export default echoListener
