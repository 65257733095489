import {
    apiWithAuth
} from '../helpers/http'

import {
    apiWithoutAuth
} from '../helpers/http'

export const infosRequest = data => apiWithoutAuth('exposants', {
    data,
    method: 'POST'
})

export const getBusinessTypes = () => apiWithoutAuth('accounts/businesstypes')

export const get = () => apiWithAuth('subscriptions')

export const getIntent = () => apiWithAuth('subscriptions/getIntent')

export const getCurrentDefaultSource = () => apiWithAuth('subscriptions/getCurrentSource')

export const cancel = id => apiWithAuth(`subscriptions/${id}/cancel`, {
    method: 'POST'
})

export const addExposant = (data) => apiWithoutAuth('accounts', {
    data,
    method: 'POST'
})

export const postUpdateCard = pm => apiWithAuth(`subscriptions/updateCard`, {
    method: 'POST',
    data: {
        payment_method: pm
    }
})