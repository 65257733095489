import React, { Component } from 'react'
import { Upload, Form, Input, Switch, Button, Divider, Checkbox, Row, Col, Icon } from 'antd'
import { Loader } from '@mafoire/components'
import { Link } from 'react-router-dom'
import UploadMedia from '../medias/UploadMedia'
import { renderMediaPreview } from '../../../helpers/functions'
import './EditAccountForm.css'

const { TextArea } = Input

class EditAccountForm extends Component {
  state = {
    renewalCheckState: false,
    addressCheckState: false,
    phoneCheckState: false,
    business_types_array: [],
  }
  constructor(props) {
    super(props)
    const { account } = this.props
    if (account && account.kbis_url) {
      this.state = {
        kbisFileList: [{ uid: '1', name: 'Kbis', status: 'done', url: account.kbis_url }],
        ...this.state,
      }
    }
  }

  render() {
    const {
      form,
      editAccount,
      businessTypes,
      account,
      mediaLoading,
      uploadMedia,
      editAccountIsLoading,
      currentMedia,
    } = this.props

    const { validateFields, getFieldDecorator, getFieldValue } = form

    const handleSubmit = event => {
      event.preventDefault()
      validateFields((err, values) => {
        if (!err) {
          let business_types = this.state.business_types_array
          const datas = {
            ...values,
            renewal: this.state.renewalCheckState,
            business_types,
            account_id: account.id,
            url_logo: currentMedia.url,
            kbis: this.state.kbis_file,
          }
          editAccount(datas)
        }
      })
    }

    const setAddressSwitch = () => {
      this.setState({
        addressCheckState: !this.state.addressCheckState,
      })
    }

    const setPhoneSwitch = () => {
      this.setState({
        phoneCheckState: !this.state.phoneCheckState,
      })
    }

    const onChange = checkedList => {
      this.setState({
        business_types_array: checkedList,
      })
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <div className="add-account-wrapper center-container">
        <h3>Editer les informations du compte</h3>
        {!account ? (
          <Loader fullPage />
        ) : (
          <Form className="add-account-form" onSubmit={handleSubmit} {...formItemLayout}>
            <Form.Item className="company-input" label="Entreprise">
              {getFieldDecorator('name', {
                initialValue: account.name,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner le nom de l'entreprise",
                  },
                ],
              })(<Input type="text" placeholder="Entreprise X" autoFocus />)}
            </Form.Item>
            <Form.Item className="contact-input" label="Responsable du dossier">
              {getFieldDecorator('in_charge_name', {
                initialValue: account.in_charge_name,
                rules: [
                  {
                    required: true,
                    message: 'Veuillez renseigner le nom du contact',
                  },
                ],
              })(<Input type="text" placeholder="Pierre DUBOIS" />)}
            </Form.Item>
            <Form.Item className="contact-input" label="Téléphone du responsable">
              {getFieldDecorator('in_charge_phone', {
                initialValue: account.in_charge_phone,
              })(<Input type="text" placeholder="0245678990" />)}
            </Form.Item>
            <Form.Item className="contact-input" label="Téléphone mobile du responsable">
              {getFieldDecorator('in_charge_mobile_phone', {
                initialValue: account.in_charge_mobile_phone,
              })(<Input type="text" placeholder="0645678990" />)}
            </Form.Item>
            <Form.Item className="contact-input" label="E-mail du responsable">
              {getFieldDecorator('in_charge_mail', {
                initialValue: account.in_charge_mail,
              })(<Input type="text" placeholder="pierre-dubois@gmail.com" />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                initialValue: account.description,
                rules: [
                  {
                    required: true,
                    message: 'Veuillez renseigner la description',
                  },
                ],
              })(<TextArea type="text" placeholder="Description" rows={9} />)}
            </Form.Item>
            <Divider style={{ marginTop: '50px' }}>Siège social</Divider>
            <div className="head-quarter-form-section">
              <Form.Item label="Adresse">
                {getFieldDecorator('contact_address', {
                  initialValue: account.contact_address,
                  rules: [
                    {
                      required: true,
                      message: "Veuillez renseigner l'adresse",
                    },
                  ],
                })(<Input type="text" placeholder="42, rue de l'étoile" />)}
              </Form.Item>
              <Form.Item label="Code postal">
                {getFieldDecorator('contact_zip', {
                  initialValue: account.contact_zip,
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez renseigner le code postal',
                    },
                  ],
                })(<Input type="text" placeholder="75000" />)}
              </Form.Item>
              <Form.Item label="Ville">
                {getFieldDecorator('contact_city', {
                  initialValue: account.contact_city,
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez renseigner la ville',
                    },
                  ],
                })(<Input type="text" placeholder="Paris" />)}
              </Form.Item>
              <Form.Item label="Pays">
                {getFieldDecorator('contact_country', {
                  initialValue: account.contact_country,
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez renseigner le pays',
                    },
                  ],
                })(<Input type="text" placeholder="France" />)}
              </Form.Item>
              <Form.Item label="e-mail" className="email-form-input">
                {getFieldDecorator('contact_mail', {
                  initialValue: account.contact_mail,
                  rules: [
                    {
                      required: true,
                      message: "Veuillez confirmer l'e-mail du compte",
                    },
                  ],
                })(<Input type="text" placeholder="jean-pierre@gmail.com" />)}
              </Form.Item>
              <Form.Item label="Téléphone">
                {getFieldDecorator('contact_phone', {
                  initialValue: account.contact_phone,
                })(<Input type="text" placeholder="0254378954" />)}
              </Form.Item>
              <Form.Item label="Téléphone portable">
                {getFieldDecorator('contact_mobile_phone', {
                  initialValue: account.contact_mobile_phone,
                })(<Input type="text" placeholder="0654378954" />)}
              </Form.Item>
              <Form.Item label="Fax">
                {getFieldDecorator('contact_fax', {
                  initialValue: account.contact_fax,
                })(<Input type="text" placeholder="0654378954" />)}
              </Form.Item>
              <Form.Item label="site internet">
                {getFieldDecorator('url_website', {
                  initialValue: account.url_website,
                })(<Input type="text" placeholder="ventes-dupont.com" />)}
              </Form.Item>
              <Form.Item label="Numéro SIREN">
                {getFieldDecorator('siren_number', {
                  initialValue: account.siren_number,
                })(<Input type="text" placeholder="456987456 000 14" />)}
              </Form.Item>
              <Form.Item label="Numéro TVA intracommunautaire">
                {getFieldDecorator('vat_number', {
                  initialValue: account.vat_number,
                })(<Input type="text" placeholder="FR 56 456987456" />)}
              </Form.Item>

              <Form.Item className="block-logo" label="Kbis">
                <Upload
                  name="file"
                  fileList={this.state.kbisFileList}
                  onChange={info => {
                    let kbisFileList = [...info.fileList]

                    kbisFileList = kbisFileList.slice(-1)

                    this.setState({ kbisFileList })
                  }}
                  beforeUpload={file => {
                    this.setState({
                      kbis_file: file,
                    })

                    return false
                  }}
                >
                  <Button>
                    <Icon type="upload" /> Importer votre fichier Kbis
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item label="Logo" className="block-logo">
                {getFieldDecorator('url_logo')(
                  <UploadMedia
                    label="Téléchargez votre fichier"
                    filetypesAllowed={['image']}
                    uploadMedia={uploadMedia}
                    isMediaLoading={mediaLoading}
                    targetId={account.id}
                  />
                )}
                <div className="logo-text-wrapper">
                  <div className="account-logo-wrapper">
                    {!currentMedia && !account.url_logo ? (
                      <div className="no-logo-info">
                        <Icon
                          className="info-circle-logo"
                          type="info-circle"
                          theme="twoTone"
                          twoToneColor="#f49e42"
                        />
                        Vous n'avez pas encore de logo
                      </div>
                    ) : (
                      (currentMedia && (
                        <div className="edit-account-logo">{renderMediaPreview(currentMedia)}</div>
                      )) ||
                      (account.url_logo && (
                        <img className="edit-account-logo" src={account.url_logo} alt="logo" />
                      ))
                    )}
                  </div>
                </div>
              </Form.Item>
            </div>
            <Divider style={{ marginBottom: '40px', marginTop: '40px' }}>Agence</Divider>
            <div className="agency-form-section">
              <div className="same-address-section">
                <span className="same-address-section-text">Même adresse que le siège social</span>
                <Switch defaultChecked={false} onChange={setAddressSwitch} />
              </div>
              <Form.Item label="Adresse">
                {getFieldDecorator('agency_contact_address', {
                  initialValue:
                    this.state.addressCheckState === true
                      ? getFieldValue('contact_address')
                      : account.agency_contact_address,
                })(<Input type="text" placeholder="42, rue de l'étoile" />)}
              </Form.Item>
              <Form.Item label="Code postal">
                {getFieldDecorator('agency_contact_zip', {
                  initialValue:
                    this.state.addressCheckState === true
                      ? getFieldValue('contact_zip')
                      : account.agency_contact_zip,
                })(<Input type="text" placeholder="75000" />)}
              </Form.Item>
              <Form.Item label="Ville">
                {getFieldDecorator('agency_contact_city', {
                  initialValue:
                    this.state.addressCheckState === true
                      ? getFieldValue('contact_city')
                      : account.agency_contact_city,
                })(<Input type="text" placeholder="Paris" />)}
              </Form.Item>
              <Form.Item label="Pays">
                {getFieldDecorator('agency_contact_country', {
                  initialValue:
                    this.state.addressCheckState === true
                      ? getFieldValue('contact_country')
                      : account.agency_contact_country,
                })(<Input type="text" placeholder="France" />)}
              </Form.Item>
              <div className="same-phone-email-section">
                <span className="same-phone-email-section-text">
                  Mêmes coordonnées que le siège social
                </span>
                <Switch defaultChecked={false} onChange={setPhoneSwitch} />
              </div>
              <Form.Item label="e-mail" className="email-form-input">
                {getFieldDecorator('agency_contact_mail', {
                  initialValue:
                    this.state.phoneCheckState === true
                      ? getFieldValue('contact_mail')
                      : account.agency_contact_mail,
                })(<Input type="text" placeholder="jean-pierre@gmail.com" />)}
              </Form.Item>
              <Form.Item label="Téléphone">
                {getFieldDecorator('agency_contact_phone', {
                  initialValue:
                    this.state.phoneCheckState === true
                      ? getFieldValue('contact_phone')
                      : account.agency_contact_phone,
                })(<Input type="text" placeholder="0254378954" />)}
              </Form.Item>
              <Form.Item label="Téléphone portable">
                {getFieldDecorator('agency_contact_mobile_phone', {
                  initialValue:
                    this.state.phoneCheckState === true
                      ? getFieldValue('contact_mobile_phone')
                      : account.agency_contact_mobile_phone,
                })(<Input type="text" placeholder="0654378954" />)}
              </Form.Item>
              <Form.Item label="Fax">
                {getFieldDecorator('agency_contact_fax', {
                  initialValue:
                    this.state.phoneCheckState === true
                      ? getFieldValue('contact_fax')
                      : account.agency_contact_country,
                })(<Input type="text" placeholder="0654378954" />)}
              </Form.Item>
              <Divider style={{ marginBottom: '40px', marginTop: '40px' }}>
                Secteur(s) d'activité
              </Divider>
              <div className="checkbox-section">
                <Checkbox.Group
                  className="checkbox-group"
                  onChange={onChange}
                  defaultValue={account.business_types.map(business_type => {
                    return business_type.id
                  })}
                >
                  <Row className="checkbox-section-row">
                    <div className="space-container"></div>
                    <div className="cols-container">
                      {businessTypes.map(businessType => {
                        return (
                          <Col
                            className="checkbox-group-col"
                            key={businessType.id}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                          >
                            <Checkbox key={businessType.id} value={businessType.id}>
                              {businessType.name}
                            </Checkbox>
                          </Col>
                        )
                      })}
                    </div>
                  </Row>
                </Checkbox.Group>
                <div className="products-marks">
                  <Form.Item label="Marque(s)/Produit(s) représenté.e(s)">
                    {getFieldDecorator('products_marks', {
                      initialValue:
                        this.state.addressCheckState === true
                          ? getFieldValue('products_marks')
                          : account.products_marks,
                    })(<TextArea placeholder="Coca-Cola, Pepsi, Fanta..." />)}
                  </Form.Item>
                </div>
              </div>
              <Divider style={{ marginBottom: '40px', marginTop: '40px' }}>
                Packs et abonnements
              </Divider>
            </div>
            <Form.Item {...tailFormItemLayout}>
              <div className="add-account-form-buttons">
                <Button className="add-account-cancel" type="secondary">
                  <Link to="/mon-compte">Annuler</Link>
                </Button>
                <Button className="add-account" type="primary" htmlType="submit">
                  Enregistrer{editAccountIsLoading && <Icon type="loading" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    )
  }
}

export default Form.create()(EditAccountForm)
