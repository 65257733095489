import React from 'react'
import { Button, Form, Input, Select } from 'antd'

const { Option } = Select

const StandForm = ({ activeDepartments, valider, setStandToBeAdded, standToBeAdded, themes }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  return (
    <div className="stand-form">
      <Form className="exposant-form" {...formItemLayout}>
        <Form.Item label="Nom">
          <Input
            placeholder="Nom du stand"
            value={standToBeAdded.name}
            onChange={e => {
              setStandToBeAdded({
                ...standToBeAdded,
                name: e.target.value,
              })
            }}
          />
        </Form.Item>

        <Form.Item label="Offre">
          <Select
            placeholder="Offre"
            value={standToBeAdded.pack}
            onChange={e => {
              setStandToBeAdded({ ...standToBeAdded, pack: e })
            }}
          >
            <Option value="lead">Pack contacts</Option>
            <Option value="ecommerce">Pack e-commerce</Option>
            <Option value="unlimited">Pack contacts + option e-commerce</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Thématique">
          <Select
            mode="multiple"
            placeholder="Thématique"
            value={standToBeAdded.themes}
            onChange={e => {
              setStandToBeAdded({ ...standToBeAdded, themes: e })
            }}
          >
            {themes.map(theme => (
              <Option key={theme.id} value={theme.id}>
                {theme.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Départements">
          <Select
            mode="multiple"
            placeholder="Départements du stand"
            value={standToBeAdded.departments.map(dept => dept.code)}
            onChange={e => {
              let formatedDprtmnts = activeDepartments.filter(dprt =>
                e.includes(dprt.code.toString())
              )
              setStandToBeAdded({
                ...standToBeAdded,
                departments: formatedDprtmnts,
              })
            }}
          >
            {activeDepartments &&
              activeDepartments.map(dep => (
                <Option key={dep.id} value={dep.code}>
                  {dep.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        {
          <Form.Item
            wrapperCol={{
              sm: {
                span: 16,
                offset: 8,
              },
            }}
          >
            <Button onClick={valider}>Valider</Button>
          </Form.Item>
        }
      </Form>
    </div>
  )
}

export default Form.create()(StandForm)
