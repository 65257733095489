import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Empty } from 'antd'
import Base from '../Base'
import { loadVisitors } from '../../../domains/visitors/actions'
import { VisitorsTable, Loader } from '@mafoire/components'
import './VisitorsPageContainer.css'

const VisitorsPage = ({ visitors, loadVisitors }) => {
  useEffect(() => {
    loadVisitors()
  }, [])

  return (
    <Base
      customClass="no-margin"
      breadcrumb={[{ label: 'Accueil', to: '/' }, { label: 'Leads', to: '/leads' }]}
    >
      <div className="visitors-page center-container">
        {!visitors ? (
          <Loader fullPage />
        ) : visitors.length === 0 ? (
          <Empty description="Vous n'avez pas encore de lead(s)" />
        ) : (
          <VisitorsTable visitors={visitors} />
        )}
      </div>
    </Base>
  )
}

const mapStateToProps = state => ({
  visitors: state.visitors.visitors,
})

const mapDispatchToProps = {
  loadVisitors,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorsPage)
