import React, { Component } from 'react'
import { Form, Input, Button, Divider, Icon } from 'antd'
import { Link } from 'react-router-dom'
import SubscriptionForm from './SubscriptionForm'
import { addExposant } from '../../../api/subscription'
import './NewAccountForm.css'
import history from '../../../helpers/history'
import { message } from 'antd'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

class NewAccountForm extends Component {
  state = {
    addressCheckState: false,
    phoneCheckState: false,
    business_types_array: [],
    globalError: false,
    errorMessage: false,
  }

  constructor(props) {
    super(props)
    const { exposant } = this.props
    if (exposant && exposant.kbis_url) {
      this.state = {
        kbisFileList: [{ uid: '1', name: 'Kbis', status: 'done', url: exposant.kbis_url }],
        ...this.state,
      }
    }
  }

  handleSubmit = event => {
    const { form } = this.props
    const { validateFields } = form
    event.preventDefault()
    // reset global error
    if (this.state.globalError) this.setState({ globalError: false })
    this.setState({
      errorMessage: null
    })
    validateFields((err, values) => {
      if (!err) {
          // let business_types = this.state.business_types_array
          const datas = {
            // business_types,
            kbis: this.state.kbis_file,
            ...values,
          }
          const formData = new FormData()
          Object.entries(datas).forEach(([k, v]) => {
            if (typeof v === 'object' && k !== 'kbis' && v !== undefined && v !== null) {
              formData.append(k, JSON.stringify(v))
            } else if (v !== undefined && v !== null) {
              formData.append(k, v)
            }
          }) 
          const sendData = async data => {
            try {
              await addExposant(data)
              history.push('/login/creer/confirmation')
            } catch (e) {
              const error = e.response && e.response.data ? e.response.data.error : "Impossible de créer votre compte. Veuillez réessayer plus tard."
              this.setState({
                errorMessage: error
              })
              message.error(error)
            }
          }
          sendData(formData)
      } else {
        this.setState({ globalError: true })
      }
    })
  }

  setAddressSwitch = () => this.setState({ addressCheckState: !this.state.addressCheckState })

  setPhoneSwitch = () => this.setState({ phoneCheckState: !this.state.phoneCheckState })

  onChange = checkedList => this.setState({ business_types_array: checkedList })

  updateCompanyName(event) {
    this.setState({
      company_name: event.target.value,
    })
  }
  updateBrandName(event) {
    this.setState({
      brand_name: event.target.value,
    })
  }
  updateBrandNameField(event) {
    if (!this.state.brand_name || (this.props.exposant && !this.props.exposant.brand_name)) {
      this.props.form.setFieldsValue({ brand_name: event.target.value })
    }
  }


  render() {
    const {
      form,
      exposant,
      exposantsLoading,
      prospect,
      activeDepartments,
      themes,
    } = this.props

    const { getFieldDecorator, getFieldValue } = form

    return (
      <div className="exposant-form-wrapper">
        <Form className="exposant-form" onSubmit={this.handleSubmit} {...formItemLayout}>
          <Form.Item className="company-input" label="Entreprise">
            {getFieldDecorator('name', {
              initialValue: (prospect && prospect.company) || (exposant && exposant.name),
              rules: [
                {
                  required: true,
                  message: "Veuillez renseigner le nom de l'entreprise",
                },
              ],
            })(
              <Input
                type="text"
                placeholder="Entreprise X"
                onBlur={this.updateBrandNameField.bind(this)}
                onChange={this.updateCompanyName.bind(this)}
                autoFocus
              />
            )}
          </Form.Item>

          <Form.Item className="brand-name-input" label="Enseigne commerciale">
            {getFieldDecorator('brand_name', {
              initialValue: exposant && exposant.brand_name,
              rules: [
                {
                  required: true,
                  message: "Veuillez renseigner le nom de l'enseigne commerciale",
                },
              ],
            })(
              <Input
                type="text"
                placeholder="Cuisinella"
                onChange={this.updateBrandName.bind(this)}
              />
            )}
          </Form.Item>

          <Form.Item className="contact-input" label="Responsable du dossier">
            {getFieldDecorator('in_charge_name', {
              initialValue:
                (prospect && `${prospect.firstname} ${prospect.lastname}`) ||
                (exposant && exposant.in_charge_name),
              rules: [
                {
                  required: true,
                  message: 'Veuillez renseigner le nom',
                },
              ],
            })(<Input type="text" placeholder="Pierre DUBOIS" />)}
          </Form.Item>

          <Form.Item className="contact-input" label="Téléphone mobile du responsable">
            {getFieldDecorator('in_charge_mobile_phone', {
              initialValue:
                (prospect && prospect.mobile) || (exposant && exposant.in_charge_mobile_phone),
              rules: [
                {
                  required: true,
                  message:
                    "Veuillez renseigner le numéro de téléphone portable, pour que l'exposant reçoive les alertes par sms",
                },
              ],
            })(<Input type="text" placeholder="0640567890" />)}
          </Form.Item>

          <Divider className="head-quarter-bound">Siège social</Divider>

          <div className="head-quarter-form-section">
            <Form.Item label="Adresse">
              {getFieldDecorator('contact_address', {
                initialValue: exposant && exposant.contact_address,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner l'adresse de l'exposant",
                  },
                ],
              })(<Input type="text" placeholder="42, rue de l'étoile" />)}
            </Form.Item>

            <Form.Item label="Code postal">
              {getFieldDecorator('contact_zip', {
                initialValue: exposant && exposant.contact_zip,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner le code postal de l'exposant",
                  },
                ],
              })(<Input type="text" placeholder="75000" />)}
            </Form.Item>

            <Form.Item label="Ville">
              {getFieldDecorator('contact_city', {
                initialValue: exposant && exposant.contact_city,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner la ville de l'exposant",
                  },
                ],
              })(<Input type="text" placeholder="Paris" />)}
            </Form.Item>

            <Form.Item label="Pays">
              {getFieldDecorator('contact_country', {
                initialValue: exposant && exposant.contact_country,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner le pays de l'exposant",
                  },
                ],
              })(<Input type="text" placeholder="France" />)}
            </Form.Item>
          </div>

          {exposant && exposant.url_logo && (
            <React.Fragment>
              <Divider className="options-bound">Logo</Divider>
              <div className="options-section">
                <Form.Item className="upload">
                  <div className="logo-group">
                    {/* <div className="dropbox">
                      {getFieldDecorator('file', {
                        valuePropName: 'fileList',
                        getValueFromEvent: normFile,
                      })(
                        <Upload name="file" beforeUpload={beforeUpload} onChange={handleSelectedFile}>
                          <Button>
                            <Icon type="upload" />
                            <span className="label">Uploadez une image</span>
                          </Button>
                        </Upload>
                      )}
                    </div> */}
                    <div className="logo">
                      <img src={exposant.url_logo} alt="logo" />
                    </div>
                  </div>
                </Form.Item>
              </div>
            </React.Fragment>
          )}
          {!exposant && (
            <React.Fragment>
              <Divider className="connexion-bound">Informations de connexion</Divider>
              <div className="connexion-section">
                <Form.Item label="e-mail" className="email-form-input">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: "Veuillez confirmer l'e-mail du reponsable",
                      },
                    ],
                  })(<Input type="text" placeholder="jean-pierre@gmail.com" />)}
                </Form.Item>
              </div>

              <Divider className="line-business-bound">Stands et abonnement</Divider>

              <SubscriptionForm
                form={form}
                defaultStandName={getFieldValue('name')}
                activeDepartments={activeDepartments}
                themes={themes}
              />
            </React.Fragment>
          )}

          <Form.Item {...tailFormItemLayout}>
            {this.state.errorMessage && (
              <div className="email-error">
                <span className="error ">{this.state.errorMessage}</span>
              </div>
            )}
            <div className="exposant-form-buttons">
              <Button className="exposant-cancel" type="secondary">
                <Link to={'/login'}>Annuler</Link>
              </Button>
              <Button className="exposant" type="primary" htmlType="submit">
                Enregistrer{exposantsLoading && <Icon type="loading" />}
              </Button>
            </div>
            {this.state.globalError && (
              <span className="error global-error">Le formulaire contient des erreurs</span>
            )}
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create()(NewAccountForm)