import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers
} from 'redux'
import thunk from 'redux-thunk'
import ProfileFeature from './domains/profile'
import MediasFeature from './domains/medias'
import StandsFeature from './domains/stands'
import ThemesFeature from './domains/themes'
import AccountFeature from './domains/accounts'
import ProductsFeature from './domains/products'
import AgentsFeature from './domains/agents'
import UserFeature from './domains/user'
import ChatFeature from './domains/chat'
import EcommerceFeature from './domains/ecommerce'
import VisitorsFeature from './domains/visitors'
import echoListener from './echo-listener'

const customMiddleware = store => next => action => {
    switch (action.type) {
        case 'APP_STARTED':
            store.dispatch(ProfileFeature.actions.loadProfile())
            break

        case 'LOAD_PROFILE_SUCCESS':
            store.dispatch(ThemesFeature.actions.loadThemes())
            store.dispatch(MediasFeature.actions.loadMedias())
            store.dispatch(AccountFeature.actions.loadAccount(action.payload))
            store.dispatch(AccountFeature.actions.loadBusinessTypes())
            store.dispatch(AgentsFeature.actions.loadAgents())
            store.dispatch(ChatFeature.actions.loadConversations())
            echoListener(action, store)
            break

        case 'LOAD_THEMES_SUCCESS':
            store.dispatch(StandsFeature.actions.loadStands())
            break

        default:
    }

    return next(action)
}

const reducers = combineReducers({
    profile: ProfileFeature.reducer,
    medias: MediasFeature.reducer,
    stands: StandsFeature.reducer,
    themes: ThemesFeature.reducer,
    account: AccountFeature.reducer,
    products: ProductsFeature.reducer,
    agents: AgentsFeature.reducer,
    users: UserFeature.reducer,
    chat: ChatFeature.reducer,
    ecommerce: EcommerceFeature.reducer,
    visitors: VisitorsFeature.reducer,
})

const isDev = process.env.NODE_ENV === 'development'

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && isDev ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true
    }) :
    compose

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk, customMiddleware)))

export default store