import {
    CARTS_LOADED,
    PAID_CARTS_LOADED,
    STATUS_UPDATED,
} from './actionTypes'

const defaultState = {
    carts: null,
    paidCarts: null,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case CARTS_LOADED:
            return {
                ...state,
                carts: payload,
            }

        case PAID_CARTS_LOADED:
            return {
                ...state,
                paidCarts: payload,
            }

        case STATUS_UPDATED:
            return {

                ...state,
                paidCarts: state.paidCarts.map(cart => {
                    if (cart.id === payload.id)
                        return {
                            ...cart,
                            cart_status: payload.cart_status
                        }
                    return cart
                }),
            }

        default:
    }

    return state
}