import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, message } from 'antd'
import { Loader } from '@mafoire/components'
import { getURLParam } from '../../../helpers/functions'
import { retrieveStripeURLState } from '../../../helpers/stripe'
import './AccountStripeConnection.css'
import history from '../../../helpers/history'

const AccountStripeConnection = ({ account, connectStripeAccount }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (account && !account.stripe_user_id) {
      ;(async () => {
        const stripeAuthorizationCode = getURLParam('code')
        if (stripeAuthorizationCode) {
          // If there is a stripe account token in current URL
          // Get stripe state in current URL
          const stripeURLStateFromURL = getURLParam('state')
          // Get stripe URL state from sessionStorage
          let stripeURLState = retrieveStripeURLState()
          // If there is no stripe state in current URL or both are differents
          if (stripeURLStateFromURL && stripeURLStateFromURL === stripeURLState) {
            // Send the authorization code to the server
            await connectStripeAccount(account.id, stripeAuthorizationCode)
            setLoading(false)
          }
        } else {
          message.error(
            "Une erreur est survenue. Votre compte Stripe n'a pas été connecté à votre compte mafoire.com. Veuillez réessayer plus tard."
          )
          setTimeout(() => {
            history.push('/mon-compte')
          }, 1000)
        }
      })()
    }
  }, [account])

  return (
    <div className="account-stripe-connection-page center-container">
      <div>
        <h3>Connexion à Stripe</h3>

        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <p className="stripe-connection-success">
              Votre compte de facturation Stripe a bien été lié à votre compte mafoire.com !
            </p>
            <div className="actions">
              <Button>
                <a
                  href="https://dashboard.stripe.com/dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accéder au tableau de bord de Stripe
                </a>
              </Button>
              <div>
                <Link to="/mon-compte">Retour à mon compte</Link>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withRouter(AccountStripeConnection)
