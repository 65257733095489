import { connect } from 'react-redux'
import React, { Component } from 'react'
import { CheckOutlined } from  '@ant-design/icons'
import { Divider } from 'antd'
import Account from '../../common/account/Account'
import Base from '../Base'
import {
  homeLoaded,
  loadSubscriptions,
  cancelSubscription,
} from '../../../domains/accounts/actions'
import { profileHomeLoaded } from '../../../domains/profile/actions'
import './AccountPageContainer.css'

class AccountPage extends Component {
  componentDidMount() {
    this.props.homeLoaded()
    this.props.profileHomeLoaded()
    if (this.props.profile.id && this.props.profile.roles[0] === 'EXPOSANT') {
      this.props.loadSubscriptions()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile.id !== this.props.profile.id &&
      this.props.profile.roles[0] === 'EXPOSANT'
    ) {
      this.props.loadSubscriptions()
    }
  }

  render() {
    const { account, profile, subscriptions, cancelSubscription } = this.props

    return (
      <Base
        customClass="no-margin"
        breadcrumb={[
          { label: 'Accueil', to: '/' },
          { label: 'Mon compte', to: '/mon-compte' },
        ]}
      >
        <Account
          account={account}
          profile={profile}
          subscriptions={subscriptions}
          cancelSubscription={cancelSubscription}
        />
        <div className="terms-service-container">
          <Divider orientation="left">Conditions d'utilisation de mafoire.com</Divider>
          {account && account.cgv_is_accepted === 1 && (
            <div className="agreement-terms-container">
              <div className="agreement-label-container">
                <CheckOutlined />
                <span className="agreement-label"> Acceptées </span>
              </div>
              <div>
                <a
                  href="https://mafoire-medias.s3.eu-central-1.amazonaws.com/CGV_exposants_MF.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  Conditions générales d'utilisation
                </a>
              </div>
            </div>
          )}
        </div>
      </Base>
    )
  }
}

export default connect(
  (state) => ({
    profile: state.profile.profile,
    account: state.account.account,
    subscriptions: state.account.subscriptions,
    stands: state.stands.stands,
  }),
  {
    homeLoaded,
    profileHomeLoaded,
    loadSubscriptions,
    cancelSubscription,
  }
)(AccountPage)
