export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const PROFILE_EDITED = 'PROFILE_EDITED'
export const LOAD_PROFILE_ABORTED = 'LOAD_PROFILE_ABORTED'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST'
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS'
export const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR'
export const PROFILE_HOME_LOADED = 'PROFILE_HOME_LOADED'
export const TERMS_SERVICE_ACCEPTED = 'TERMS_SERVICE_ACCEPTED'
export const INFOS_REQUEST_SEND = 'INFOS_REQUEST_SEND'