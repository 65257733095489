import React from 'react'
import { Icon } from 'antd'
import AgentCard from './AgentCard'
import AddAgentSection from './AddAgentSection'
import './Agents.css'

const Agents = ({ agents, deleteAgent, agentsLoading }) => (
  <div className="center-container">
    <div className="agents-container">
      {agents.length === 0 ? (
        <div className="no-agents-wrapper">
          <AddAgentSection />
          <div className="no-agents-info">
            <Icon
              className="agents-circle-logo"
              type="info-circle"
              theme="twoTone"
              twoToneColor="#f49e42"
            />
            <span className="no-agents-text">Vous n'avez pas encore ajouté de vendeurs</span>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <AddAgentSection />
          {agents.map(agent => (
            <AgentCard
              key={agent.id}
              agent={agent}
              deleteAgent={deleteAgent}
              agentsLoading={agentsLoading}
            />
          ))}
        </React.Fragment>
      )}
    </div>
  </div>
)

export default Agents
