export const ACTIVE_DEPARTMENTS_LOADED = 'STANDS/ACTIVE_DEPARTMENTS_LOADED'
export const LOAD_STANDS_REQUEST = 'STANDS/LOAD_STANDS_REQUEST'
export const LOAD_STANDS_SUCCESS = 'STANDS/LOAD_STANDS_SUCCESS'
export const LOAD_STAND_REQUEST = 'STANDS/LOAD_STAND_REQUEST'
export const LOAD_STAND_SUCCESS = 'STANDS/LOAD_STAND_SUCCESS'
export const ADD_STAND_REQUEST = 'STANDS/ADD_STAND_REQUEST'
export const ADD_STAND_SUCCESS = 'STANDS/ADD_STAND_SUCCESS'
export const EDIT_STAND_REQUEST = 'STANDS/EDIT_STAND_REQUEST'
export const EDIT_STAND_SUCCESS = 'STANDS/EDIT_STAND_SUCCESS'
export const EDIT_MASS_STAND_SUCCESS = 'STANDS/EDIT_MASS_STAND_SUCCESS'
export const ADD_CATEGORY_SUCCESS = 'STANDS/ADD_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_SUCCESS = 'STANDS/EDIT_CATEGORY_SUCCESS'
export const MOVE_CATEGORY_SUCCESS = 'STANDS/MOVE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_SUCCESS = 'STANDS/DELETE_CATEGORY_SUCCESS'
export const UPDATE_STAND_ADDRESS = 'STANDS/UPDATE_STAND_ADDRESS'
export const DELETE_STAND_ADDRESS_REQUEST = 'STANDS/DELETE_STAND_ADDRESS_REQUEST'
export const DELETE_STAND_ADDRESS_SUCCESS = 'STANDS/DELETE_STAND_ADDRESS_SUCCESS'
export const ADD_STAND_ADDRESS = 'STANDS/ADD_STAND_ADDRESS'
export const LOAD_ESTIMATE_SUCCESS = 'STANDS/LOAD_ESTIMATE_SUCCESS'
export const LOAD_DEPARTMENTS_SUCCESS = 'STANDS/LOAD_DEPARTMENTS_SUCCESS'
export const LOAD_ESTIMATE_REQUEST = 'STANDS/LOAD_ESTIMATE_REQUEST'
export const SET_SHIPPING_FEES_SUCCESS = 'STANDS/SET_SHIPPING_FEES_SUCCESS'