import {
    httpWithAuth,
    apiWithAuth
} from '../helpers/http'

export const createMedia = media => httpWithAuth(`/api/medias`, media, {
    method: 'POST'
})

export const getDocuments = standId => httpWithAuth(`/api/stands/${standId}/documents`)

export const createDocument = (formDataDocument, progressCallback) =>
    apiWithAuth(`medias`, {
        method: 'POST',
        onUploadProgress: progressCallback,
        data: formDataDocument
    })

export const updateDocument = formDataDocument =>
    httpWithAuth(
        `/api/stands/${formDataDocument.get('stand_id')}/documents/${formDataDocument.get('id')}`,
        formDataDocument, {
            method: 'POST',
        }
    )

export const destroy = mediaId => httpWithAuth(`/api/medias/${mediaId}`, null, {
    method: 'DELETE'
})