import React, { useState } from "react";
import { connect } from "react-redux";
import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import { Button, Tabs, Radio, Popconfirm } from "antd";
import ShippingFeesGapsForm from "./ShippingFeesGapsForm";
import { addShippingFees } from "../../../domains/stands/actions";
import "./ShippingFeesSettings.css";
import { CHF_SYMBOL, DEFAULT_CURRENCY_CODE, EURO_SYMBOL } from "../../../helpers/constants";

const { TabPane } = Tabs;

const ShippingFeesSettings = ({ stand, setDisplayModal, addShippingFees }) => {
    const currencySymbol =
        stand.departments.find(d => d.currency_code !== DEFAULT_CURRENCY_CODE) !== undefined
            ? CHF_SYMBOL
            : EURO_SYMBOL;

    const [unity, setUnity] = useState(stand.shipping_fee ? stand.shipping_fee.unity : "amount");
    const [fee, setFee] = useState(stand.shipping_fee ? stand.shipping_fee.fee : "fixed");
    const [displayInputs, setDisplayInputs] = useState(false);
    const [gaps, setGaps] = useState(
        stand.shipping_fee
            ? stand.shipping_fee.gaps.sort((a, b) => a.start_value - b.start_value)
            : []
    );
    const [gapsEmpty, setGapsEmpty] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUnity = e => {
        setUnity(e.target.value);
    };

    const handleFee = e => {
        setFee(e.target.value);
    };

    const handleGap = (start_value, end_value, fees_value) => {
        const gap = { start_value, end_value, fees_value };
        setGaps(gaps => [...gaps, gap]);
    };

    const deletGap = index => {
        setGaps(gaps.filter((gap, i) => i !== index));
    };

    const gapsNotAdded = () => {
        setGapsEmpty(true);
    };

    const handleFees = async () => {
        setLoading(true);
        const payload = {
            unity,
            fee,
            gaps,
            stand_id: stand.id,
        };
        await addShippingFees(payload);
        setLoading(false);
        setDisplayModal(false);
    };

    const resetState = () => {
        setDisplayModal(false);
        setGapsEmpty(false);
        setGaps(
            stand.shipping_fee
                ? stand.shipping_fee.gaps.sort((a, b) => a.start_value - b.start_value)
                : []
        );
    };

    return (
        <div className="fees-settings-container">
            <h2>Configuration de vos frais de livraison</h2>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Vos unités de calcul" key="1">
                    <div className="calculation-unit-container">
                        <Radio.Group
                            className="unit-container unit"
                            value={unity}
                            onChange={handleUnity}
                        >
                            <h4>Unité de référence</h4>
                            <p>
                                Frais de livraison déterminées en fonction du montant du panier ou
                                de la quantité des produits du panier*.
                            </p>
                            <Radio value="amount">Montant du panier</Radio>
                            <Radio value="quantity">Quantité des articles</Radio>
                            <span className="fee-mention">
                                *Par défaut, l'unité "Montant" est sélectionnée
                            </span>
                        </Radio.Group>
                        <Radio.Group
                            className="unit-container fee"
                            value={fee}
                            onChange={handleFee}
                        >
                            <h4>Nature des frais</h4>
                            <p>Montant fixe ou pourcentage du montant du panier*.</p>
                            <Radio value="fixed">Fixe</Radio>
                            <Radio value="percent">Pourcentage du prix du panier</Radio>
                            <span className="fee-mention">
                                *Par défaut, la valeur "Fixe" est sélectionnée
                            </span>
                        </Radio.Group>
                    </div>
                </TabPane>
                <TabPane tab="Vos intervalles" key="2">
                    <div className="gaps-container">
                        <div className="gaps-intro">
                            <p>
                                Pour chaque intervalle, ajouter la valeur de départ, la valeur de
                                fin et la valeur des frais qui s’appliquent. Si vous renseignez le
                                champ "Frais" à 0, cela signifie que la livraison est gratuite. Si
                                vous ne renseignez pas le champ "à", cela signifie que vos frais
                                s'appliquent sans fin à partir de la valeur du champe "De".
                            </p>
                        </div>
                        <Button
                            className="add-gap-button"
                            type="primary"
                            onClick={() => setDisplayInputs(true)}
                        >
                            <span className="add-icon">
                                <PlusOutlined />
                            </span>{" "}
                            Ajouter un intervalle
                        </Button>
                        {displayInputs && (
                            <ShippingFeesGapsForm
                                stand={stand}
                                handleGap={handleGap}
                                setDisplayInputs={setDisplayInputs}
                                gaps={gaps}
                                setGapsEmpty={setGapsEmpty}
                                unity={unity}
                            />
                        )}
                        {gaps.length > 0 ? (
                            <div className="gaps-list">
                                {gaps.map((gap, index) => (
                                    <div className="gap" key={index}>
                                        <div className="gap-mention">
                                            <span className="gap-title">
                                                Intervalle {index + 1} :{" "}
                                            </span>
                                            {!gap.end_value ? (
                                                <React.Fragment>
                                                    <span>
                                                        À partir de {gap.start_value}{" "}
                                                        {unity === "amount"
                                                            ? currencySymbol
                                                            : "articles"}
                                                        ,{" "}
                                                    </span>
                                                    {!gap.fees_value ? (
                                                        <span>la livraison est gratuite </span>
                                                    ) : (
                                                        <span>
                                                            {gap.fees_value}{" "}
                                                            {fee === "fixed"
                                                                ? `${currencySymbol} de frais fixes`
                                                                : "% du montant du panier"}{" "}
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <span>de {gap.start_value} </span>
                                                    <span>
                                                        à {gap.end_value}{" "}
                                                        {unity === "amount"
                                                            ? `${currencySymbol}`
                                                            : "articles"}
                                                        ,{" "}
                                                    </span>
                                                    {!gap.fees_value ? (
                                                        <span>la livraison est gratuite </span>
                                                    ) : (
                                                        <span>
                                                            {gap.fees_value}{" "}
                                                            {fee === "fixed"
                                                                ? `${currencySymbol} de frais fixes`
                                                                : "% du montant du panier"}{" "}
                                                        </span>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="gap-actions">
                                            <Popconfirm
                                                title={`Supprimer l'intervalle ${index + 1} ? `}
                                                onConfirm={() => deletGap(index)}
                                                okText="Oui"
                                                cancelText="Non"
                                            >
                                                <DeleteOutlined />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <span>Vous n'avez pas encore défini d'intervalle</span>
                        )}
                    </div>
                </TabPane>
            </Tabs>
            <div className="actions-container">
                <div className="actions-buttons-container">
                    <Button onClick={() => resetState()}>Annuler</Button>
                    <Button
                        type="primary"
                        onClick={gaps.length < 1 ? () => gapsNotAdded() : () => handleFees()}
                    >
                        Enregistrer{loading && <LoadingOutlined />}
                    </Button>
                </div>
                {gapsEmpty && (
                    <div className="actions-alert">
                        <ExclamationCircleOutlined />
                        <span>Vous devez ajouter au moins un intervalle</span>
                    </div>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    addShippingFees,
};

export default connect(null, mapDispatchToProps)(ShippingFeesSettings);
