import {
    LOAD_STANDS_REQUEST,
    LOAD_STANDS_SUCCESS,
    LOAD_STAND_REQUEST,
    LOAD_STAND_SUCCESS,
    ADD_STAND_REQUEST,
    ADD_STAND_SUCCESS,
    EDIT_STAND_REQUEST,
    EDIT_STAND_SUCCESS,
    ADD_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    UPDATE_STAND_ADDRESS,
    DELETE_STAND_ADDRESS_REQUEST,
    DELETE_STAND_ADDRESS_SUCCESS,
    ADD_STAND_ADDRESS,
    MOVE_CATEGORY_SUCCESS,
    LOAD_ESTIMATE_SUCCESS,
    LOAD_DEPARTMENTS_SUCCESS,
    EDIT_MASS_STAND_SUCCESS,
    LOAD_ESTIMATE_REQUEST,
    SET_SHIPPING_FEES_SUCCESS
} from './actionTypes'
import {
    update,
    create,
    deleteAddress
} from '../../api/standAddresses'
import {
    message
} from 'antd'
import {
    getStands,
    getStand,
    createStand,
    updateStand,
    addStandCategory,
    updateStandCategory,
    deleteStandCategory,
    moveStandCategory,
    getSubscriptionEstimate,
    massEditStands,
    createShippingFees
} from '../../api/stands'
import {
    getActiveDepartments
} from '../../api/departments'
export const loadStands = () => async dispatch => {
    try {
        dispatch({
            type: LOAD_STANDS_REQUEST,
            payload: {}
        })
        const results = await getStands()
        dispatch({
            type: LOAD_STANDS_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger la liste des stands. Veuillez réessayer plus tard.')
    }
}

export const loadStand = standId => async dispatch => {
    try {
        dispatch({
            type: LOAD_STAND_REQUEST,
            payload: standId
        })
        const stand = await getStand(standId)
        dispatch({
            type: LOAD_STAND_SUCCESS,
            payload: stand
        })
    } catch (e) {
        message.error('Impossible de charger le stand. Veuillez réessayer plus tard.')
    }
}

export const loadActiveDepartments = () => async dispatch => {
    try {
        const results = await getActiveDepartments()
        dispatch({
            type: LOAD_DEPARTMENTS_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger la liste des departements. Veuillez réessayer plus tard.')
    }
}

export const addStand = payload => async dispatch => {
    try {
        dispatch({
            type: ADD_STAND_REQUEST,
            payload
        })
        const results = await createStand(payload)
        dispatch({
            type: ADD_STAND_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de créer le stand. Veuillez réessayer plus tard')
    }
}

export const editStand = payload => async dispatch => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            if (k === 'themes' || k === 'theme_id') return false
            formData.append(k, v)
        })
        dispatch({
            type: EDIT_STAND_REQUEST,
            payload
        })
        const stand = await updateStand(formData)
        dispatch({
            type: EDIT_STAND_SUCCESS,
            payload: stand
        })
    } catch (e) {
        message.error('Impossible de mettre à jour le stand. Veuillez réessayer plus tard.')
    }
}

export const editStandsAndSubs = allStands => async dispatch => {
    try {
        const copied = JSON.parse(JSON.stringify(allStands))
        const allStandsFiltred = copied.map(stnd => {
            stnd.departments = stnd.departments.map(dprtmtns => dprtmtns.code)
            return stnd
        })

        await massEditStands(allStandsFiltred)
        dispatch({
            type: EDIT_MASS_STAND_SUCCESS,
            payload: {}
        })
        message.info('Vos modifications de stands ont bien été pris en charge')
    } catch (e) {
        message.error('Impossible de modifier les stands. Veuillez réessayer plus tard')
    }
}

export const loadSubscriptionEstimate = stands => async dispatch => {
    try {
        dispatch({
            type: LOAD_ESTIMATE_REQUEST,
            payload: {}
        })
        const estimate = await getSubscriptionEstimate(stands)
        dispatch({
            type: LOAD_ESTIMATE_SUCCESS,
            payload: estimate
        })
    } catch (e) {
        message.error("Impossible de charger l'estimation.")
    }
}

export const addCategory = payload => async dispatch => {
    try {
        const results = await addStandCategory(payload)
        dispatch({
            type: ADD_CATEGORY_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error("Impossible d'ajouter la catégorie. Veuillez réessayer plus tard.")
    }
}

export const editCategory = payload => async dispatch => {
    try {
        const results = await updateStandCategory(payload)
        dispatch({
            type: EDIT_CATEGORY_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de mettre à jour la catégorie. Veuillez réessayer plus tard.')
    }
}

export const moveCategory = payload => async dispatch => {
    try {
        const categories = await moveStandCategory(
            payload.standId,
            payload.categoryId,
            payload.direction
        )
        dispatch({
            type: MOVE_CATEGORY_SUCCESS,
            payload: { ...payload,
                categories
            }
        })
    } catch (e) {
        message.error('Impossible de déplacer la catégorie. Veuillez réessayer plus tard.')
    }
}

export const deleteCategory = (standId, categoryId) => async dispatch => {
    try {
        await deleteStandCategory(standId, categoryId)
        dispatch({
            type: DELETE_CATEGORY_SUCCESS,
            payload: {
                categoryId
            }
        })
    } catch (e) {
        const errorMessage = e.response ?
            e.response.data.message :
            'Impossible de supprimer la catégorie. Veuillez réessayer plus tard.'
        message.error(errorMessage)
    }
}

export const addStandAddress = (stand, values, department) => async dispatch => {
    try {
        const newAddress = await create(stand, { ...values,
            department
        })
        dispatch({
            type: ADD_STAND_ADDRESS,
            address: newAddress
        })
    } catch (e) {
        message.error("Impossible de créer l'adresse. Veuillez réessayer plus tard.")
    }
}

export const updateStandAddress = (address, values, department) => async dispatch => {
    try {
        const updatedAddress = await update(address, { ...values,
            department
        })
        dispatch({
            type: UPDATE_STAND_ADDRESS,
            address: updatedAddress
        })
    } catch (e) {
        message.error("Impossible d'éditer l'adresse. Veuillez réessayer plus tard.")
    }
}

export const deleteStandAddress = (stand_id, address_id) => async dispatch => {
    dispatch({
        type: DELETE_STAND_ADDRESS_REQUEST,
        address_id: address_id
    })
    try {
        await deleteAddress(stand_id, address_id)
        dispatch({
            type: DELETE_STAND_ADDRESS_SUCCESS,
            address_id: address_id
        })
    } catch (e) {
        message.error("Impossible de supprimer l'adresse. Veuillez réessayer plus tard.")
    }
}


export const addShippingFees = payload => async dispatch => {
    try {
        const results = await createShippingFees(payload)
        dispatch({
            type: SET_SHIPPING_FEES_SUCCESS,
            shippingFee: results
        })
    } catch (e) {
        message.error("Impossible de créer les frais. Veuillez réessayer plus tard.")
    }
}