import {

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,

} from './actionTypes';

const defaultState = {
    users: [],
    isSaved: true,
};

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action;

    switch (type) {

        case FORGOT_PASSWORD_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                    message: false
                }
            }

        case FORGOT_PASSWORD_SUCCESS:
            {
                return {
                    ...state,
                    message: payload,
                    isSaved: true
                }
            }

        case FORGOT_PASSWORD_ERROR:
            {
                return {
                    ...state,
                    message: payload,
                    isSaved: true
                }
            }

        case RESET_PASSWORD_REQUEST:
            {
                return {
                    ...state,
                    isSaved: false,
                    message: false
                }
            }

        case RESET_PASSWORD_SUCCESS:
            {
                return {
                    ...state,
                    message: payload,
                    isSaved: true
                }
            }

        case RESET_PASSWORD_ERROR:
            {
                return {
                    ...state,
                    message: payload,
                    isSaved: true
                }
            }

        default:
    }

    return state;
};