import React from 'react'
import { connect } from 'react-redux'
import { Icon, Upload, Button, message } from 'antd'
import { checkFile } from '../../../helpers/functions'
import './UploadMedia.css'

const UploadMedia = ({
  label,
  isMediaLoading,
  filetypesAllowed,
  uploadMedia,
  profile,
  targetId,
  uploadManually,
  name,
  storeFile,
}) => (
  <div className="upload-button">
    <Upload
      name="file"
      beforeUpload={media => {
        if (uploadManually) {
          if (checkFile(media, message, filetypesAllowed)) storeFile(media)
          return false
        }
        return checkFile(media, message, filetypesAllowed)
      }}
      disabled={!!isMediaLoading}
      showUploadList={false}
      customRequest={media => !uploadManually && uploadMedia(media, profile.account_id, targetId, name)}
    >
      <Button>
        <Icon type="upload" />
        <span className="label">{label}</span>
        {isMediaLoading && <Icon type="loading" />}
      </Button>
    </Upload>
  </div>
)

export default connect(
  state => ({
    profile: state.profile.profile,
  }),
  {}
)(UploadMedia)
