import React, { Component } from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import { loadVisitor } from '../../../domains/visitors/actions'
import { Empty } from 'antd'
import { Visitor, Loader } from '@mafoire/components'
import { loadAgents } from '../../../domains/agents/actions'
import { loadConversations } from '../../../domains/chat/actions'
import { loadPaidCarts } from '../../../domains/ecommerce/actions'
import './VisitorPageContainer.css'

class VisitorPage extends Component {
  componentDidMount() {
    const visitorId = parseInt(this.props.match.params.id, 10)
    this.props.loadVisitor(visitorId)
    this.props.loadConversations()
    this.props.loadPaidCarts()
  }

  render() {
    const { visitorTracking, medias, agents, conversations, stands, carts } = this.props

    return (
      <Base
        customClass="no-margin"
        breadcrumb={[
          { label: 'Accueil', to: '/' },
          { label: 'Leads', to: '/leads' },
          {
            label: visitorTracking && visitorTracking.firstname,
          },
        ]}
      >
        <div className="visitors-page center-container">
          {!visitorTracking || !stands || !agents || !conversations || !medias ? (
            <Loader fullPage />
          ) : visitorTracking && stands && agents && conversations && medias ? (
            <Visitor
              visitorTracking={visitorTracking}
              medias={medias}
              conversations={conversations}
              agents={agents}
              stands={stands}
              carts={carts}
            />
          ) : visitorTracking.length === 0 ? (
            <Empty description="Vous n'avez pas encore de lead(s)" />
          ) : null}
        </div>
      </Base>
    )
  }
}

const mapStateToProps = state => ({
  visitorTracking: state.visitors.visitorTracking,
  medias: state.medias.medias,
  conversations: state.chat.conversations,
  stands: state.stands.stands,
  agents: state.agents.agents,
  carts: state.ecommerce.paidCarts,
})

const mapDispatchToProps = {
  loadVisitor,
  loadAgents,
  loadConversations,
  loadPaidCarts,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorPage)
