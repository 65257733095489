import React, { useState } from 'react'
import { Loader } from '@mafoire/components'
import { Input, Button, Form, notification } from 'antd'
import './InfosRequestForm.css'

const FormItem = Form.Item

const InfosRequestForm = ({form, infosRequestAction}) => {
  const { getFieldDecorator, validateFields } = form
  const [infosRequestIsSending, setInfosRequestIsSending] = useState(false)

  const openNotification = () => {
    notification.open({
      description: 'Votre demande a bien été enregistrée. Nos conseillers vont rapidement prendre contact avec vous.',
      style: {
        backgroundColor: '#E20079',
        borderRadius: '8px',
        color: 'white',
        fontFamily: 'gilroy-bold'
      }
    })
  }

  const handleSubmit = e => {
    setInfosRequestIsSending(true)
    e.preventDefault()
    validateFields((err, values) => {
      if (err) {
        return
      } 
      async function handleDisplayAndAlert() {
        await infosRequestAction(values)
        setInfosRequestIsSending(false)
        form.resetFields()
        openNotification()
      }
      handleDisplayAndAlert()
    })
  }
  
  return (
    <div className="infos-request-form-container">
      <div className="infos-request-form-title-container">
        <h2>Merci de remplir le formulaire afin d'être rappelé par un conseiller.</h2>
      </div>
      <Form className="infos-request-form" onSubmit={handleSubmit}>
        <div className="infos-request-form-row">
          <FormItem>
            {getFieldDecorator('company', {
            rules: [{ required: true, message: 'Veuillez saisir un nom de société' }],
            }
            )(< Input placeholder="Société*" name="company" autoFocus />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('activity', {
              rules: [{ required: true, message: 'Veuillez saisir votre activité' }],
            })(<Input placeholder="Activité*" name="activity" />)}
          </FormItem>
        </div>
        <div className="infos-request-form-row">
          <FormItem>
            {getFieldDecorator('lastname', {
            rules: [{ required: true, message: 'Veuillez saisir votre nom' }],
            })(<Input placeholder="Nom*" name="lastname" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('cp', {
            rules: [{ required: true, message: 'Veuillez saisir un code postal' }],
            })(<Input placeholder="Code postal*" name="cp" />)}
          </FormItem>
        </div>
        <div className="infos-request-form-row">
          <FormItem>
            {getFieldDecorator('telephone', {
            rules: [{ required: true, message: 'Veuillez saisir un numéro de téléphone' }],
            })(<Input placeholder="Téléphone*" name="telephone" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Veuillez saisir un e-mail' }],
            })(<Input placeholder="Votre email*" name="email" />)}
          </FormItem>
        </div>
        <div className="infos-request-form-row">
          <FormItem>
            {getFieldDecorator('website',
            )(<Input placeholder="Votre site internet" name="website" />)}
          </FormItem>
          </div>
        <FormItem className="actions">
          <Button className="validation-button" type="primary" htmlType="submit">
            Envoyer votre demande
        {infosRequestIsSending && <Loader />}
          </Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(InfosRequestForm)