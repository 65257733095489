import React from 'react'
import { Divider, Tooltip } from 'antd'
import { maxDateBeforeJulyDiscount } from './data'
import { formatDate } from '../../../helpers/functions'

const VAT = 20

const price = price =>
  String(parseFloat(Math.round(price * 100) / 100).toFixed(2)).replace('.', ',')

const SubscriptionDetails = ({ subscription }) => {
  const {
    applicationFees,
    launchOfferPrice,
    discount,
    total,
    launchOfferMonths,
    monthlyPrice,
    subscribedUntil
  } = subscription

  return (
    <React.Fragment>
      <Divider>Paiement unique à l'activation</Divider>

      <div className="subscription-detail-line">
        Frais de dossier : {price(applicationFees)}€ <sup>HT</sup>
      </div>

      <div className="subscription-detail-line">
        Offre d'ouverture : {price(launchOfferPrice)}€ <sup>HT</sup>
      </div>

      {!!discount && <div className="subscription-detail-line">Remise : -{price(discount)}€</div>}

      <div className="subscription-detail-line">TVA : {VAT}%</div>

      <div className="subscription-detail-line line-flex">
        <div>
          Total : <strong>{price(total)}€</strong> <sup>HT</sup>
        </div>
        <div>-></div>
        <div>
          <strong>{price(total * (VAT / 100 + 1))}€</strong> <sup>TTC</sup>
        </div>
      </div>

      <Divider>
        Abonnement déclenché le{' '}
        <Tooltip
          title={
            launchOfferMonths === 5 &&
            `Inclut 1 mois offert si paiement avant le ${formatDate(maxDateBeforeJulyDiscount)}`
          }
        >
          {formatDate(subscribedUntil)}
        </Tooltip>
      </Divider>

      <div className="subscription-detail-line line-flex">
        <div>
          Abonnement : <strong>{price(monthlyPrice)}€</strong> <sup>HT</sup> / mois
        </div>
        <div>-></div>
        <div>
          <strong>{price(monthlyPrice * (VAT / 100 + 1))}€</strong> <sup>TTC</sup> / mois
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubscriptionDetails
