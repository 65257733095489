import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  loadDocuments,
  addDocument,
  editDocument,
  destroyMedia,
} from '../../../../domains/medias/actions'
import {
  editStand,
  addStandAddress,
  updateStandAddress,
  deleteStandAddress,
} from '../../../../domains/stands/actions'
import { StandGallery } from '@mafoire/components'
import { Button, Tooltip } from 'antd'
import logo from '../../../../assets/images/mafoire-icon.png'
import HelpModal from '../../guides/HelpModal'
import googleMapMarkerIcon from '../../../../assets/images/mafoire-icon.png'

class StandGalleryContainer extends Component {
  state = {
    visible: false,
  }
  componentDidMount() {
    this.props.loadDocuments(this.props.stand.id)
  }
  showModal = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    const {
      stand,
      documents,
      addDocument,
      editDocument,
      destroyMedia,
      addStandAddress,
      updateStandAddress,
      deleteStandAddress,
      editStand,
      asOwner,
      profile,
      addressIsDeleting,
    } = this.props

    return (
      <React.Fragment>
        <StandGallery
          stand={stand}
          documents={documents}
          maxFileSize={window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT}
          addDocument={addDocument}
          editDocument={editDocument}
          deleteDocument={destroyMedia}
          editStand={editStand}
          asOwner={asOwner}
          profile={profile}
          editDescription={description => editStand({ ...stand, description })}
          addStandAddress={addStandAddress}
          updateStandAddress={updateStandAddress}
          deleteStandAddress={deleteStandAddress}
          googleMapMarkerIcon={googleMapMarkerIcon}
          googleMapApiKey={window.process.env.APP_GOOGLE_API_KEY}
          addressIsDeleting={addressIsDeleting}
        />
        {this.state.visible && (
          <HelpModal
            showModal={this.showModal}
            stand={stand}
            home
            avatarsArray={this.avatarsArray}
            className="help-modal-container"
          />
        )}
        {asOwner && (
          <Tooltip
            overlayClassName="help-tooltip"
            title="Bonjour! 👋 Si vous avez besoin d'aide pour créer l'accueil de votre stand, cliquez sur ce bouton"
          >
            <Button className="help-button" onClick={this.showModal}>
              <img src={logo} alt=""></img>
              <span className="help-label">Besoin d'aide ?</span>
            </Button>
          </Tooltip>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  stand: state.stands.stand,
  documents: state.medias.documents,
  profile: state.profile.profile,
  addressIsDeleting: state.stands.addressIsDeleting,
})

const mapDispatchToProps = {
  loadDocuments,
  addDocument,
  editDocument,
  destroyMedia,
  editStand,
  addStandAddress,
  updateStandAddress,
  deleteStandAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(StandGalleryContainer)
