import {
    LOAD_ACCOUNT_REQUEST,
    LOAD_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_REQUEST,
    EDIT_ACCOUNT_SUCCESS,
    LOAD_BUSINESS_TYPES_REQUEST,
    LOAD_BUSINESS_TYPES_SUCCESS,
    HOME_LOADED,
    LOGIN_LOADED,
    CONNECT_STRIPE_ACCOUNT,
    SUBSCRIPTIONS_LOADED,
    INTENT_LOADED,
    CARD_CHANGED,
    CARD_LOADED
} from './actionTypes'

const defaultState = {
    account: null,
    subscriptions: null,
    businessTypes: [],
    accountLoading: false,
    redirectToHomePage: false,
    redirectToLoginPage: false,
    editAccountIsLoading: false,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_ACCOUNT_REQUEST:
            {
                return {
                    ...state,
                    accountLoading: true,
                }
            }

        case LOAD_ACCOUNT_SUCCESS:
            {
                return {
                    ...state,
                    account: payload,
                    accountLoading: false,
                }
            }

        case LOAD_BUSINESS_TYPES_REQUEST:
            {
                return {
                    ...state,
                    businessTypesLoading: true,
                }
            }

        case LOAD_BUSINESS_TYPES_SUCCESS:
            {
                return {
                    ...state,
                    businessTypes: payload,
                    businessTypesLoading: false,
                }
            }

        case HOME_LOADED:
            {
                return {
                    ...state,
                    redirectToHomePage: false,
                }
            }

        case LOGIN_LOADED:
            {
                return {
                    ...state,
                    redirectToLoginPage: false,
                }
            }

        case EDIT_ACCOUNT_REQUEST:
            {
                return {
                    accountBeingEdited: payload.account_id,
                    ...state,
                    redirectToHomePage: false,
                    isSaved: false,
                    editAccountIsLoading: true,
                }
            }

        case EDIT_ACCOUNT_SUCCESS:
            {
                const newAccount = {
                    ...payload,
                    isSaved: true,
                }
                const account = newAccount
                return {
                    ...state,
                    account,
                    isSaved: true,
                    redirectToHomePage: true,
                    editAccountIsLoading: false,
                }
            }

        case CONNECT_STRIPE_ACCOUNT:
            {
                return {
                    ...state,
                    account: payload,
                }
            }

        case SUBSCRIPTIONS_LOADED:
            {
                return { ...state,
                    subscriptions: payload
                }
            }

        case INTENT_LOADED:
            {
                return { ...state,
                    intent: payload
                }
            }
        case CARD_CHANGED:
            {
                return { ...state,
                    card: payload
                }
            }

        case CARD_LOADED:
            {
                return { ...state,
                    card: payload
                }
            }

        default:
    }

    return state
}