import React, { Component } from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import { Icon } from 'antd'
import {
  loadConversations,
  loadAgents,
  selectConversation,
  sendMessageAction,
  retrySendMessageAction,
  setConversationHasSeen,
} from '../../../domains/chat/actions'
import { loadVisitor, loadVisitorByStandId } from '../../../domains/visitors/actions'
import { loadPaidCarts } from '../../../domains/ecommerce/actions'
import { Chat } from '@mafoire/components'
import './ChatPage.css'

class ChatPage extends Component {
  componentDidMount() {
    this.props.loadConversations()
  }

  handleSendMessage(payload) {
    this.props.sendMessageAction(payload, this.props.profile)
  }

  render() {
    const {
      conversations,
      selectConversation,
      selectedConversation,
      profile,
      retrySendMessageAction,
      setConversationHasSeen,
      visitorTracking,
      medias,
      agents,
      stands,
      carts,
      loadVisitor,
      loadPaidCarts,
      loadVisitorByStandId,
    } = this.props

    let defaultConversation = null

    return (
      <Base customClass="no-margin chat" breadcrumb={[]}>
        <div className="chat-container">
          {conversations !== undefined && conversations.length > 0 ? (
            <Chat
              conversations={conversations}
              profile={profile}
              isAgent={true}
              selectConversation={selectConversation}
              selectedConversation={selectedConversation || defaultConversation}
              sendMessage={(payload) => this.handleSendMessage(payload)}
              retrySendMessage={retrySendMessageAction}
              conversationHasBeenSeen={setConversationHasSeen}
              loadVisitor={loadVisitor}
              loadPaidCarts={loadPaidCarts}
              agents={agents}
              stands={stands}
              carts={carts}
              visitorTracking={visitorTracking}
              medias={medias}
              loadVisitorByStandId={loadVisitorByStandId}
            />
          ) : conversations !== undefined && conversations.length === 0 ? (
            <div className="center-block no-data">
              Vous n'avez pas de conversations pour l'instant
            </div>
          ) : (
            <div className="center-block loader">
              <Icon type="loading" />
            </div>
          )}
        </div>
      </Base>
    )
  }
}

export default connect(
  (state) => ({
    conversations: state.chat.conversations,
    profile: state.profile.profile,
    selectedConversation: state.chat.selectedConversation,
    stands: state.stands.stands,
    visitorTracking: state.visitors.visitorTracking,
    medias: state.medias.medias,
    agents: state.agents.agents,
    carts: state.ecommerce.paidCarts,
  }),
  {
    loadConversations,
    loadAgents,
    selectConversation,
    sendMessageAction,
    retrySendMessageAction,
    setConversationHasSeen,
    loadVisitor,
    loadPaidCarts,
    loadVisitorByStandId,
  }
)(ChatPage)
