import React from 'react'
import Header from '../common/header/Header'
import { Breadcrumb } from '@mafoire/components'
import { Layout } from 'antd'
import './Base.css'

const { Content } = Layout

const Base = ({ breadcrumb, customClass, children }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <Header />
    <Breadcrumb links={breadcrumb} prependBackArrow />
    <Layout className={'base-layout-container ' + (customClass ? customClass : '')}>
      <Content className="base-layout-content-container">{children}</Content>
    </Layout>
  </Layout>
)

export default Base
