import {
    LOAD_MEDIAS_REQUEST,
    LOAD_MEDIAS_SUCCESS,
    UPLOAD_MEDIA_REQUEST,
    UPLOAD_MEDIA_SUCCESS,
    UPLOAD_MEDIA_TO_CATEGORY_REQUEST,
    UPLOAD_MEDIA_TO_CATEGORY_SUCCESS,
    DESTROY_REQUEST,
    DESTROY_SUCCESS,
    UPLOAD_MEDIA_TO_CORPORATE_REQUEST,
    UPLOAD_MEDIA_TO_CORPORATE_SUCCESS,
    REINIT_CURRENT_MEDIA,
    LOAD_DOCUMENTS_SUCCESS,
    ADD_DOCUMENT_SUCCESS,
    EDIT_DOCUMENT_SUCCESS,
} from './actionTypes'

import {
    sortBy,
    findIndex
} from 'lodash'

const defaultState = {
    medias: [],
    mediasByCategory: [],
    mediaIsDeleting: '',
    mediasLoading: true,
    mediaLoading: false,
    currentMedia: false,
    attachingMediaLoading: false,
    detachingMediaLoading: false,
    documents: null,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_MEDIAS_REQUEST:
            {
                return {
                    ...state,
                    mediasLoading: true,
                }
            }

        case LOAD_MEDIAS_SUCCESS:
            {
                const medias = sortBy(payload, 'created_at').reverse()
                return {
                    ...state,
                    medias,
                    mediasLoading: false,
                }
            }

        case LOAD_DOCUMENTS_SUCCESS:
            {
                return { ...state,
                    documents: sortBy(payload, 'name')
                }
            }

        case ADD_DOCUMENT_SUCCESS:
            {
                return { ...state,
                    documents: [...state.documents, payload]
                }
            }

        case EDIT_DOCUMENT_SUCCESS:
            {
                const documentIndex = findIndex(state.documents, {
                    id: payload.id
                })
                const document = state.documents[documentIndex]
                const newDocument = {
                    ...document,
                    ...payload,
                }
                const documents = [...state.documents]
                documents[documentIndex] = newDocument

                return {
                    ...state,
                    documents,
                    documentLoading: false,
                }
            }

        case UPLOAD_MEDIA_REQUEST:
            {
                return {
                    ...state,
                    mediaLoading: true,
                    currentMedia: false,
                }
            }

        case UPLOAD_MEDIA_SUCCESS:
            {
                return {
                    ...state,
                    mediaLoading: false,
                    currentMedia: payload,
                }
            }

        case UPLOAD_MEDIA_TO_CATEGORY_REQUEST:
            {
                return {
                    ...state,
                    attachingMediaLoading: true,
                }
            }

        case UPLOAD_MEDIA_TO_CATEGORY_SUCCESS:
            {
                payload.category = [{
                    id: payload.category_id
                }]
                return {
                    ...state,
                    medias: [payload, ...state.medias],
                    attachingMediaLoading: false,
                }
            }

        case DESTROY_REQUEST:
            {
                return {
                    ...state,
                    detachingMediaLoading: payload.mediaId,
                }
            }

        case DESTROY_SUCCESS:
            {
                return {
                    ...state,
                    medias: state.medias && state.medias.filter(media => media.id !== payload.mediaId),
                    documents: state.documents && state.documents.filter(media => media.id !== payload.mediaId),
                    detachingMediaLoading: false,
                }
            }

        case UPLOAD_MEDIA_TO_CORPORATE_REQUEST:
            {
                return {
                    ...state,
                    mediaLoading: true,
                }
            }

        case UPLOAD_MEDIA_TO_CORPORATE_SUCCESS:
            {
                return {
                    ...state,
                    medias: [payload, ...state.medias],
                    mediaLoading: false,
                }
            }

        case REINIT_CURRENT_MEDIA:
            {
                return {
                    ...state,
                    currentMedia: false,
                }
            }

        default:
    }

    return state
}