import React from 'react'
import logoToSTand from '../../../assets/images/stand_logo_upload.png'
import Stand1 from '../../../assets/images/stands/stand_01.png'
import Stand2 from '../../../assets/images/stands/stand_02.png'
import Stand3 from '../../../assets/images/stands/stand_03.png'
import Stand4 from '../../../assets/images/stands/stand_04.png'
import { Icon } from 'antd'
import { StandDispatcher } from '@mafoire/components'
import './SettingsModal.css'

const SettingsModal = ({ stand, showModal, avatarsArray }) => {
  const standColor = stand ? JSON.parse(stand.colors)[0] : '#000'
  const standColor2 = stand ? JSON.parse(stand.colors)[1] : '#000'
  const colors = stand && JSON.parse(stand.colors)
  const standTypes = [Stand1, Stand2, Stand3, Stand4]
  const standTypeRegex = new RegExp('\\Stand_0' + stand.type_id)
  const match = standTypes.filter(value => standTypeRegex.test(value))

  const getContrastYIQ = hexColor => {
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 180 ? '#535467' : 'white'
  }
  return (
    <div>
      <div className="modal-header">
        <span style={{ color: 'white', fontSize: '13.5px' }}>Configuration de votre stand</span>
        <div className="close-button-container" onClick={showModal}>
          <div className="close-button"></div>
        </div>
      </div>
      <div className="slideshow-container">
        <div className="help-modal-slide fade">
          <p>
            Bienvenue dans l’espace de configuration de votre stand. Avant d'accueillir les
            visiteurs de maFoire.com et de vous lancer dans la vente de vos produits, vous devez
            configurer votre stand.
          </p>
          <p>
            Dans la barre située sous votre stand se trouve le menu du stand. Il vous permet
            d’accéder à la configuration, à l'accueil ainsi qu'à votre catalogue. Par défaut,
            lorsque vous vous rendez sur votre stand, vous accédez sur la page configuration.
          </p>
          <div
            className="settings-navbar-container"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              marginBottom: '30px',
            }}
          >
            <div
              className="settings-navbar"
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: standColor,
                color: stand && getContrastYIQ(standColor.substring(1)),
                fontSize: '1.2em',
              }}
            >
              <span>Configuration</span>
              <div
                style={{
                  height: '5px',
                  width: '60%',
                  backgroundColor: standColor,
                  borderBottomColor: standColor2,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '5px',
                  position: 'relative',
                  top: '31%',
                }}
              ></div>
            </div>
          </div>
          <p>
            Lorsque vous faites défiler la page vers le bas, vous remarquez les quatre éléments
            personalisables de votre stand :
          </p>
          <ul>
            <li>le type du stand</li>
            <li>les couleurs du stand</li>
            <li>le logo du stand</li>
            <li>le conseiller du stand</li>
          </ul>
        </div>

        <div className="help-modal-slide fade">
          <h3>Le type de stand</h3>
          <p>
            Le premier élément est le type de stand. Un stand peut-être constitué notamment de
            cloisons, d'un sol et d'un présentoir. Choissisez parmi tous les styles celui qui vous
            correspond.
          </p>
          <p>Un simple clique suffit pour changer le style de votre stand.</p>
          <div className="stand-type-img-container">
            <img src={match} alt="" />
          </div>
          <p>
            Notez que même après avoir choisi les couleurs, le logo et/ou le conseiller de votre
            stand, vous pouvez modifier le style de celui-ci en conservant les choix de chaque
            élément.
          </p>
        </div>

        <div className="help-modal-slide fade">
          <h3>Les couleurs du stand</h3>
          <p>
            Le deuxième élément personnalisable de votre stand sont les couleurs. Afin d’avoir un
            stand à votre image, Mafoire.com vous permet de choisir la couleur de vos cloisons, de
            votre sol et de votre comptoir.
          </p>
          <p>
            En cliquant sur les carrés de couleur comme représentés ci-dessous, vous pouvez modifier
            la couleur de l'élément correspondant au carré (cloisons, sol ou comptoir).
          </p>
          <div className="colors-blocs-container">
            <div className="color-picker-block">
              <div className="colorpicker-container">
                <div
                  className="colorpicker"
                  style={{
                    backgroundColor: colors[0],
                  }}
                />
              </div>
              <div className="color-legend">
                <h3>Cloisons</h3>
              </div>
            </div>

            <div className="color-picker-block">
              <div className="colorpicker-container">
                <div
                  className="colorpicker"
                  style={{
                    backgroundColor: colors[1],
                  }}
                />
              </div>
              <div className="color-legend">
                <h3>Sol</h3>
              </div>
            </div>

            <div className="color-picker-block">
              <div className="colorpicker-container">
                <div
                  className="colorpicker"
                  style={{
                    backgroundColor: colors[2],
                  }}
                />
              </div>
              <div className="color-legend">
                <h3>Comptoir</h3>
              </div>
            </div>
          </div>

          <div className="stand-container">
            <StandDispatcher stand={stand} />
          </div>
        </div>
        <div className="help-modal-slide fade">
          <h3>Le logo du stand</h3>
          <p>
            Le troisisème élément que vous pouvez personnaliser est le logo. Cliquez sur le bouton
            « Télécharger une image », choisissez votre fichier, et le tour est joué.
          </p>
          <div className="upload-button-container">
            <div className="upload-button">
              <Icon type="upload" />
              <span>Télécharger une image</span>
            </div>
          </div>
          <div
            className="logo-to-stand-container"
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
          >
            <img src={logoToSTand} alt="" style={{ height: '220px' }} />
          </div>
          <p>
            Préférez un logo en mode paysage (horizontal) plutôt qu'en mode portrait (vertical) afin
            qu’il soit mieux intégré dans les encarts/écrans de votre stand.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Le conseiller du stand</h3>
          <p>
            Le dernier élément que vous pouvez personnaliser est le(la) conseiller(ère) de votre
            stand. Un simple clique suffit pour choisir celui ou celle qui sera le(la)
            représentant(e) virtuel(le) de votre stand.
          </p>
          <div className="avatars-container">
            <div className="avatar-subcontainer">
              <img src={avatarsArray && avatarsArray()[6].thumbnail} alt="avatar" />
              <img src={avatarsArray && avatarsArray()[7].thumbnail} alt="avatar" />
              <img src={avatarsArray && avatarsArray()[5].thumbnail} alt="avatar" />
              <img src={avatarsArray && avatarsArray()[4].thumbnail} alt="avatar" />
            </div>
          </div>
          <p>
            N'hésitez pas à tester le rendu de vos choix avec le bouton "Prévisualiser le stand",
            situé au dessus et à gauche de la barre de menu du stand.
          </p>
          <p>
            Il vous permet d'afficher votre stand en mode "VISITEUR". Vous pouvez ainsi
            prévisualiser ce que vera le visiteur lorsqu'il se rendra sur votre stand.
          </p>
          <p>
            N.B. : Lorsque vous passez en mode "VISITEUR", cette fenêtre disparait. Passez en mode
            "EXPOSANT" et cliquez de nouveau sur "Besoin d'aide?" pour rouvrir cette fenêtre.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SettingsModal
