import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Base from '../Base'
import { Switch as ToggleSwitch } from 'antd'
import { StandDispatcher, StandMenu, Loader } from '@mafoire/components'
import StandConfiguration from '../../common/stands/subpages/StandConfiguration'
import StandEcommerce from '../../common/stands/subpages/StandEcommerceContainer'
import StandGallery from '../../common/stands/subpages/StandGalleryContainer'
import StandContact from '../../common/stands/subpages/StandContactContainer'
import {
  editStand,
  loadStand,
  updateStandAddress,
  addStandAddress,
} from '../../../domains/stands/actions'
import history from '../../../helpers/history'
import varLess from '../../../helpers/theme-variables'
import './StandPageContainer.css'

class StandPage extends Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
  }
  state = {
    // title: '',
    // titleEditing: false,
    previewAsVisitor: false,
  }

  componentDidMount() {
    const standId = parseInt(this.props.match.params.id, 10)
    this.props.loadStand(standId)
    this.props.history.push(`${this.props.match.url}/configuration`)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stand && this.props.stand) {
      const path = this.props.location.pathname.split('/')
      if (path[path.length - 1] === 'contact') {
        history.push(`/stands/${this.props.stand.id}`)
      }
    }
  }

  togglePreview = checked => {
    const {
      location: { pathname },
      stand,
    } = this.props
    const path = pathname.split('/')
    if (
      (!checked && path[path.length - 1] === 'contact') ||
      (checked && path[path.length - 1] === 'accueil')
    ) {
      history.push(`/stands/${stand.id}`)
    }
    this.setState({ previewAsVisitor: checked })
  }

  closeEditProductModal = () => this.setProductToEdit(null)

  render() {
    const {
      stand,
      editStand,
      logoLoading,
      standTypeLoading,
      standAvatarLoading,
      standColorsLoading,
      profile,
      account,
      match,
    } = this.props
    const { previewAsVisitor } = this.state

    const colors = stand ? JSON.parse(stand.colors) : ['#333', varLess['@primary-color']]


    const getContrastYIQ = hexColor => {
      const r = parseInt(hexColor.substr(0, 2), 16)
      const g = parseInt(hexColor.substr(2, 2), 16)
      const b = parseInt(hexColor.substr(4, 2), 16)
      const yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 180 ? '#535467' : 'white'
    }

    const selectSubMenu = (to, callback) => {
      history.push(`/stands/${stand.id}/${to}`)
      callback()
    }

    const selectNavbarMenu = (to, callback) => {
      history.push(`/stands/${stand.id}/${to}`)
      callback()
    }

    const selectSubMenuIconChat = () => {
      history.push(`/chat`)
    }

    const scrollToBottom = () => {
      const menu = document.getElementsByClassName('stand-menu')[0]
      menu.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }

    return (
      <Base
        breadcrumb={[
          { label: 'Accueil', to: '/' },
          { label: 'Mes stands', to: '/stands' },
          stand ? { label: stand.name, to: `/stands/${stand.id}` } : {},
        ]}
      >
        <div className="page-container stand">
          {!stand ? (
            <Loader fullPage />
          ) : (
            <React.Fragment>
              <div className="stand-infos-container">
                <div
                  className="stand-bloc name"
                  style={{
                    backgroundColor: stand && colors[0],
                    color: stand && getContrastYIQ(colors[0].substring(1)),
                  }}
                >
                  {stand && stand.name}
                </div>
                {stand && (
                  <React.Fragment>
                    <div className="stand-bloc business-type">
                    {stand && stand.themes && 
                       stand.themes.map((theme)=> (<span key={theme.id}>{theme.name}</span>)) }
                    </div>
                    <div className="stand-bloc county">
                      {stand.departments.map(department => (
                        <div className="departement-name-number" key={department.code}>
                          <span className="department-name">{department.name}</span>
                          {!department.is_special && <span>({department.code})</span>}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className={`stand-wrapper stand${stand.type_id}${previewAsVisitor ? ' preview-as-visitor' : ''}`}>
                <StandDispatcher
                  stand={stand}
                  avatarDomain={window.process.env.APP_AVATARS_URL}
                  exposantMode
                  selectSubMenu={selectSubMenu}
                  selectSubMenuIconChat={selectSubMenuIconChat}
                  hasEcommerce={stand.pack_unlimited === 1 || stand.pack_ecommerce === 1}
                  asOwner={!previewAsVisitor}
                  scrollToBottom={scrollToBottom}
                />
                <div className="stand-preview-switch">
                  <span className="preview-label">Prévisualiser le stand</span>
                  <ToggleSwitch
                    checkedChildren="Visiteur"
                    unCheckedChildren="Exposant"
                    onChange={this.togglePreview}
                  />
                </div>
              </div>
              <div className="stand-menu">
                <StandMenu
                  asOwner={!previewAsVisitor}
                  stand={stand}
                  selectNavbarMenu={selectNavbarMenu}
                  scrollToBottom={scrollToBottom}
                />
              </div>

              <div className="stand-content">
                <Switch>
                  <Route
                    exact
                    path={`${match.url}/ecommerce`}
                    render={() => (
                      <StandEcommerce
                        asOwner={!previewAsVisitor}
                        openEditModal={this.setProductToEdit}
                        closeEditModal={this.closeEditProductModal}
                      />
                    )}
                  />
                  {previewAsVisitor && (
                    <Route
                      exact
                      path={`${match.url}/contact`}
                      render={() => <StandContact stand={stand} profile={profile} asOwner />}
                    />
                  )}
                  <Route
                    exact
                    path={`${match.url}/accueil`}
                    render={() => (
                      <StandGallery
                        editStand={editStand}
                        asOwner={!previewAsVisitor}
                        openEditModal={this.setProductToEdit}
                        closeEditModal={this.closeEditProductModal}
                      />
                    )}
                  />
                  {!previewAsVisitor && (
                    <Route
                      exact
                      path={`${match.url}/configuration`}
                      render={() => (
                        <StandConfiguration
                          stand={stand}
                          account={account}
                          colors={colors}
                          editStand={editStand}
                          logoLoading={logoLoading}
                          standTypeLoading={standTypeLoading}
                          standAvatarLoading={standAvatarLoading}
                          standColorsLoading={standColorsLoading}
                        />
                      )}
                    />
                  )}
                </Switch>
              </div>
            </React.Fragment>
          )}
        </div>
      </Base>
    )
  }
}

export default connect(
  state => ({
    account: state.account.account,
    stand: state.stands.stand,
    medias: state.medias.medias,
    standLoading: state.stands.standLoading,
    logoLoading: state.stands.logoLoading,
    standTypeLoading: state.stands.standTypeLoading,
    standAvatarLoading: state.stands.standAvatarLoading,
    standColorsLoading: state.stands.standColorsLoading,
    titleLoading: state.stands.titleLoading,
    mediaLoading: state.medias.mediaLoading,
    attachingMediaLoading: state.medias.attachingMediaLoading,
    detachingMediaLoading: state.medias.detachingMediaLoading,
    profile: state.profile.profile,
    editingProduct: state.products.editingProduct,
    productLoading: state.products.productLoading,
    infosFormDisplayed: state.stands.infosFormDisplayed,
  }),
  {
    loadStand,
    editStand,
    updateStandAddress,
    addStandAddress,
  }
)(StandPage)
