import {
    httpWithAuth
} from '../helpers/http'

export const getVisitors = () => httpWithAuth('/api/visitors')

export const getVisitor = async id => httpWithAuth(`/api/visitors/${id}`)

export const getTrackVisitorAgentView = async (id, standId) => httpWithAuth(`/api/visitors/${id}/stands/${standId}`)

export const getLeadsContacts = () => httpWithAuth('/api/leads/contacts')