import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Avatar, Icon, Tag, Popconfirm, Divider, Tooltip } from 'antd'
import varLess from '../../../helpers/theme-variables'
import './AgentCard.css'

const AgentCard = ({ agent, deleteAgent }) => {
  const agentStands = agent.user_stands.map(userStand => {
    const stand = agent.stands.find(stand => stand.id === userStand.stand_id)
    return { ...userStand, stand }
  })

  const { Meta } = Card

  return (
    <div>
      {agent && (
        <Card
          style={{ minHeight: 160 }}
          className="agent-card"
          actions={[
            <Link className="edit-button-navlink" to={`/vendeurs/editer/${agent.id}`}>
              <Icon type="edit" />
            </Link>,
            <Popconfirm
              placement="top"
              title="Êtes-vous sûr de vouloir supprimer ce vendeur ?"
              onConfirm={() => deleteAgent(agent)}
              okText="Oui"
              cancelText="Non"
            >
              {agent.agentsLoading && <Icon className="delete-agent-loading-icon" type="loading" />}
              <Icon type="delete" />
            </Popconfirm>,
          ]}
        >
          <Meta
            avatar={
              agent.avatar ? (
                <Avatar size={54} className="card-avatar" src={agent.avatar} />
              ) : (
                <Avatar size={54} className="card-avatar-no-picture" icon="user" />
              )
            }
            title={
              <Link className="edit-button-navlink" to={`/vendeurs/editer/${agent.id}`}>
                {agent.name}
              </Link>
            }
            description={agent.email}
          />
          <div className="agent-tags-wrapper">
            <Divider className="agent-tags-divider">
              <span className="agent-tags-wrapper-title">Stands et départements</span>
            </Divider>
            {agent.stands.length < 1 ? (
              <div className="any-tag-text">
                <Icon
                  className="any-tag-icon"
                  type="info-circle"
                  theme="twoTone"
                  twoToneColor="#f49e42"
                />
                <span>Aucun stand associé</span>
              </div>
            ) : (
              agentStands.length > 0 && (
                <div className="tags-wrapper-row">
                  {agentStands.map((agentStand, index) => (
                    <div
                      className="stands-block"
                      style={{ color: agentStand.stand.colors[1] }}
                      key={agentStand.id}
                    >
                      <div
                        className="stands-title"
                        style={{
                          color: index % 2 === 0 ? varLess['@pink'] : varLess['@blue'],
                        }}
                      >
                        {agentStand.stand.name}
                      </div>
                      <div className="departments-block">
                        {agentStand.departments && JSON.parse(agentStand.departments).length > 0 ? (
                          JSON.parse(agentStand.departments).map(department => (
                            <Tag
                              className="department-agent-tag"
                              style={{
                                color: index % 2 === 0 ? varLess['@pink'] : varLess['@blue'],
                              }}
                              key={department}
                            >
                              {department}
                            </Tag>
                          ))
                        ) : (
                          <div className="no-department-message">
                            <Tooltip title="Aucun département attribué">
                              <Icon
                                className="no-department-icon"
                                type="exclamation-circle"
                                theme="twoTone"
                                twoToneColor="#FF0000"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

export default AgentCard
