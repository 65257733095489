import {
    LOAD_PRODUCTS_SUCCESS,
    ADD_PRODUCT_SUCCESS,
    EDIT_PRODUCT_SUCCESS,
    RECREATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    SET_DEFAULT_SUCCESS,
    DELETE_PRODUCT_FILE_SUCCESS,
    DUPLICATE_PRODUCT_SUCCESS,
} from './actionTypes'
import {
    getProducts,
    createProduct,
    updateProduct,
    destroyProduct,
    markAsPrincipal,
    destroyProductFile,
    duplicateProduct,
} from '../../api/product'
import {
    message
} from 'antd'

export const loadProducts = standId => async dispatch => {
    try {
        const results = await getProducts(standId)
        dispatch({
            type: LOAD_PRODUCTS_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger la liste des produits. Veuillez réessayer plus tard.')
    }
}

export const addProduct = (payload) => async dispatch => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            if (k === 'files' || k === 'youtubeFiles') {
                const keyName = k + '[]'
                for (let i = 0; i < v.length; i++) {
                    formData.append(keyName, v[i])
                }
            } else {
                if (v !== undefined && v !== null) formData.append(k, v)
            }
        })

        const result = await createProduct(formData)
        message.info('Le produit a bien été créé')
        dispatch({
            type: ADD_PRODUCT_SUCCESS,
            payload: result
        })
    } catch (e) {
        const errorMessage = e.response && e.response.data.error ?
            e.response.data.error :
            'Impossible de créer le produit. Veuillez réessayer plus tard.'
        message.error(errorMessage, 10)
        throw Error('Error') // Throw error that can be caught in a component
    }
}

export const editProduct = payload => async dispatch => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            if (k === 'files' || k === 'youtubeFiles') {
                const keyName = k + '[]'
                for (let i = 0; i < v.length; i++) {
                    formData.append(keyName, v[i])
                }
            } else {
                if (v !== undefined && v !== null) formData.append(k, v)
            }
        })
        const result = await updateProduct(formData)
        if (result.id !== payload.id) {
            message.info('Produit recréé avec les modifications car des commandes ont déjà été passées')
            dispatch({
                type: RECREATE_PRODUCT_SUCCESS,
                payload: {
                    archivedProduct: result.archivedProduct,
                    newProduct: result.newProduct
                },
            })
        } else {
            message.info('Le produit a bien été modifié')
            dispatch({
                type: EDIT_PRODUCT_SUCCESS,
                payload: result
            })
        }
    } catch (e) {
        const errorMessage = e.response && e.response.data.error ?
            e.response.data.error :
            'Impossible d\'éditer le produit. Veuillez réessayer plus tard.'
        message.error(errorMessage, 10)
        throw Error('Error') // Throw error that can be caught in a component
    }
}

export const doubleProduct = (standId, productId) => async dispatch => {
    try {
        const result = await duplicateProduct(standId, productId)
        message.info('Le produit a bien été dupliqué, procédons à sa modification : ', 6)
        dispatch({
            type: DUPLICATE_PRODUCT_SUCCESS,
            payload: result
        })
    } catch (e) {
        const errorMessage = e.response && e.response.data.error ?
            e.response.data.error :
            'Impossible de dupliquer le produit. Veuillez réessayer plus tard.'
        message.error(errorMessage, 10)
        throw Error('Error') // Throw error that can be caught in a component
    }
}

export const deleteProduct = (standId, id) => async dispatch => {
    try {
        await destroyProduct(standId, id)
        message.info('Le produit a bien été supprimé')
        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: id
        })
    } catch (e) {
        message.error('Impossible de supprimer le produit. Veuillez réessayer plus tard.')
    }
}

export const setDefault = (standId, productId, id) => async dispatch => {
    try {
        const results = await markAsPrincipal(standId, productId, id)
        message.info('La photo est devenue principale.')
        dispatch({
            type: SET_DEFAULT_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Imposible de définir la photo comme principale. Veuillez réessayer plus tard.')
    }
}

export const deleteProductFile = (standId, productId, id) => async dispatch => {
    try {
        const results = await destroyProductFile(standId, productId, id)
        message.info('La photo est supprimée.')
        dispatch({
            type: DELETE_PRODUCT_FILE_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de supprimer la photo. Veuillez réessayer plus tard.')
    }
}