import React, { useState } from 'react'
import { Form, Button } from 'antd'
import StandForm from './StandForm'
import { maxDateBeforeJulyDiscount, mafoireLaunchDate } from './data'
import { changeDate } from '../../../helpers/functions'
import './SubscriptionForm.css'
import SubscriptionDetails from './SubscriptionDetails'

// TODO: Handle 1 more month discount if subscribed before 1st july
const computeSubscription = (stands = [], discount) => {
  const isComplete = !stands.every(
    stand => !!stand.name && !!stand.pack && !!stand.departments && !!stand.departments.length
  )
  if (isComplete) return null
  discount = discount ? parseInt(discount) : 0
  let launchOfferPrice = 0
  let monthlyPrice = 0
  let applicationFees = 250 * stands.length
  stands.forEach(stand => {
    const packPriceFactor = stand.pack === 'unlimited' ? 1.5 : 1
    launchOfferPrice += 500 * packPriceFactor * stand.departments.length
    monthlyPrice += 250 * packPriceFactor * stand.departments.length
  })
  const total = applicationFees + launchOfferPrice - discount
  const subscriptionDate = new Date() < mafoireLaunchDate ? mafoireLaunchDate : new Date()
  const launchOfferMonths = subscriptionDate < maxDateBeforeJulyDiscount ? 5 : 3
  const subscribedUntil = changeDate(subscriptionDate, { months: launchOfferMonths, days: 10 })
  return {
    applicationFees,
    launchOfferPrice,
    total,
    discount,
    monthlyPrice,
    launchOfferMonths,
    subscribedUntil,
  }
}

const SubscriptionForm = ({ form, activeDepartments, themes }) => {
  const [standIndexes, setStandIndexes] = useState([0])

  const { getFieldValue } = form

  const lastStandIndex = standIndexes[standIndexes.length - 1]

  const subscription = computeSubscription(
    getFieldValue('stands'),
    getFieldValue('subscription.discount')
  )

  return (
    <div className="subscription-form-section">
      {standIndexes.map(standIndex => (
        <StandForm
          key={standIndex}
          standIndex={standIndex}
          form={form}
          initialStandName={getFieldValue('name')}
          activeDepartments={activeDepartments}
          themes={themes}
          onClose={
            standIndexes.length > 1
              ? () => {
                  setStandIndexes(standIndexes.filter(index => index !== standIndex))
                }
              : null
          }
        />
      ))}

<Form.Item wrapperCol={{ sm: { span: 16, offset: 8 } }}>
        <Button
          className="add-stand-button"
          type="dashed"
          onClick={() => setStandIndexes([...standIndexes, lastStandIndex + 1])}
        >
          Ajouter un nouveau stand
        </Button>
      </Form.Item>

      <Form.Item label="Détail de la facturation">
        {/* TODO: Prix total */}
        {subscription ? (
          <SubscriptionDetails subscription={subscription} lastStandIndex={lastStandIndex} />
        ) : (
          "Pour définir le prix de l'offre de l'exposant vous devez remplir les informations de son/ses stand(s)"
        )}
      </Form.Item>

    </div>
  )
}

export default SubscriptionForm