import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Logo from '../../../assets/images/mafoire-logo.svg'
import { logout } from '../../../domains/profile/actions'
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import * as MF from '@mafoire/components'
import './Header.css'

const Header = ({ profile, logout, account, conversations }) => {
  const newMessageCount =
    conversations &&
    conversations.filter(
      conversation =>
        !!conversation.messages.filter(message => message.seen === 0 && message.agent_id === null)
          .length
    ).length

  let favicon = document.getElementById('favicon')
  if (!!newMessageCount) {
    favicon.href = '/favicon-notif.png'
  } else {
    favicon.href = '/favicon.png'
  }

  return (
    <MF.Header
      className="header"
      brand={
        <NavLink to="/">
          <div className="logo">
            <img src={Logo} alt="Logo MaFoire" />
            {!!Object.keys(profile).length &&
              (!profile.subscribed_until || new Date(profile.subscribed_until) < new Date()) && (
                <div
                  className="tag-unsubscribed"
                  title={`Vous ${
                    profile.subscribed_until
                      ? "n'êtes plus abonné depuis le " + new Date(profile.subscribed_until)
                      : "n'êtes pas abonné"
                  }`}
                >
                  Non-abonné
                </div>
              )}
          </div>
        </NavLink>
      }
      leftMenu={
        profile.name && (
          <LeftMenu
            profile={profile && profile}
            hasEcommerce={account && !!account.has_ecommerce}
            newMessageCount={newMessageCount}
          />
        )
      }
      rightMenu={profile && profile.name && <RightMenu logout={logout} newMessageCount={newMessageCount} />}
    />
  )
}

export default connect(
  state => ({
    profile: state.profile.profile,
    account: state.account.account,
    conversations: state.chat.conversations,
  }),
  {
    logout,
  }
)(Header)
