import React from 'react'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'

const NewAccountButton = () => (
  <NavLink to="/login/creer">
    <Button type="primary">Je crée mon compte</Button>
  </NavLink>
)

const LoginPageButton = () => (
  <NavLink to="/login">
    <Button type="primary">Retour à la page de connexion</Button>
  </NavLink>
)

export { NewAccountButton, LoginPageButton }