import React from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import AccountStripeConnection from '../../common/account/AccountStripeConnection'
import { connectStripeAccount } from '../../../domains/accounts/actions'

const AccountStripeConnectionPage = ({ account, connectStripeAccount }) => (
  <Base
    breadcrumb={[
      { label: 'Accueil', to: '/' },
      { label: 'Mon compte', to: '/mon-compte' },
      {
        label: 'Connexion à Stripe',
      },
    ]}
  >
    <AccountStripeConnection account={account} connectStripeAccount={connectStripeAccount} />
  </Base>
)

export default connect(
  state => ({
    account: state.account.account,
  }),
  { connectStripeAccount }
)(AccountStripeConnectionPage)
