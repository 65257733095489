import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";

import LoginPage from "./component/page/login/LoginPageContainer";
import NewAccountConfirmationPage from "./component/page/login/AccountConfirmationPageContainer";
import NewAccountPage from "./component/page/login/NewAccountPageContainer";
import ForgotPage from "./component/page/user/ForgotPageContainer";
import ResetPage from "./component/page/user/ResetPageContainer";
import InfosRequestPage from "./component/page/InfosRequest/InfosRequestPage";

import HomePage from "./component/page/home/HomePageContainer";
import StandsPage from "./component/page/stands/StandsPageContainer";
import StandPage from "./component/page/stands/StandPageContainer";
import ProductPage from "./component/page/product/ProductPage";
import AccountPage from "./component/page/account/AccountPageContainer";
import AccountStripeConnectionPage from "./component/page/account/AccountStripeConnectionPageContainer";
import EditAccountPage from "./component/page/account/EditAccountPageContainer";
import EditConnexionAccountPage from "./component/page/account/EditConnexionAccountPageContainer";
import EditAccountBillingPage from "./component/page/account/EditAccountBillingPageContainer";
import EditAccountSubscriptionPage from "./component/page/account/EditAccountCardContainer";
import AgentsPage from "./component/page/agents/AgentsPageContainer";
import AddAgentPage from "./component/page/agents/AddAgentPageContainer";
import EditAgentPage from "./component/page/agents/EditAgentPageContainer";
import ChatPage from "./component/page/chat/ChatPage";
import VisitorsPage from "./component/page/visitors/VisitorsPageContainer";
import VisitorPage from "./component/page/visitors/VisitorPageContainer";
import CartsPage from "./component/page/carts/CartsPage";
import StandGuidePage from "./component/page/guides/StandGuidePageContainer";
import StripeGuidePage from "./component/page/guides/StripeGuidePageContainer";
import { getProfile } from "./helpers/local-storage";
import LeadsContactsPage from "./component/page/leads/leadsContactsPageContainer";

const Routes = ({ history }) => {
  const profile = getProfile();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        profile && profile.access_token ? (
          !profile.hasPayementMethod &&
          window.process.env.APP_MAFOIRE_DEV !== "true" ? (
            <EditAccountSubscriptionPage {...props} />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute exact path="/stands" component={StandsPage} />
        <Route
          path={"/stands/:standId/ecommerce/:productId"}
          component={ProductPage}
        />
        <PrivateRoute exact path="/chat" component={ChatPage} />
        <PrivateRoute exact path="/leads" component={VisitorsPage} />
        <PrivateRoute
          exact
          path="/leads/contacts"
          component={LeadsContactsPage}
        />
        <PrivateRoute path="/leads/:id" component={VisitorPage} />
        <PrivateRoute path="/commandes" component={CartsPage} />
        <PrivateRoute path="/guide-stand" component={StandGuidePage} />
        <PrivateRoute path="/guide-stripe" component={StripeGuidePage} />
        <PrivateRoute
          path="/mon-compte/connexion/editer"
          component={EditConnexionAccountPage}
        />
        <PrivateRoute
          path="/mon-compte/facturation/editer"
          component={EditAccountBillingPage}
        />
        <PrivateRoute
          path="/mon-compte/abonnement/editer"
          component={EditAccountSubscriptionPage}
        />

        <PrivateRoute
          path="/mon-compte/facturation/connexion-stripe"
          component={AccountStripeConnectionPage}
        />
        <PrivateRoute path="/mon-compte/editer" component={EditAccountPage} />
        <PrivateRoute path="/mon-compte" component={AccountPage} />
        <PrivateRoute path="/vendeurs/editer/:id" component={EditAgentPage} />
        <PrivateRoute path="/vendeurs/ajouter" component={AddAgentPage} />
        <PrivateRoute exact path="/vendeurs" component={AgentsPage} />
        <PrivateRoute path="/stands/:id" component={StandPage} />
        <Route path="/user/reset" component={ResetPage} />
        <Route path="/user/forgot" component={ForgotPage} />
        <Route path="/infos-request" component={InfosRequestPage} />
        {!profile && (
          <Route
            path="/login/creer/confirmation"
            component={NewAccountConfirmationPage}
          />
        )}
        {!profile && <Route path="/login/creer" component={NewAccountPage} />}
        {!profile && <Route path="/login" component={LoginPage} />}
      </Switch>
    </Router>
  );
};

export default Routes;
