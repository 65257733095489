import React, { Component } from 'react'
import StandItemCard from './StandItemCard'
import { Loader } from '@mafoire/components'
import { Col } from 'antd'
import './StandsList.css'

class StandsList extends Component {
  state = {}

  render() {
    const { stands, editStand, account, statusLoading } = this.props

    return (
      <React.Fragment>
        {stands ? (
          stands.length ? (
            <div className="stands-list">
              {stands &&
                stands.map(stand => (
                  <Col key={stand.id} sm={24} md={24} lg={24}>
                    <StandItemCard
                      data={stand}
                      account={account}
                      editStand={editStand}
                      statusLoading={statusLoading}
                    />
                  </Col>
                ))}
            </div>
          ) : (
            <p>Vous n'avez aucun stand pour le moment. Un admin doit en créer un pour vous.</p>
          )
        ) : (
          <Loader fullPage />
        )}
      </React.Fragment>
    )
  }
}

export default StandsList
