import React from 'react'
import { Icon } from 'antd'
import './HomeModal.css'
import youTubeVideoLink from '../../../assets/images/youtube_video_link.jpg'
import googleMapExample from '../../../assets/images/google_map_example.jpg'

const HomeModal = ({ stand, showModal }) => {
  const standColor = stand ? JSON.parse(stand.colors)[0] : '#000'
  const standColor2 = stand ? JSON.parse(stand.colors)[1] : '#000'

  const getContrastYIQ = hexColor => {
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 180 ? '#535467' : 'white'
  }
  return (
    <div>
      <div className="modal-header">
        <span style={{ color: 'white', fontSize: '13.5px' }}>L'accueil de votre stand</span>
        <div className="close-button-container" onClick={showModal}>
          <div className="close-button"></div>
        </div>
      </div>
      <div className="slideshow-container">
        <div className="help-modal-slide fade">
          <p>
            Bienvenue dans l’espace de gestion de l'accueil de votre stand. Dans cet espace, vous
            allez pouvoir présenter votre entreprise par l'intermédaire de zones de textes à remplir
            et de documents à télécharger.
          </p>
          <div
            className="settings-navbar-container"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              marginBottom: '30px',
            }}
          >
            <div
              className="settings-navbar"
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: standColor,
                color: stand && getContrastYIQ(standColor.substring(1)),
                fontSize: '1.2em',
              }}
            >
              <span>Accueil</span>
              <div
                style={{
                  height: '5px',
                  width: '60%',
                  backgroundColor: standColor,
                  borderBottomColor: standColor2,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '5px',
                  position: 'relative',
                  top: '31%',
                }}
              ></div>
            </div>
          </div>

          <p>Nous verrons successivement comment ajouter :</p>
          <ul>
            <li>votre accroche commerciale</li>
            <li>la description de votre entreprise</li>
            <li>vos documents de présentation</li>
            <li>vos adresses</li>
            <li>vos documents légaux</li>
          </ul>
          <p>
            Notez que lorsque le visiteur se rend sur votre stand, il accède par défaut à la page
            accueil de votre stand.
          </p>
        </div>

        <div className="help-modal-slide fade">
          <h3>L'accroche commerciale</h3>
          <p>
            Sous le nom de votre stand, vous remarquez un champ texte qui vous permet de placer
            votre accroche commerciale en 140 caractères.
          </p>
          <p>
            Cliquez sur l'icône représentant un crayon et écrivez votre texte. Un compteur situé
            sous le champ vous indique combien de caractères il vous reste.
          </p>

          <div className="modal-presentation-container">
            <span className="modal-presentation-label">
              Ceci est une approche commerciale fictive...
            </span>
            <div className="modal-count">
              <span className="modal-count-label">Nombre de caractères restants : </span>
              <span className="modal-count-number">99</span>
            </div>
          </div>

          <p>
            Votre accroche commerciale est affichée sur la carte de votre stand dans l'application
            dédiée aux visiteurs. Celle-ci doit par conséquent être succincte (140 caractères
            maximum)
          </p>
          <p>
            N.B. : Vous pouvez aussi modifier le nom de votre entreprise en cliquant sur l'icône de
            crayon situé à droite de votre nom.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>La description de votre entreprise</h3>
          <p>
            L'espace prévu pour la description de votre entreprise se situe sous le champ de
            l'accroche commerciale. Vous pouvez présenter votre activité, vos produits et/ou
            services, vos valeurs...
          </p>
          <p>
            Cliquez sur l'icône représentant un crayon et accédez à l'éditeur de texte, dont vous
            pouvez voir une illustration ci-dessous.
          </p>
          <div className="modal-editor-container">
            <div className="modal-editor-toolbar">
              <div className="modal-editor-paragraph-container">
                <span>Paragraph</span>
                <Icon type="down" />
              </div>
              <div className="modal-editor-font-style-container">
                <Icon type="bold" />
                <Icon type="italic" />
                <Icon type="underline" />
              </div>
              <Icon type="align-left" />
            </div>
            <span className="modal-editor-text">
              Notre magasin vend <span className="modal-editor-text-number">3 types</span> de
              chaises :
            </span>
            <ul className="modal-editor-text-list">
              <li>des chaises en cuir</li>
              <li>ldes chaises en bois</li>
              <li>des chaises en métal</li>
            </ul>
          </div>
          <p>
            L'éditeur de texte vous permet notamment de changer le style du texte (gras, itlaique,
            souligné) et son alignement.
          </p>
        </div>

        <div className="help-modal-slide fade">
          <h3>Vos documents de présentation</h3>
          <p>
            Pour compléter la description de votre entreprise, vous pouvez ajouter des documents
            (photos, vidéos, PDF...) de présentation.
          </p>
          <h2 lassName="help-modal-subtitle">Vos fichiers</h2>
          <p>
            Cliquez sur "Téléchargez un fichier", choisissez votre document, renseignez son nom,
            puis cliquez sur "OK".
          </p>
          <div className="modal-category-container"></div>
          <h2 className="help-modal-subtitle">Vidéo YouTube</h2>
          <p>
            Si vous souhaitez aussi intégrer une vidéo YouTube, rendez-vous sur YouTube et accédez à
            votre vidéo. Copiez l'adresse de la vidéo dans la barre de votre navigateur (Chrome,
            Safari, Firefox...).
            <div className="youtube-video-link-container">
              <img src={youTubeVideoLink} alt="Vidéo Youtube"></img>
            </div>
            Collez ensuite cette adresse dans le champ correspondant.
            <div className="youtube-link-container">
              <span className="youtube-link">Lien vers une vidéo Y...</span>
            </div>
            Renseignez le champ du nom puis cliquez sur "OK".
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Vos adresses</h3>
          <p>
            Sous l'espace prévu pour vos documents se trouve une zone composée d'un rectange gris à
            gauche et d'un formulaire à droite.
          </p>
          <div className="modal-adresse-container">
            <div className="modal-grey-square"></div>
            <div className="modal-adresse-form-container">
              <span className="modal-adresse-adresse-item">Adresse</span>
              <div className="modal-adresse-zip-container">
                <span className="modal-adresse-adresse-item">Code postal</span>
                <span className="modal-adresse-adresse-item">Ville</span>
              </div>
              <span className="modal-adresse-adresse-item">Email</span>
              <span className="modal-adresse-adresse-item">Téléphone</span>
            </div>
          </div>
          <div className="add-productsheet-button-container">
            <div className="add-productsheet-button">
              <span>Enregistrer</span>
            </div>
          </div>
          <p>
            Renseignez les champs du formulaire correspondant à l'adresse de votre entreprise. Le
            plan (Google) de la zone de votre entreprise s'affichera dans le rectangle gris situé à
            gauche du formulaire.
          </p>
          <div className="modal-adresse-container">
            <div className="modal-grey-square with-map">
              <img src={googleMapExample} alt="Google Maps"></img>
            </div>
            <div className="modal-adresse-form-container with-map">
              <span className="modal-adresse-adresse-item">Place Saint-François</span>
              <div className="modal-adresse-zip-container">
                <span className="modal-adresse-adresse-item">06000</span>
                <span className="modal-adresse-adresse-item">Nice</span>
              </div>
              <span className="modal-adresse-adresse-item">contact@mafoire.com</span>
              <span className="modal-adresse-adresse-item">04 06 99 06 99</span>
            </div>
          </div>
        </div>
        <div className="help-modal-slide fade">
          <h3>Vos documents légaux</h3>
          <p>
            Sous vos adresses, vous trouvez l'espace dédié aux documents légaux, dans lequel vous
            allez pouvoir notamment préciser vos conditions générales de vente.
          </p>
          <p>
            Cliquez sur l'icône représentant un crayon sur la droite de l'écran, puis écrivez ou
            collez votre texte.
          </p>
          <p>
            Comme dans les autres onglets, n'hésitez pas à tester le rendu de vos choix avec le
            bouton "Prévisualiser le stand", situé au dessus et à gauche de la barre de menu du
            stand.
          </p>
          <p>
            Il vous permet d'afficher votre stand en mode "VISITEUR". Vous pouvez ainsi
            prévisualiser ce que vera le visiteur lorsqu'il se rendra sur votre stand.
          </p>
          <p>
            N.B. : Lorsque vous passez en mode "VISITEUR", cette fenêtre disparait. Passez en mode
            "EXPOSANT" et cliquez de nouveau sur "Besoin d'aide?" pour rouvrir cette fenêtre.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomeModal
