import {
    http,
    httpWithAuth
} from '../../helpers/http'
import {
    LOAD_ACCOUNT_REQUEST,
    LOAD_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_REQUEST,
    EDIT_ACCOUNT_SUCCESS,
    LOAD_BUSINESS_TYPES_REQUEST,
    LOAD_BUSINESS_TYPES_SUCCESS,
    HOME_LOADED,
    LOGIN_LOADED,
    CONNECT_STRIPE_ACCOUNT,
    SUBSCRIPTIONS_LOADED,
    INTENT_LOADED,
    CARD_CHANGED,
    CARD_LOADED
} from './actionTypes'
import {
    getProfile
} from '../../helpers/local-storage'
import {
    message
} from 'antd'
import * as Subscription from '../../api/subscription'
import history from '../../helpers/history'


export const loadBusinessTypes = () => dispatch => {
    const profile = getProfile()
    if (profile) {
        dispatch({
            type: LOAD_BUSINESS_TYPES_REQUEST,
            payload: {},
        })

        const options = {
            method: 'get',
            url: '/api/accounts/businesstypes',
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        http(options).then(results => {
            dispatch({
                type: LOAD_BUSINESS_TYPES_SUCCESS,
                payload: results,
            })
        })
    }
}

export const loadAccount = user => dispatch => {
    const profile = getProfile()
    if (profile) {
        dispatch({
            type: LOAD_ACCOUNT_REQUEST,
            payload: {},
        })

        const options = {
            method: 'get',
            url: `/api/accounts/${user.account_id}`,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        http(options).then(results => {
            const account = results
            dispatch({
                type: LOAD_ACCOUNT_SUCCESS,
                payload: account,
            })
        })
    }
}

export const editAccount = datas => dispatch => {
    const profile = getProfile()
    if (profile) {
        dispatch({
            type: EDIT_ACCOUNT_REQUEST,
            payload: datas,
        })

        datas.renewal = Number(datas.renewal)

        const formData = new FormData()
        Object.entries(datas).forEach(([k, v]) => {
            if (typeof v === 'object' && k !== 'kbis' && v !== undefined && v !== null) {
                formData.append(k, JSON.stringify(v))
            } else if (v !== undefined && v !== null) {
                formData.append(k, v)
            }
        })

        const options = {
            method: 'POST',
            url: `/api/accounts/${datas.account_id}`,
            payload: formData,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        http(options, getProfile()).then(results => {
            dispatch({
                type: EDIT_ACCOUNT_SUCCESS,
                payload: {
                    ...results,
                },
            })
        })
    }
}

export const connectStripeAccount = (accountId, code) => async dispatch => {
    try {
        const account = await httpWithAuth(
            `/api/accounts/${accountId}/connect-stripe-account`, {
                code
            }, {
                method: 'POST'
            }
        )
        dispatch({
            type: CONNECT_STRIPE_ACCOUNT,
            payload: account
        })
    } catch (e) {
        message.error(
            "Une erreur est survenue. Votre compte Stripe n'a pas été connecté à votre compte mafoire.com. Veuillez réessayer plus tard."
        )
        history.push('/mon-compte')
    }
}

export const homeLoaded = () => dispatch => {
    dispatch({
        type: HOME_LOADED,
    })
}

export const loginLoaded = () => dispatch => {
    dispatch({
        type: LOGIN_LOADED,
    })
}

export const loadSubscriptions = () => async dispatch => {
    try {
        const subscriptions = await Subscription.get()
        dispatch({
            type: SUBSCRIPTIONS_LOADED,
            payload: subscriptions
        })
    } catch (e) {
        message.error('Impossible de récupérer les abonnements. Veuillez réessayer plus tard.')
    }
}

export const cancelSubscription = subscriptionID => async dispatch => {
    try {
        const subscriptions = await Subscription.cancel(subscriptionID)
        dispatch({
            type: SUBSCRIPTIONS_LOADED,
            payload: subscriptions
        })
    } catch (e) {
        message.error("Impossible de suspendre la prochaine échéance. Veuillez réessayer plus tard")
    }
}


export const getIntent = () => async dispatch => {
    try {
        const intent = await Subscription.getIntent()
        dispatch({
            type: INTENT_LOADED,
            payload: intent
        })
    } catch (e) {
        message.error("Impossible de commencer la récuperation de paiement. Veuillez réessayer plus tard")
    }
}

export const updateCard = (paymentMethod) => async dispatch => {
    try {
        const card = await Subscription.postUpdateCard(paymentMethod)
        dispatch({
            type: CARD_CHANGED,
            payload: card
        })
        message.info("La carte a été correctement mise à jour.")

    } catch (e) {
        message.error("Impossible de lancer le changement, Veuillez réessayer plus tard")

    }
}


export const getCurrentCard = () => async dispatch => {
    try {
        const card = await Subscription.getCurrentDefaultSource()
        dispatch({
            type: CARD_LOADED,
            payload: card
        })
    } catch (e) {
        message.error("Impossible de récuperer les informations de votre carte. Veuillez réessayer plus tard")
    }
}