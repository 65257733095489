import React, { Component } from 'react'
import { Icon, Upload, Button, Tooltip } from 'antd'
import { Loader } from '@mafoire/components'
import ColorPicker from '../../general/ColorPicker'
import Stand01 from '../../../../assets/images/stands/stand_01.png'
import Stand02 from '../../../../assets/images/stands/stand_02.png'
import Stand03 from '../../../../assets/images/stands/stand_03.png'
import Stand04 from '../../../../assets/images/stands/stand_04.png'
import Stand05 from '../../../../assets/images/stands/stand_05.png'
import Stand06 from '../../../../assets/images/stands/stand_06.png'
import Stand07 from '../../../../assets/images/stands/stand_07.png'
import Stand08 from '../../../../assets/images/stands/stand_08.png'
import logo from '../../../../assets/images/mafoire-icon.png'
import HelpModal from '../../guides/HelpModal'
import './StandConfiguration.css'

class StandConfiguration extends Component {
  state = {
    logoLoading: false,
    visible: false,
  }

  showModal = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  editStandRequest = async (property, value) => {
    const payload = {
      ...this.props.stand,
      [property]: value,
    }
    await this.props.editStand(payload)
  }

  selectColor = (value, index) => {
    this.props.colors[index] = value
    this.editStandRequest('colors', JSON.stringify(this.props.colors))
  }

  switchStandType = value => {
    this.editStandRequest('type_id', value)
  }

  selectAvatar = value => {
    this.editStandRequest('avatar_id', value)
  }

  uploadLogo = async value => {
    this.setState({ logoLoading: true })
    await this.editStandRequest('image', value)
    this.setState({ logoLoading: false })
  }

  avatarsArray() {
    let avatarsArray = []
    for (let i = 1; i < 13; i++) {
      avatarsArray.push({
        id: i,
        thumbnail: `${window.process.env.APP_AVATARS_URL}${i}-thumbnail.jpg`,
      })
    }
    return avatarsArray
  }

  render() {
    const { stand, standTypeLoading, standColorsLoading, colors, standAvatarLoading } = this.props
    const { logoLoading } = this.state

    const standType = parseInt(stand.type_id, 10)

    return (
      <div className="configuration">
        <div className="stand-config-text">
          Cliquez sur les différentes icônes ou onglets pour configurer votre stand
        </div>
        <div className="types-and-colors">
          <div className="section stand-type">
            <div className="title">
              Types de stand {standTypeLoading && <Icon type="loading" />}
            </div>
            <div className="stands-list">
              <div
                className={'stand stand01 ' + (standType === 1 ? 'selected' : '')}
                onClick={() => this.switchStandType(1)}
              >
                <img src={Stand01} alt="stand 01" />
              </div>
              <div
                className={'stand stand02 ' + (standType === 2 ? 'selected' : '')}
                onClick={() => this.switchStandType(2)}
              >
                <img src={Stand02} alt="stand 02" />
              </div>
              <div
                className={'stand stand03 ' + (standType === 3 ? 'selected' : '')}
                onClick={() => this.switchStandType(3)}
              >
                <img src={Stand03} alt="stand 03" />
              </div>
              <div
                className={'stand stand04 ' + (standType === 4 ? 'selected' : '')}
                onClick={() => this.switchStandType(4)}
              >
                <img src={Stand04} alt="stand 04" />
              </div>
              <div
                className={'stand stand05 ' + (standType === 5 ? 'selected' : '')}
                onClick={() => this.switchStandType(5)}
              >
                <img src={Stand05} alt="stand 05" />
              </div>
              <div
                className={'stand stand06 ' + (standType === 6 ? 'selected' : '')}
                onClick={() => this.switchStandType(6)}
              >
                <img src={Stand06} alt="stand 06" />
              </div>
              <div
                className={'stand stand07 ' + (standType === 7 ? 'selected' : '')}
                onClick={() => this.switchStandType(7)}
              >
                <img src={Stand07} alt="stand 07" />
              </div>
              <div
                className={'stand stand08 ' + (standType === 8 ? 'selected' : '')}
                onClick={() => this.switchStandType(8)}
              >
                <img src={Stand08} alt="stand 08" />
              </div>
            </div>
          </div>

          <div className="section colors">
            <div className="title">
              Couleurs de votre stand{standColorsLoading && <Icon type="loading" />}
            </div>
            <div className="colors-list">
              {standType !== 4 && (
                <div className="color-picker-block">
                  <ColorPicker
                    color={colors[0]}
                    selectColor={value => this.selectColor(value, 0)}
                    size={40}
                  />
                  <div className="color-legend">
                    <h3>Cloisons - Barre d’Accueil - Nom du Stand</h3>
                  </div>
                </div>
              )}

              <div className="color-picker-block">
                <ColorPicker
                  color={colors[1]}
                  selectColor={value => this.selectColor(value, 1)}
                  size={40}
                />
                <div className="color-legend">
                  <h3>Sol</h3>
                </div>
              </div>

              {standType !== 3 && (
                <div className="color-picker-block">
                  <ColorPicker
                    color={colors[2]}
                    selectColor={value => this.selectColor(value, 2)}
                    size={40}
                  />
                  <div className="color-legend">
                    <h3>Comptoir</h3>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="section logo">
            <div className="title">Logo</div>
            <div className="logo-container">
              {stand.image && <img src={stand.image} alt="logo" />}
            </div>
            <div className="upload-button">
              <Upload
                name="file"
                disabled={logoLoading}
                showUploadList={false}
                customRequest={image => {
                  this.uploadLogo(image.file)
                }}
              >
                <Button className="ant-upload-button">
                  {logoLoading ? <Loader /> : <Icon type="upload" />}
                  <span className="label">Télécharger une image</span>
                </Button>
              </Upload>
              <div className="file-types-block">
                <p>Téléchargez un fichier de type .jpg, .gif, animation gif ou .png.</p>
                <p>
                  Nous vous conseillons d'utiliser un logo en mode paysage plutôt qu'en mode
                  portrait.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section avatar">
          <div className="title">
            Choisissez votre conseiller.e {standAvatarLoading ? <Icon type="loading" /> : ''}
          </div>
          <div className="avatars-list">
            {this.avatarsArray().map(avatar => (
              <div
                key={avatar.id}
                className={'avatar-item ' + (stand.avatar_id === avatar.id ? 'selected' : '')}
                onClick={() => this.selectAvatar(avatar.id)}
              >
                <img src={avatar.thumbnail} alt="avatar" />
              </div>
            ))}
          </div>
        </div>
        {this.state.visible && (
          <HelpModal
            showModal={this.showModal}
            stand={stand}
            settings
            avatarsArray={this.avatarsArray}
            className="help-modal-container"
          />
        )}
        <Tooltip
          overlayClassName="help-tooltip"
          title="Bonjour! 👋 Si vous avez besoin d'aide pour configurer votre stand, cliquez sur ce bouton"
        >
          <Button className="help-button" onClick={this.showModal}>
            <img src={logo} alt=""></img>
            <span className="help-label">Besoin d'aide ?</span>
          </Button>
        </Tooltip>
      </div>
    )
  }
}

export default StandConfiguration
