import uuid from 'uuid'

export const generateAndStoreStripeURLState = () => {
    let stripeURLState = sessionStorage.getItem('stripeURLState')
    if (!stripeURLState) {
        stripeURLState = uuid.v4()
        sessionStorage.setItem('stripeURLState', stripeURLState)
    }
    return stripeURLState
}

export const retrieveStripeURLState = () => sessionStorage.getItem('stripeURLState')