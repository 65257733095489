import React, { Component } from "react";
import { connect } from "react-redux";
import {
    addCategory,
    editCategory,
    deleteCategory,
    moveCategory,
} from "../../../../domains/stands/actions";
import {
    loadProducts,
    addProduct,
    editProduct,
    deleteProduct,
    setDefault,
    deleteProductFile,
    doubleProduct,
} from "../../../../domains/products/actions";
import { Button, Tooltip } from "antd";
import logo from "../../../../assets/images/mafoire-icon.png";
import HelpModal from "../../guides/HelpModal";
import { StandEcommerce } from "@mafoire/components";
import "./StandEcommerceContainer.css";
import { CHF_SYMBOL, DEFAULT_CURRENCY_CODE, EURO_SYMBOL } from "../../../../helpers/constants";

class StandEcommerceContainer extends Component {
    state = {
        visible: false,
    };
    componentDidMount() {
        this.props.loadProducts(this.props.stand.id);
    }

    showModal = () => {
        this.setState({
            visible: !this.state.visible,
        });
    };

    render() {
        const {
            stand,
            products,
            profile,
            account,
            addProduct,
            editProduct,
            deleteProduct,
            addCategory,
            editCategory,
            deleteCategory,
            moveCategory,
            asOwner,
            setDefault,
            deleteProductFile,
            doubleProduct,
            pointToDuplicatedProduct,
        } = this.props;

        const currencySymbol =
            stand.departments
                .filter(d => d.currency_code !== undefined)
                .find(d => d.currency_code !== DEFAULT_CURRENCY_CODE) !== undefined
                ? CHF_SYMBOL
                : EURO_SYMBOL;

        return (
            <React.Fragment>
                <StandEcommerce
                    stand={stand}
                    products={products}
                    profile={profile}
                    maxFileSize={window.process.env.APP_FILE_UPLOAD_SIZE_LIMIT}
                    asOwner={asOwner}
                    stripeNotConfigured={account && !account.stripe_user_id}
                    addCategory={addCategory}
                    editCategory={editCategory}
                    moveCategory={moveCategory}
                    deleteCategory={deleteCategory}
                    createProduct={addProduct}
                    updateProduct={editProduct}
                    deleteProduct={deleteProduct}
                    setDefault={setDefault}
                    deleteProductFile={deleteProductFile}
                    doubleProduct={doubleProduct}
                    pointToDuplicatedProduct={pointToDuplicatedProduct}
                    currencySymbol={currencySymbol}
                />
                {this.state.visible && (
                    <HelpModal
                        showModal={this.showModal}
                        stand={stand}
                        catalogue
                        avatarsArray={this.avatarsArray}
                        className="help-modal-container"
                    />
                )}
                {asOwner && (
                    <Tooltip
                        overlayClassName="help-tooltip"
                        title="Bonjour! 👋 Si vous avez besoin d'aide pour créer votre catalogue de produits, cliquez sur ce bouton"
                    >
                        <Button className="help-button" onClick={this.showModal}>
                            <img src={logo} alt=""></img>
                            <span className="help-label">Besoin d'aide ?</span>
                        </Button>
                    </Tooltip>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    stand: state.stands.stand,
    products: state.products.products,
    pointToDuplicatedProduct: state.products.pointedProduct,
    account: state.account.account,
});

const mapDispatchToProps = {
    loadProducts,
    addProduct,
    editProduct,
    deleteProduct,
    addCategory,
    editCategory,
    deleteCategory,
    moveCategory,
    setDefault,
    deleteProductFile,
    doubleProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandEcommerceContainer);
