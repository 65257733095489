import { connect } from 'react-redux'
import React, { Component } from 'react'
import { reset } from '../../../domains/user/actions'
import getParameterByName from '../../../helpers/lib/getParameterByName'
import { Link } from 'react-router-dom'
import ResetForm from '../../common/user/ResetFormContainer'
import Base from '../Base'

class ResetPage extends Component {
  handleSubmit(e) {
    e.preventDefault()

    const form = this.formRef.props.form

    form.validateFields((err, values) => {
      if (err) {
        return
      }

      const token = getParameterByName('token')

      const datas = {
        ...values,
        token,
      }

      this.props.reset(datas)

      form.resetFields()
    })
  }

  saveFormRef(formRef) {
    this.formRef = formRef
  }

  render() {
    return (
      <Base hideBreadcrumbs={true}>
        <div className="off-site-page">
          <div className="block login-form">
            <div className="title">
              Réinitialiser le mot de passe
            </div>
            <ResetForm
              handleSubmit={this.handleSubmit.bind(this)}
              wrappedComponentRef={this.saveFormRef.bind(this)}
              {...this.props}
            />
            <Link to="/login">Retour</Link>
          </div>
        </div>
      </Base>
    )
  }
}

export default connect(
  (state, props) => ({
    isSaved: state.users.isSaved,
    message: state.users.message,
  }),
  {
    reset,
  }
)(ResetPage)
