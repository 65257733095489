import {
    http
} from '../../helpers/http';
import {
    LOAD_THEMES_REQUEST,
    LOAD_THEMES_SUCCESS,
} from './actionTypes';
import {
    getProfile
} from '../../helpers/local-storage';

export const loadThemes = () => {
    return dispatch => {
        const profile = getProfile();
        if (profile) {
            dispatch({
                type: LOAD_THEMES_REQUEST,
                payload: {}
            });

            const options = {
                method: 'get',
                url: '/api/themes',
                headers: {
                    Authorization: `${profile.token_type} ${profile.access_token}`
                }
            };

            http(options)
                .then(resultats => {
                    dispatch({
                        type: LOAD_THEMES_SUCCESS,
                        payload: resultats
                    })
                });
        }
    };
};