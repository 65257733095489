import {
    LOAD_PRODUCTS_SUCCESS,
    ADD_PRODUCT_SUCCESS,
    EDIT_PRODUCT_SUCCESS,
    RECREATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    SET_DEFAULT_SUCCESS,
    DELETE_PRODUCT_FILE_SUCCESS,
    DUPLICATE_PRODUCT_SUCCESS
} from './actionTypes'

const defaultState = {
    products: null,
    pointedProduct: null
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_PRODUCTS_SUCCESS:
            return { ...state,
                products: payload
            }

        case ADD_PRODUCT_SUCCESS:
            return { ...state,
                products: [payload, ...state.products]
            }

        case DUPLICATE_PRODUCT_SUCCESS:
            return { ...state,
                products: [payload, ...state.products],
                pointedProduct: payload
            }

        case EDIT_PRODUCT_SUCCESS:
            const products = state.products.map(product => {
                if (product.id === payload.id) {
                    return {
                        ...payload,
                    }
                }
                return product
            })
            return {
                ...state,
                products,
                productLoading: false,
            }

        case RECREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: [
                    payload.newProduct,
                    ...state.products.map(product => {
                        if (product.id === payload.archivedProduct.id) return payload.archivedProduct
                        return product
                    }),
                ],
            }

        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: state.products.filter(product => product.id !== payload),
            }

        case SET_DEFAULT_SUCCESS:
            return {
                ...state,
                products: state.products.map(product => {
                    if (product.id === payload.id) {
                        return {
                            ...product,
                            ...payload,
                        }
                    }
                    return product
                }),
                productLoading: false,
            }

        case DELETE_PRODUCT_FILE_SUCCESS:
            {
                const products = state.products.map(product => {
                    if (product.id === payload.id) {
                        return {
                            ...payload,
                        }
                    }
                    return product
                })
                return {
                    ...state,
                    products: [...products],
                    productLoading: false,
                }
            }

        default:
    }

    return state
}