import {
    http
} from '../../helpers/http';
import history from '../../helpers/history'
import {

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,

} from './actionTypes';
import {
    getProfile
} from '../../helpers/local-storage';

export const forgot = datas => {
    return dispatch => {

        dispatch({
            type: FORGOT_PASSWORD_REQUEST,
            payload: {}
        });

        const options = {
            method: 'post',
            url: `/api/auth/forgot`,
            payload: {
                email: datas.email
            }
        };

        http(options, getProfile())
            .then(resultats => {
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                    payload: resultats.message
                });
            })
            .catch(err => {
                dispatch({
                    type: FORGOT_PASSWORD_ERROR,
                    payload: err.error
                });
            });

    }
}

export const reset = datas => {
    return dispatch => {

        dispatch({
            type: RESET_PASSWORD_REQUEST,
            payload: {}
        });

        const options = {
            method: 'post',
            url: `/api/auth/reset`,
            payload: datas
        };

        http(options, getProfile())
            .then(resultats => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: resultats.message
                });
                localStorage.removeItem('email');
                setTimeout(() => {
                    history.push('/login')
                }, 1500)
            })
            .catch(err => {
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    payload: err.error
                });
            });

    }
}