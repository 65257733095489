import React from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import EditAccountSubscriptionPage from '../../common/account/EditAccountSubscriptionPage'


const EditAccountBillingPage = ({ account }) => (
  <Base
    breadcrumb={[
      { label: 'Accueil', to: '/' },
      { label: 'Mon compte', to: '/mon-compte' },
      {
        label: 'Configuration de votre abonnement Mafoire',
      },
    ]}
  >
    <EditAccountSubscriptionPage account={account} />
  </Base>
)

export default connect(
  state => ({
    account: state.account.account,
  })
)(EditAccountBillingPage)
