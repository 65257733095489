import { connect } from 'react-redux'
import React, { Component } from 'react'
import { forgot } from '../../../domains/user/actions'
import { Link } from 'react-router-dom'
import ForgotForm from '../../common/user/ForgotFormContainer'
import Base from '../Base';

class ForgotPage extends Component {
  handleSubmit(e) {
    e.preventDefault()

    const form = this.formRef.props.form

    form.validateFields((err, values) => {
      if (err) return

      this.props.forgot(values)

      localStorage.setItem('email', values.email)

      form.resetFields()
    })
  }

  saveFormRef(formRef) {
    this.formRef = formRef
  }

  render() {
    return (
      <Base hideBreadcrumbs>
        <div className="off-site-page">
          <div className="block login-form">
            <div className="title">Mot de passe oublié ?</div>
            <ForgotForm
              handleSubmit={this.handleSubmit.bind(this)}
              wrappedComponentRef={this.saveFormRef.bind(this)}
              {...this.props}
            />
            <Link to="/login">Retour</Link>
          </div>
        </div>
      </Base>
    )
  }
}

export default connect(
  (state, props) => ({
    isSaved: state.users.isSaved,
    message: state.users.message,
  }),
  {
    forgot,
  }
)(ForgotPage)
