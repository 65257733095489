export const LOAD_MEDIAS_REQUEST = 'LOAD_MEDIAS_REQUEST';
export const LOAD_MEDIAS_SUCCESS = 'LOAD_MEDIAS_SUCCESS';
export const UPLOAD_MEDIA_REQUEST = 'UPLOAD_MEDIA_REQUEST';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_TO_CATEGORY_REQUEST = 'UPLOAD_MEDIA_TO_CATEGORY_REQUEST';
export const UPLOAD_MEDIA_TO_CATEGORY_SUCCESS = 'UPLOAD_MEDIA_TO_CATEGORY_SUCCESS';
export const UPLOAD_MEDIA_TO_CORPORATE_REQUEST = 'UPLOAD_MEDIA_TO_CORPORATE_REQUEST';
export const UPLOAD_MEDIA_TO_CORPORATE_SUCCESS = 'UPLOAD_MEDIA_TO_CORPORATE_SUCCESS';
export const DESTROY_REQUEST = 'MEDIAS/DESTROY_REQUEST';
export const DESTROY_SUCCESS = 'MEDIAS/DESTROY_SUCCESS';
export const REINIT_CURRENT_MEDIA = 'REINIT_CURRENT_MEDIA';
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS'
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS'
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS'