import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '@mafoire/components'

import './LoginFormContainer.css'

class LoginForm extends Component {
  state = { email: '', password: '' }

  updateValue = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { handleSubmit, profile } = this.props
    const { email, password } = this.state

    const submitForm = () => handleSubmit({ email: email, password: password })

    const handleKeyDown = e => e.key === 'Enter' && submitForm()

    return (
      <div className="login-form-container">
        <div className="form-item">
          <div className="form-item-title">Email :</div>
          <input
            type="text"
            name="email"
            placeholder="Saisir votre adresse email"
            autoFocus
            className="input-login"
            onChange={this.updateValue}
            maxLength={60}
          />
        </div>
        <div className="form-item">
          <div className="form-item-title">Mot de passe :</div>
          <input
            type="password"
            name="password"
            placeholder="Saisir votre mot de passe"
            className="input-login"
            maxLength={60}
            onChange={this.updateValue}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="forgot-password">
          <Link to="/user/forgot">Mot de passe oublié ?</Link>
        </div>
        <div className="flex-center">
          <button className="ant-btn-primary" disabled={!email || !password} onClick={submitForm}>
            <span>Poursuivre</span>
            {profile.isLoging && <Loader />}
          </button>
        </div>

        <div>{profile.message }</div>
        
      </div>
    )
  }
}

export default LoginForm
