import React, { Component } from 'react'
import {
  Form,
  Input,
  Icon,
  Button,
  Divider,
  Checkbox,
  Select,
  Row,
  Switch,
  Upload,
  message,
  Avatar,
} from 'antd'
import { Loader } from '@mafoire/components'
import { NavLink } from 'react-router-dom'
import { checkFile } from '../../../helpers/functions'
import './AgentForm.css'

const Option = Select.Option

class EditAgentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addressCheckState: false,
      phoneCheckState: false,
      checkedStands: [],
      file: null,
      fileLocalUrl: null,
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.agent) {
      this.initCheckedStands()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.file && prevState.file !== this.state.file) {
      this.computePreview()
    }
    if (!prevProps.agent && this.props.agent) {
      this.initCheckedStands()
    }
  }

  initCheckedStands = () => {
    this.setState({
      checkedStands: this.props.agent.user_stands.map(userStand => ({
        id: userStand.stand_id,
        departments: userStand.departments,
      })),
    })
  }

  storeFile = file => {
    this.setState({ file })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { file } = this.state
    const { agent, editAgent } = this.props
    this.props.form.validateFields(async (err, values) => {
      if (err) return
      this.setState({ loading: true })
      const data = {
        ...values,
        id: this.props.agent.id,
        stands: this.state.checkedStands,
        file,
        avatar: agent.avatar,
      }
      await editAgent(data)
      this.setState({ file: null, loading: false })
    })
  }

  onChange = checkedList => {
    const checkedStands = checkedList.map(id => {
      const item = this.state.checkedStands.find(stand => stand.id === id)
      if (item) {
        return item
      }
      return { id, departments: JSON.stringify([]) }
    })
    this.setState({
      checkedStands,
    })
  }

  onSelectChange = (value, standId) => {
    const checkedStands = this.state.checkedStands.map(checkedStand => {
      if (checkedStand.id === standId) {
        return { ...checkedStand, departments: JSON.stringify(value) }
      }
      return checkedStand
    })
    this.setState({
      checkedStands,
    })
  }

  computePreview = async () => {
    this.setState(prevState => ({ fileLocalUrl: URL.createObjectURL(prevState.file) }))
  }

  render() {
    const { form, agent, stands, maxFileSize } = this.props
    const { getFieldDecorator } = form
    const { file, fileLocalUrl, loading } = this.state

    const agentStands =
      agent &&
      agent.user_stands.map(userStand => {
        const stand = agent.stands.find(stand => stand.id === userStand.stand_id)
        return { ...userStand, stand }
      })

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <div className="agent-form-wrapper">
        {!agent ? (
          <Loader fullPage />
        ) : (
          <Form className="add-agent-form" onSubmit={this.handleSubmit} {...formItemLayout}>
            <Form.Item className="name-input" label="Nom du vendeur">
              {getFieldDecorator('name', {
                initialValue: agent.name,
                rules: [
                  {
                    required: true,
                    message: "Veuillez renseigner le nom du vendeur'",
                  },
                ],
              })(<Input type="text" placeholder="Stéphane Vendeur" autoFocus />)}
            </Form.Item>
            <Form.Item label="E-mail" className="email-form-input">
              {getFieldDecorator('email', {
                initialValue: agent.email,
                rules: [
                  {
                    required: true,
                    message: "Veuillez confirmer l'e-mail du vendeur'",
                  },
                ],
              })(<Input type="text" placeholder="jean-pierre@gmail.com" />)}
            </Form.Item>
            <Form.Item label="Actif">
              {getFieldDecorator('status', {
                initialValue: agent.status,
                valuePropName: 'checked',
                getValueFromEvent: checked => (checked ? 1 : 0),
              })(<Switch />)}
            </Form.Item>

            <div className="picture">
              <div>
                <div className="avatar-label">Avatar:</div>

                {file && fileLocalUrl ? (
                  <div className="profile-avatar">
                    <img src={fileLocalUrl} alt={file.name} />
                  </div>
                ) : agent && agent.avatar ? (
                  <div className="profile-avatar">
                    <img src={agent.avatar} alt="Produit" />
                  </div>
                ) : (
                  <Avatar size={70} className="profile-avatar" icon="user" />
                )}
              </div>

              <Upload
                name="file"
                beforeUpload={image => {
                  if (checkFile(image, message.error, ['image'], maxFileSize)) this.storeFile(image)
                  return false
                }}
                disabled={this.state.loading}
                showUploadList={false}
                customRequest={() => false}
              >
                <Button type="primary">
                  <Icon type="upload" />
                  <span className="label">Télécharger un fichier</span>
                </Button>
              </Upload>
            </div>

            <Divider className="stands-bound">Stands et départements potentiels</Divider>
            <div className="checkbox-section">
              {getFieldDecorator('stands', {
                initialValue: agentStands && agentStands.map(agentStand => agentStand.stand_id),
              })(
                <Checkbox.Group className="checkbox-group-section" onChange={this.onChange}>
                  <Row className="checkbox-section-row">
                    {stands &&
                      stands.map(stand => {
                        const agentStand = agentStands.find(
                          agentStand => agentStand.stand_id === stand.id
                        )
                        return (
                          <div className="checkbox-tag-block" key={stand.id}>
                            <Checkbox className="stand-checkbox" value={stand.id}>
                              {stand.name}
                            </Checkbox>
                            {this.state.checkedStands &&
                              this.state.checkedStands.some(item => stand.id === item.id) && (
                                <Select
                                  className="departments-select"
                                  mode="tags"
                                  showArrow
                                  defaultValue={
                                    agentStand ? JSON.parse(agentStand.departments) : []
                                  }
                                  placeholder="Attribuer un département"
                                  onChange={value => this.onSelectChange(value, stand.id)}
                                >
                                  {stand.departments.map(dep => (
                                    <Option
                                      key={dep.id}
                                      className="stand-county-tag"
                                      value={dep.code}
                                    >
                                      {dep.code} {dep.name}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                          </div>
                        )
                      })}
                  </Row>
                </Checkbox.Group>
              )}
            </div>
            <Form.Item {...tailFormItemLayout}>
              <div className="add-agent-form-buttons">
                <Button className="add-agent-cancel" type="secondary">
                  <NavLink to="/vendeurs">Annuler</NavLink>
                </Button>
                <Button className="add-exposant" type="primary" htmlType="submit">
                  Enregistrer{loading && <Icon type="loading" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    )
  }
}

export default EditAgentForm
