//base de départ : copier coller depuis le form sur l'app admin

import React from 'react'
import { Button, Form, Input, Select } from 'antd'

const { Option } = Select

const StandForm = ({ standIndex, form, initialStandName, activeDepartments, themes, onClose }) => {
  const { getFieldDecorator } = form

  return (
    <div className="stand-form">
      <Form.Item label="Nom">
        {getFieldDecorator(`stands[${standIndex}].name`, {
          initialValue: initialStandName,
          rules: [{ required: true, message: 'Veuillez renseigner un nom de stand' }],
        })(<Input placeholder="Nom du stand" />)}
      </Form.Item>

      <Form.Item label="Sélectionnez votre pack">
        {getFieldDecorator(`stands[${standIndex}].pack`, {
          rules: [{ required: true, message: 'Veuillez définir un pack pour le stand' }],
        })(
          <Select placeholder="Offre">
            <Option value="lead">Pack contacts</Option>
            <Option value="ecommerce">Pack e-commerce</Option>
            <Option value="unlimited">Pack contacts + option e-commerce</Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Sélectionnez votre département">
        {getFieldDecorator(`stands[${standIndex}].departments`, {
          rules: [
            { required: true, message: 'Veuillez assigner un ou plusieurs département au stand' },
          ],
        })(
          <Select mode="multiple" placeholder="Départements du stand">
            {activeDepartments &&
              activeDepartments.map(dep => (
                <Option key={dep.id} value={dep.code}>
                  {dep.code} {dep.name}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>

      {onClose && (
        <Form.Item
          wrapperCol={{
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button type="secondary" onClick={onClose}>
            Supprimer le stand
          </Button>
        </Form.Item>
      )}
    </div>
  )
}

export default StandForm