import React, {
    Component
} from 'react';
import {
    Input,
    Button,
    Form,
    Icon
} from 'antd';

const FormItem = Form.Item;

const ForgotForm = Form.create()(

        class extends Component {

            render() {
                const {
                    handleSubmit,
                    form,
                    isSaved,
                    message
                } = this.props;

                const {
                    getFieldDecorator
                } = form;

                return ( <
                    Form layout = "inline"
                    onSubmit = {
                        handleSubmit
                    } >
                    <
                    FormItem label = "E-Mail"
                    className = "loginForm__label loginForm__label--email" > {
                        getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: 'Veuillez saisir cette information'
                            }]
                        })( <
                            Input type = "text"
                            placeholder = "jean@monsite.com"
                            autoFocus className = "loginForm__input loginForm__input--email"
                            maxLength = "60" / >
                        )
                    } <
                    /FormItem> <
                    FormItem className = "reinit" >
                    <
                    Button type = "primary"
                    htmlType = "submit" >
                    <
                    div className = "flex-center" >
                    <
                    span > Réinitialiser < /span> {
                        !isSaved && < div className = "loading" > < Icon type = "loading" / > < /div>} <
                            /div> <
                            /Button> <
                            /FormItem> <
                            FormItem className = "message" > {
                                message && < div > {
                                    message
                                } < /div>} <
                                /FormItem> <
                                /Form>
                            );
                    }
                }
            )

            export default ForgotForm