export const LOAD_CONVERSATIONS_REQUEST = 'LOAD_CONVERSATIONS_REQUEST'
export const LOAD_CONVERSATIONS_SUCCESS = 'LOAD_CONVERSATIONS_SUCCESS'
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR'
export const RETRY_SEND_MESSAGE_REQUEST = 'RETRY_SEND_MESSAGE_REQUEST'
export const RETRY_SEND_MESSAGE_SUCCESS = 'RETRY_SEND_MESSAGE_SUCCESS'
export const RETRY_SEND_MESSAGE_ERROR = 'RETRY_SEND_MESSAGE_ERROR'
export const SET_CONVERSATION_AS_SEEN_SUCCESS = 'SET_CONVERSATION_AS_SEEN_SUCCESS'
export const SET_CONVERSATION_AS_SEEN_ERROR = 'SET_CONVERSATION_AS_SEEN_ERROR'
export const LOAD_AGENTS_SUCCESS = 'LOAD_AGENTS_SUCCESS'
export const SELECT_CONVERSATION_REQUEST = 'SELECT_CONVERSATION_REQUEST'
export const SELECT_CONVERSATION_SUCCESS = 'SELECT_CONVERSATION_SUCCESS'
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE'