import {
    CARTS_LOADED,
    PAID_CARTS_LOADED,
    STATUS_UPDATED,
} from './actionTypes'
import {
    getCarts,
    getPaidCarts,
    updateCart
} from '../../api/ecommerce'
import {
    message
} from 'antd'

export const loadCarts = () => async dispatch => {
    try {
        const carts = await getCarts()
        dispatch({
            type: CARTS_LOADED,
            payload: carts
        })
    } catch (e) {
        message.error('Impossible de charger les paniers. Veuillez réessayer plus tard.')
    }
}

export const loadPaidCarts = () => async dispatch => {
    try {
        const paidCarts = await getPaidCarts()
        dispatch({
            type: PAID_CARTS_LOADED,
            payload: paidCarts
        })
    } catch (e) {
        message.error('Impossible de charger les paniers payés. Veuillez réessayer plus tard.')
    }
}

export const editCartStatus = (cartid, status) => async dispatch => {
    try {
        const updatedCart = await updateCart(cartid, {
            cart_status: status
        })
        dispatch({
            type: STATUS_UPDATED,
            payload: updatedCart
        })
    } catch (e) {
        message.error('Impossible de modifier le statut de la commande. Veuillez réessayer plus tard.')
        throw new Error(e)
    }
}