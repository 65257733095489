import {
    LOAD_CONVERSATIONS_REQUEST,
    LOAD_CONVERSATIONS_SUCCESS,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    RETRY_SEND_MESSAGE_REQUEST,
    RETRY_SEND_MESSAGE_SUCCESS,
    RETRY_SEND_MESSAGE_ERROR,
    SET_CONVERSATION_AS_SEEN_SUCCESS,
    SET_CONVERSATION_AS_SEEN_ERROR,
    LOAD_AGENTS_SUCCESS,
    SELECT_CONVERSATION_REQUEST,
    SELECT_CONVERSATION_SUCCESS,
    RECEIVE_MESSAGE,
} from './actionTypes'
import {
    getConversations,
    sendMessage,
    setConversationHasSeenAction
} from '../../api/chat'
import {
    getAgents
} from '../../api/agents'
import {
    getProfile
} from '../../api/user'
import {
    getStands
} from '../../api/stands'
import {
    message
} from 'antd'
import uuid from 'uuid'

export const loadConversations = () => async dispatch => {
    dispatch({
        type: LOAD_CONVERSATIONS_REQUEST
    })
    try {
        const profile = await getProfile()
        const agents = await getAgents()
        const stands = await getStands()
        const conversations = await getConversations()
        dispatch({
            type: LOAD_CONVERSATIONS_SUCCESS,
            payload: {
                conversations,
                agents,
                stands,
                profile,
            },
        })
    } catch (e) {
        message.error('Impossible de charger les conversations. Veuillez réessayer plus tard.')
    }
}

export const sendMessageAction = (payload, profile) => async dispatch => {
    const tempMessageId = uuid.v4()

    dispatch({
        type: SEND_MESSAGE_REQUEST,
        payload: {
            message: {
                ...payload,
                tempMessageId,
            },
            profile,
        },
    })
    try {
        const messageSent = await sendMessage(payload)
        dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: {
                message: messageSent,
                tempMessageId,
            },
        })
    } catch (e) {
        message.error("Impossible d'envoyer le message. Veuillez réessayer plus tard.")
        dispatch({
            type: SEND_MESSAGE_ERROR,
            payload: {
                message: {
                    ...payload,
                    tempMessageId,
                },
                tempMessageId,
            },
        })
    }
}

export const retrySendMessageAction = message => async dispatch => {
    dispatch({
        type: RETRY_SEND_MESSAGE_REQUEST,
        payload: message,
    })
    try {
        let messageSent = await sendMessage(message)
        dispatch({
            type: RETRY_SEND_MESSAGE_SUCCESS,
            payload: {
                ...message,
                ...messageSent,
            },
        })
    } catch (e) {
        message.error("Impossible d'envoyer le message. Veuillez réessayer plus tard.")
        dispatch({
            type: RETRY_SEND_MESSAGE_ERROR,
            payload: message,
        })
    }
}

export const setConversationHasSeen = conversation_id => async dispatch => {
    try {
        let conversation = await setConversationHasSeenAction(conversation_id)
        dispatch({
            type: SET_CONVERSATION_AS_SEEN_SUCCESS,
            payload: conversation,
        })
    } catch (e) {
        dispatch({
            type: SET_CONVERSATION_AS_SEEN_ERROR,
        })
    }
}

export const loadAgents = () => async dispatch => {
    try {
        const agents = await getAgents()
        dispatch({
            type: LOAD_AGENTS_SUCCESS,
            payload: agents
        })
    } catch (e) {
        message.error('Impossible de charger les agents. Veuillez réessayer plus tard.')
    }
}

export const selectConversation = selectedConversation => dispatch => {
    dispatch({
        type: SELECT_CONVERSATION_REQUEST
    })
    dispatch({
        type: SELECT_CONVERSATION_SUCCESS,
        payload: selectedConversation
    })
}

export const receiveMessage = payload => dispatch => {
    dispatch({
        type: RECEIVE_MESSAGE,
        payload
    })
}