import { Provider } from 'react-redux'
import React, { Component } from 'react'
import Routes from './Routes'
import history from './helpers/history'
import store from './store'
import './App.css'

class App extends Component {
  componentDidMount() {
    store.dispatch({ type: 'APP_STARTED' })
  }

  render() {
    return (
      <Provider store={store}>
        <Routes {...this.props} history={history} />
      </Provider>
    )
  }
}

export default App
