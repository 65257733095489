import {
    LOAD_VISITORS,
    LOAD_VISITOR,
    LOAD_LEAD_CONTACTS,
    CHECK_LEADCONTACT_REQUEST,
    CHECK_LEADCONTACT_SUCCESS,
} from './actionTypes'

const defaultState = {
    visitors: null,
    visitorTracking: null,
    leadsContacts: null,
    checkStatus: true,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_VISITORS:
            return { ...state,
                visitors: payload
            }

        case LOAD_VISITOR:
            return { ...state,
                visitorTracking: payload
            }

        case LOAD_LEAD_CONTACTS:
            return { ...state,
                leadsContacts: payload
            }

        case CHECK_LEADCONTACT_REQUEST:
            {
                const leadsContacts = state.leadsContacts.splice(0).map((leadContact) => {
                    if (leadContact.id === payload.leadContact.id) {
                        return {
                            ...leadContact,
                            checked: payload.value,
                        }
                    }
                    return leadContact
                })
                return {
                    ...state,
                    leadsContacts,
                }
            }

        case CHECK_LEADCONTACT_SUCCESS:
            {
                const leadsContacts = state.leadsContacts.splice(0).map((leadContact) => {
                    if (leadContact.id === payload.id) {
                        return {
                            ...leadContact,
                            ...payload,
                        }
                    }
                    return leadContact
                })
                return {
                    ...state,
                    leadsContacts,
                }
            }

        default:
    }

    return state
}