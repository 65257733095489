import { connect } from 'react-redux'
import React from 'react'
import Base from '../Base'
import { editStand } from '../../../domains/stands/actions'
import StandsList from '../../common/stands/StandsList'
import './StandsPageContainer.css'
import { Alert } from 'antd'
import { Link } from 'react-router-dom'

let kbisPasVerif = "Votre Kbis n'a pas encore été verifié par les équipes Mafoire.com."
let kbisPasEntre = "Vous n'avez pas encore entré votre Kbis, nous vous invitons à l'entrer ici."
let kbisZero =
  "Votre KBIS a été vérifié par les équipes Mafoire.com mais il n'a pas été validé, nous vous invitons à le rectifier ici."

const StandsPage = ({ stands, themes, account, standsLoading, editStand, statusLoading }) => (
  <Base
    breadcrumb={[
      { label: 'Accueil', to: '/' },
      { label: 'Mes stands', to: '/stands' },
    ]}
  >
    {account && account.kbis_status !== 1 && (
      <Link to={'/mon-compte/editer'}>
        <Alert
          message={
            account.kbis_url === null
              ? kbisPasEntre
              : account.kbis_status === 0
              ? kbisZero
              : kbisPasVerif
          }
          banner
          closable
        />
      </Link>
    )}

    <div className="page-container stands center-container">
      <StandsList
        stands={stands}
        themes={themes}
        account={account}
        standsLoading={standsLoading}
        editStand={editStand}
        statusLoading={statusLoading}
      />
    </div>
  </Base>
)

export default connect(
  state => ({
    stands: state.stands.stands,
    themes: state.themes.themes,
    account: state.account.account,
    standsLoading: state.stands.standsLoading,
    standsEditing: state.stands.standsEditing,
    standsDeleting: state.stands.standsDeleting,
    statusLoading: state.stands.statusLoading,
  }),
  {
    editStand,
  }
)(StandsPage)
