import {
    LOAD_STANDS_REQUEST,
    LOAD_STANDS_SUCCESS,
    LOAD_STAND_REQUEST,
    LOAD_STAND_SUCCESS,
    ADD_STAND_REQUEST,
    ADD_STAND_SUCCESS,
    EDIT_STAND_REQUEST,
    EDIT_STAND_SUCCESS,
    ADD_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    UPDATE_STAND_ADDRESS,
    DELETE_STAND_ADDRESS_REQUEST,
    DELETE_STAND_ADDRESS_SUCCESS,
    ADD_STAND_ADDRESS,
    MOVE_CATEGORY_SUCCESS,
    LOAD_ESTIMATE_SUCCESS,
    LOAD_DEPARTMENTS_SUCCESS,
    EDIT_MASS_STAND_SUCCESS,
    LOAD_ESTIMATE_REQUEST,
    SET_SHIPPING_FEES_SUCCESS
} from './actionTypes'

const defaultState = {
    stands: undefined,
    stand: undefined,
    filteredStands: [],
    standsLoading: true,
    standLoading: false,
    standIsSaving: false,
    standsDeleting: [],
    filters: [],
    currentStandEdit: false,
    standModalIsOpened: false,
    standTypeLoading: false,
    standColorsLoading: false,
    titleLoading: false,
    descriptionLoading: false,
    mediaLoading: false,
    statusLoading: false,
    infosFormDisplayed: false,
    addressIsDeleting: false,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_STANDS_REQUEST:
            {
                return {
                    ...state,
                    standsLoading: true,
                }
            }

        case LOAD_STANDS_SUCCESS:
            {
                return {
                    ...state,
                    stands: payload,
                    standsLoading: false,
                }
            }

        case LOAD_ESTIMATE_SUCCESS:
            {
                return {
                    ...state,
                    estimate: payload,
                    estimateLoading: false
                }
            }

        case LOAD_ESTIMATE_REQUEST:
            {
                return {
                    ...state,
                    estimateLoading: true
                }
            }



        case LOAD_STAND_REQUEST:
            {
                return {
                    ...state,
                    standLoading: payload,
                    infosFormDisplayed: false,
                }
            }

        case LOAD_STAND_SUCCESS:
            {
                return { ...state,
                    stand: payload
                }
            }

        case LOAD_DEPARTMENTS_SUCCESS:
            {
                return { ...state,
                    activeDepartments: payload
                }
            }

        case ADD_CATEGORY_SUCCESS:
            {
                return {
                    ...state,
                    stand: {
                        ...state.stand,
                        categories: [...state.stand.categories, payload],
                    },
                }
            }

        case EDIT_CATEGORY_SUCCESS:
            {
                return {
                    ...state,
                    stand: {
                        ...state.stand,
                        categories: state.stand.categories.map(category =>
                            category.id !== payload.id ? category : payload
                        ),
                    },
                }
            }

        case MOVE_CATEGORY_SUCCESS:
            const movedCategory = payload.categories[0]
            const otherCategory = payload.categories[1]
            const categories = state.stand.categories.map(category => {
                if (category.id === movedCategory.id) {
                    return movedCategory
                } else if (category.id === otherCategory.id) {
                    return otherCategory
                }
                return category
            })
            return {
                ...state,
                stand: {
                    ...state.stand,
                    categories,
                },
            }

        case DELETE_CATEGORY_SUCCESS:
            {
                const categories = state.stand.categories.filter(cat => {
                    // Remove category and subcategories
                    return cat.id !== payload.categoryId && cat.parent_category_id !== payload.categoryId
                })
                return {
                    ...state,
                    stand: {
                        ...state.stand,
                        categories,
                    },
                }
            }

        case ADD_STAND_REQUEST:
            {
                return {
                    ...state,
                    standsLoading: true,
                    isSaved: false,
                }
            }

        case ADD_STAND_SUCCESS:
            {
                const newStand = {
                    ...payload,
                    isSaved: true,
                }
                const stands = [...state.stands]
                stands.push(newStand)

                return {
                    ...state,
                    stands,
                    standsLoading: false,
                    isSaved: true,
                }
            }

        case EDIT_STAND_REQUEST:
            {
                let stand = state.stand
                if (!stand) {
                    stand = { ...state.stands[0]
                    }
                }
                const standTypeLoading = payload.type_id && payload.type_id !== stand.type_id
                const standAvatarLoading =
                    payload.avatar_id !== undefined && payload.avatar_id !== stand.avatar_id
                const standColorsLoading = payload.colors && payload.colors !== stand.colors
                const titleLoading = payload.name && payload.name !== stand.name
                const descriptionLoading = payload.description && payload.description !== stand.description
                const statusLoading = payload.status === 1 ? payload.id : false

                return {
                    ...state,
                    standIsSaving: payload.id,
                    standTypeLoading,
                    standAvatarLoading,
                    standColorsLoading,
                    titleLoading,
                    descriptionLoading,
                    statusLoading,
                }
            }

        case EDIT_STAND_SUCCESS:
            {
                const stands = state.stands.map(stand => {
                    if (stand.id === payload.id) return { ...stand,
                        ...payload
                    }
                    return stand
                })
                return {
                    ...state,
                    stands,
                    stand: {
                        ...state.stand,
                        ...payload,
                    },
                    standIsSaving: false,
                    logoLoading: false,
                    standTypeLoading: false,
                    standAvatarLoading: false,
                    standColorsLoading: false,
                    titleLoading: false,
                    descriptionLoading: false,
                    statusLoading: false,
                    infosFormDisplayed: false,
                }
            }

        case UPDATE_STAND_ADDRESS:
            return {
                ...state,
                stand: {
                    ...state.stand,
                    addresses: state.stand.addresses.map(address =>
                        address.id !== action.address.id ? address : action.address
                    ),
                },
            }

        case DELETE_STAND_ADDRESS_REQUEST:
            return {
                ...state,
                addressIsDeleting: action.address_id,
            }

        case DELETE_STAND_ADDRESS_SUCCESS:
            return {
                ...state,
                stand: {
                    ...state.stand,
                    addresses: state.stand.addresses.filter(address => address.id !== action.address_id),
                },
                addressIsDeleting: false,
            }

        case ADD_STAND_ADDRESS:
            return {
                ...state,
                stand: {
                    ...state.stand,
                    addresses: [...state.stand.addresses, action.address],
                },
            }

        case EDIT_MASS_STAND_SUCCESS:
            return {
                ...state,
                standsMassEdited: payload,
            }

        case SET_SHIPPING_FEES_SUCCESS:
            let stands = state.stands.splice(0)
            let stand = stands.find(stand => stand.id === action.shippingFee.stand_id)
            stand["shipping_fee"] = action.shippingFee
            return {
                ...state,
                stands,
                stand
            }

        default:
    }

    return state
}