import {
    LOAD_CONVERSATIONS_REQUEST,
    LOAD_CONVERSATIONS_SUCCESS,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    RETRY_SEND_MESSAGE_REQUEST,
    RETRY_SEND_MESSAGE_SUCCESS,
    RETRY_SEND_MESSAGE_ERROR,
    SET_CONVERSATION_AS_SEEN_SUCCESS,
    SET_CONVERSATION_AS_SEEN_ERROR,
    LOAD_AGENTS_SUCCESS,
    SELECT_CONVERSATION_REQUEST,
    SELECT_CONVERSATION_SUCCESS,
    RECEIVE_MESSAGE,
} from './actionTypes'

const defaultState = {
    conversations: undefined,
    conversationsLoading: false,
    selectedConversation: null,
    agents: [],
    stands: [],
    profile: {},
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_CONVERSATIONS_REQUEST:
            {
                return {
                    ...state,
                    conversationsLoading: true,
                }
            }

        case LOAD_CONVERSATIONS_SUCCESS:
            {
                return {
                    ...state,
                    conversations: payload.conversations,
                    conversationsLoading: false,
                }
            }

        case SEND_MESSAGE_REQUEST:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => {
                    return conversation.id === payload.message.conversation_id
                }
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                conversations[currentConversationIndex].messages.push({
                    ...payload.message,
                    agent: payload.profile,
                    status: 'sending',
                    created_at: new Date().toISOString(),
                })
                conversations[currentConversationIndex].updated_at = new Date().toISOString()
                conversations.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1)).reverse()
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case SEND_MESSAGE_SUCCESS:
            {
                let conversations = [...state.conversations]
                const filterConversation = conversation => {
                    return conversation.id === payload.message.conversation_id
                }
                const currentConversationIndex = conversations.findIndex(filterConversation)
                let currentConversation = {
                    ...conversations[currentConversationIndex],
                    ...payload.message.conversation,
                }
                const filterMessage = message => {
                    return message.tempMessageId === payload.tempMessageId
                }
                const currentMessageIndex = currentConversation.messages.findIndex(filterMessage)
                let currentMessage = currentConversation.messages[currentMessageIndex]
                currentMessage.tempMessageId = false
                currentMessage = {
                    ...currentMessage,
                    ...payload.message,
                    status: 'sent',
                }
                currentConversation.messages[currentMessageIndex] = currentMessage
                conversations[currentConversationIndex] = currentConversation
                conversations.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1)).reverse()
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case SEND_MESSAGE_ERROR:
            {
                let conversations = [...state.conversations]
                const filterConversation = conversation => {
                    return conversation.id === payload.message.conversation_id
                }
                const currentConversationIndex = conversations.findIndex(filterConversation)
                let currentConversation = conversations[currentConversationIndex]
                const filterMessage = message => {
                    return message.tempMessageId === payload.tempMessageId
                }
                const currentMessageIndex = currentConversation.messages.findIndex(filterMessage)
                let currentMessage = currentConversation.messages[currentMessageIndex]
                currentMessage.status = 'error'
                currentConversation.messages[currentMessageIndex] = currentMessage
                conversations[currentConversationIndex] = currentConversation
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case RETRY_SEND_MESSAGE_REQUEST:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => {
                    return (
                        conversation.stand_id === payload.stand_id &&
                        conversation.department === payload.department
                    )
                }
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                let currentConversation = conversations[currentConversationIndex]
                const filterMessage = message => {
                    return message.tempMessageId === payload.tempMessageId
                }
                const currentMessageIndex = currentConversation.messages.findIndex(filterMessage)
                currentConversation.messages[currentMessageIndex].status = 'sending'
                conversations[currentConversationIndex] = currentConversation
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case RETRY_SEND_MESSAGE_SUCCESS:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => {
                    return (
                        conversation.stand_id === payload.stand_id &&
                        conversation.department === payload.department
                    )
                }
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                let currentConversation = conversations[currentConversationIndex]
                const filterMessage = message => {
                    return message.tempMessageId === payload.tempMessageId
                }
                const currentMessageIndex = currentConversation.messages.findIndex(filterMessage)
                currentConversation.messages[currentMessageIndex].status = 'sent'
                currentConversation.messages[currentMessageIndex].updated_at = new Date().toISOString()
                conversations[currentConversationIndex] = currentConversation
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case RETRY_SEND_MESSAGE_ERROR:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => {
                    return (
                        conversation.stand_id === payload.stand_id &&
                        conversation.department === payload.department
                    )
                }
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                let currentConversation = conversations[currentConversationIndex]
                const filterMessage = message => {
                    return message.tempMessageId === payload.tempMessageId
                }
                const currentMessageIndex = currentConversation.messages.findIndex(filterMessage)
                currentConversation.messages[currentMessageIndex].status = 'error'
                currentConversation.messages[currentMessageIndex].updated_at = new Date().toISOString()
                conversations[currentConversationIndex] = currentConversation
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case SET_CONVERSATION_AS_SEEN_SUCCESS:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => conversation.id === payload.id
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                conversations[currentConversationIndex] = payload
                return {
                    ...state,
                    conversations: [...conversations],
                }
            }

        case SET_CONVERSATION_AS_SEEN_ERROR:
            {
                return {
                    ...state,
                    conversationError: "Conversation has not been set to seen"
                }
            }

        case LOAD_AGENTS_SUCCESS:
            {
                return {
                    ...state,
                    agents: payload,
                }
            }

        case SELECT_CONVERSATION_REQUEST:
            {
                return {
                    ...state,
                    selectedConversation: null,
                }
            }

        case SELECT_CONVERSATION_SUCCESS:
            {
                return {
                    ...state,
                    selectedConversation: payload,
                }
            }

        case RECEIVE_MESSAGE:
            {
                let conversations = [...state.conversations]
                const matchTheseProps = conversation => {
                    return conversation.id === payload.message.conversation_id
                }
                const currentConversationIndex = conversations.findIndex(matchTheseProps)
                if (currentConversationIndex !== -1) {
                    conversations[currentConversationIndex].messages.push({
                        ...payload.message,
                        created_at: new Date().toISOString(),
                    })
                    conversations[currentConversationIndex].updated_at = new Date().toISOString()
                } else {
                    conversations.push({
                        ...payload.message.conversation,
                        stand: payload.stand,
                        messages: [{
                            ...payload.message,
                        }, ],
                    })
                }
                conversations.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1)).reverse()

                return {
                    ...state,
                    conversations,
                }
            }

        default:
    }

    return state
}