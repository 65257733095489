import {
    httpWithAuth
} from '../helpers/http'

export const update = (address, newValues) =>
    httpWithAuth(
        `/api/stands/${address.stand_id}/address/${address.id}`, { ...address,
            ...newValues
        }, {
            method: 'PUT'
        }
    )

export const create = (stand, address) =>
    httpWithAuth(`/api/stands/${stand.id}/address`, address, {
        method: 'POST'
    })

export const deleteAddress = (stand_id, address_id) =>
    httpWithAuth(`/api/stands/${stand_id}/address/${address_id}`, null, {
        method: 'DELETE'
    })