import {
    LOAD_AGENTS_REQUEST,
    LOAD_AGENTS_SUCCESS,
    EDIT_AGENT_REQUEST,
    EDIT_AGENT_SUCCESS,
    ADD_AGENT_REQUEST,
    ADD_AGENT_SUCCESS,
    ADD_AGENT_FAILURE,
    DELETE_AGENT_REQUEST,
    DELETE_AGENT_SUCCESS,
    HOME_LOADED,
} from './actionTypes'

import {
    findIndex
} from 'lodash'

const defaultState = {
    agents: [],
    agentsLoading: false,
    redirectToHomePage: false,
    newAgentIsSaving: false,
}

export default (state = defaultState, action) => {
    const {
        type,
        payload
    } = action

    switch (type) {
        case LOAD_AGENTS_REQUEST:
            {
                return {
                    ...state,
                    agentsLoading: true,
                }
            }

        case LOAD_AGENTS_SUCCESS:
            {
                payload.forEach(item => {
                    item.stands.forEach(stand => {
                        stand.colors = JSON.parse(stand.colors)
                    })
                })
                return {
                    ...state,
                    agents: payload,
                    agentsLoading: false,
                }
            }

        case ADD_AGENT_REQUEST:
            {
                const agents = [...state.agents]
                agents.push({
                    ...payload,
                    isSaved: false,
                })
                return {
                    ...state,
                    agents,
                    newAgentIsSaving: true,
                    redirectToHomePage: false,
                    errorMessage: null
                }
            }

        case ADD_AGENT_SUCCESS:
            {
                const agents = [...state.agents]
                const agentIndex = findIndex(agents, {
                    id: payload.oldId
                })
                agents[agentIndex] = {
                    ...payload,
                    isSaved: true,
                }
                return {
                    ...state,
                    agents,
                    newAgentIsSaving: false,
                    redirectToHomePage: true,
                }
            }
        case ADD_AGENT_FAILURE:
            {
                const agents = [...state.agents]
                const effectiveAgents = agents.filter((agnt) => agnt.id !== payload.oldId)
                return {
                    ...state,
                    agents: effectiveAgents,
                    newAgentIsSaving: false,
                    redirectToHomePage: false,
                    errorMessage: payload.message,
                }
            }

        case DELETE_AGENT_REQUEST:
            {
                const agents = [...state.agents]
                const agentIndex = findIndex(state.agents, {
                    id: payload
                })
                agents[agentIndex].agentsLoading = true
                return {
                    agentBeingDeleted: payload,
                    ...state,
                    agents,
                    isSaved: false,
                    redirectToHomePage: false,
                }
            }

        case DELETE_AGENT_SUCCESS:
            {
                state.agents.splice(findIndex(state.agents, {
                    id: payload
                }), 1)
                return {
                    ...state,
                    agents: [...state.agents],
                    isSaved: true,
                    redirectToHomePage: true,
                    agentsLoading: false,
                }
            }

        case HOME_LOADED:
            {
                return {
                    ...state,
                    redirectToHomePage: false,
                }
            }

        case EDIT_AGENT_REQUEST:
            {
                return {
                    ...state,
                    agentsLoading: true,
                }
            }

        case EDIT_AGENT_SUCCESS:
            {
                payload.stands.map(stands => {
                    return (stands.colors = JSON.parse(stands.colors))
                })
                const agentIndex = findIndex(state.agents, {
                    id: payload.id
                })
                const newAgent = {
                    ...payload,
                    isSaved: true,
                }
                const agents = [...state.agents]
                agents[agentIndex] = newAgent
                return {
                    ...state,
                    agents,
                    isSaved: true,
                    redirectToHomePage: true,
                    agentsLoading: false,
                }
            }

        default:
    }

    return state
}