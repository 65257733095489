import React, {
    Component
} from 'react';
import {
    ChromePicker
} from 'react-color';
import varLess from '../../../helpers/theme-variables';
import './ColorPicker.css';

export class ColorPicker extends Component {
    state = {
        displayColorPicker: false,
        defaultColor: varLess['@primary-color']
    };

    handleClick = () => {
        this.setState({
            displayColorPicker: !this.state.displayColorPicker
        })
    };

    handleClose = () => {
        this.setState({
            displayColorPicker: false
        })
    };

    handleChange = (color) => {
        this.props.selectColor(color.hex);
        this.setState({
            color: color.hex
        })
    };

    render() {
        const {
            color,
            size
        } = this.props;

        const blocSize = {
            width: size ? size : 40,
            height: size ? size : 40
        }

        return ( <
            div className = "colorpicker" >
            <
            div className = "color-render"
            style = {
                blocSize
            }
            onClick = {
                this.handleClick
            } >
            <
            div className = "color-fill"
            style = {
                {
                    'background': this.state.color || color || this.state.defaultColor
                }
            }
            /> <
            /div> {
                this.state.displayColorPicker ?
                    <
                    div className = "picker" >
                    <
                    div className = "close"
                onClick = {
                    this.handleClose
                }
                /> <
                ChromePicker color = {
                    this.state.color || color || this.state.defaultColor
                }
                onChangeComplete = {
                    this.handleChange
                }
                /> <
                /div> : null
            } <
            /div>
        )
    }
}

export default ColorPicker