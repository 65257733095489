import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Icon, Menu } from 'antd'
import './Menu.css'

const { SubMenu } = Menu

class LeftMenu extends Component {
  state = {}

  render() {
    const { profile, newMessageCount, hasEcommerce } = this.props

    return (
      <React.Fragment>
        {profile && profile.roles && profile.roles[0] === 'EXPOSANT' ? (
          <Menu mode="horizontal" className="app-left-menu">
            <SubMenu
              title={
                <span>
                  <Icon type="setting" />
                  Configuration
                </span>
              }
            >
              <Menu.Item>
                <span className="nav-item">
                  <NavLink activeClassName="active" to="/stands">
                    <Icon type="shop" />
                    <span className="label">Stands</span>
                  </NavLink>
                </span>
              </Menu.Item>
              <Menu.Item>
                <span className="nav-item">
                  <NavLink activeClassName="active" to="/vendeurs">
                    <Icon type="user" />
                    <span className="label">Vendeurs</span>
                  </NavLink>
                </span>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              popupClassName="submenu-item"
              title={
                <span>
                  <Icon type="star" />
                  Evènements
                </span>
              }
            >
              <Menu.Item>
                <span className="nav-item">
                  <NavLink activeClassName="active" to="/chat">
                    <Icon type="message" />
                    <span className="label">Conversations</span>
                    {!!newMessageCount && (
                      <span className="notification-dot">{newMessageCount}</span>
                    )}
                  </NavLink>
                </span>
              </Menu.Item>
              <Menu.Item>
                <span className="nav-item">
                  <NavLink activeClassName="active" to="/leads">
                    <Icon type="team" />
                    <span className="label">Leads</span>
                  </NavLink>
                </span>
              </Menu.Item>
              <Menu.Item>
                <span className="nav-item">
                  <NavLink activeClassName="active" to="/leads/contacts">
                    <Icon type="mail" />
                    <span className="label">Leads Contacts</span>
                    {/* {!!newMessageCount && (
                      <span className="notification-dot">{newMessageCount}</span>
                    )} */}
                  </NavLink>
                </span>
              </Menu.Item>
              {hasEcommerce && (
                <Menu.Item>
                  <span className="nav-item">
                    <NavLink activeClassName="active" to="/commandes/payees">
                      <Icon type="credit-card" />
                      <span className="label">Commandes</span>
                    </NavLink>
                  </span>
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        ) : (
          <Menu mode="horizontal" className="app-left-menu">
            <Menu.Item>
              <NavLink activeClassName="active" to="/chat">
                <Icon type="message" />
                <span className="label">Conversations</span>
                {!!newMessageCount && <span className="notification-dot">{newMessageCount}</span>}
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              {hasEcommerce && (
                <React.Fragment>
                  <NavLink activeClassName="active" to="/commandes/payees">
                    <Icon type="credit-card" />
                    <span className="label">Commandes</span>
                  </NavLink>
                </React.Fragment>
              )}
            </Menu.Item>
          </Menu>
        )}
      </React.Fragment>
    )
  }
}

export default LeftMenu
