import {
    httpWithAuth
} from '../helpers/http'

export const getProducts = standId => httpWithAuth(`/api/stands/${standId}/products`)

export const createProduct = (formDataProduct, progressCallback) =>
    httpWithAuth(`/api/stands/${formDataProduct.get('stand_id')}/products`, formDataProduct, {
        method: 'POST',
        onUploadProgress: progressCallback,
    })

export const updateProduct = formDataProduct =>
    httpWithAuth(
        `/api/stands/${formDataProduct.get('stand_id')}/products/${formDataProduct.get('id')}`,
        formDataProduct, {
            method: 'POST',
        }
    )

export const duplicateProduct = (standId, productId) =>
    httpWithAuth(`/api/stands/${standId}/products/${productId}/duplicate`, null, {
        method: 'GET',
    })


export const destroyProduct = (standId, id) =>
    httpWithAuth(`/api/stands/${standId}/products/${id}`, null, {
        method: 'DELETE'
    })

export const destroyProductFile = (standId, productId, id) =>
    httpWithAuth(`/api/stands/${standId}/products/${productId}/file/${id}`, null, {
        method: 'DELETE',
    })

export const markAsPrincipal = (standId, productId, id) =>
    httpWithAuth(
        `/api/stands/${standId}/products/${productId}/file/${id}`, {
            is_principal: 1
        }, {
            method: 'PUT',
        }
    )