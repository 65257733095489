module.exports = {
    '@primary-color': '#DB0C9C',
    '@font-family': "'gilroy-regular', Arial, Verdana, sans-serif",
    '@layout-header-background': 'fade(#db0e9d,100%)',
    '@heading-color-dark': 'fade(#fff, 100%)',
    '@text-color-dark': '#535467',
    '@text-color-secondary-dark': '#c4c5d6',
    '@error-color': '#f5222d',
    '@smooth-black': '#312E2E',
    '@menu-dark-color': '@text-color-secondary-dark',
    '@menu-dark-bg': '@layout-header-background',
    '@menu-dark-arrow-color': '#fff',
    '@menu-dark-submenu-bg': 'fade(#3a3a3a,85%)',
    '@menu-dark-highlight-color': '#fff',
    '@menu-dark-item-selected-bg': '@primary-color',
    '@icon-light-background': '#f2f2f5',
    '@layout-body-background': '#ffffff',
    '@blue': '#3698FB',
    '@pink': '#DB0E9D',
    '@violet': '#6E41E6',
    '@black': '#535467',
    '@grey': '#C4C5D6',
    '@light-grey': '#F8F8F8',
    '@white': '#FFFFFF',
    '@link-not-active': '#C4C5D6',
    '@link-active': '@primary-color',
    '@primary-btn-text-color': '#ffffff',
    '@grey-background': '#F2F2F2',
    '@card-border-radius': '10px',
    '@card-box-shadow': '0 1px 2px 1px rgba(64, 64, 64, 0.2)',
    '@map-lines-light-color': '#C3D4FF',
    '@light-border-color': '#e4e4e4',
}