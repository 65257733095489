import { connect } from 'react-redux'
import React, { Component } from 'react'
import Base from '../Base'
import { Form } from 'antd'
import { Redirect } from 'react-router-dom'
import AddAgentForm from '../../common/agents/AddAgentForm'
import { addAgent } from '../../../domains/agents/actions'
import './AddAgentPageContainer.css'

const WrappedAddAgentForm = Form.create()(AddAgentForm)

export class AddAgentsPage extends Component {
  render() {
    const { stands, addAgent, redirectToHomePage, newAgentIsSaving, errorMessage } = this.props

    return (
      <Base
        customClass="no-margin"
        breadcrumb={[
          { label: 'Accueil', to: '/' },
          { label: 'Mes vendeurs', to: '/vendeurs' },
          { label: 'Ajouter un nouveau vendeur', to: '/vendeurs/ajouter' },
        ]}
      >
        <WrappedAddAgentForm
          stands={stands}
          addAgent={addAgent}
          newAgentIsSaving={newAgentIsSaving}
          errorMessage={errorMessage}
        />
        {redirectToHomePage && <Redirect to={'/vendeurs'} />}
      </Base>
    )
  }
}

export default connect(
  state => ({
    stands: state.stands.stands,
    redirectToHomePage: state.agents.redirectToHomePage,
    newAgentIsSaving: state.agents.newAgentIsSaving,
    errorMessage: state.agents.errorMessage,
  }),
  {
    addAgent,
  }
)(AddAgentsPage)
