export const LOAD_ACCOUNT_REQUEST = 'ACCOUNTS/LOAD_ACCOUNT_REQUEST'
export const LOAD_ACCOUNT_SUCCESS = 'ACCOUNTS/LOAD_ACCOUNT_SUCCESS'

export const EDIT_ACCOUNT_REQUEST = 'ACCOUNTS/EDIT_ACCOUNT_REQUEST'
export const EDIT_ACCOUNT_SUCCESS = 'ACCOUNTS/EDIT_ACCOUNT_SUCCESS'

export const LOAD_BUSINESS_TYPES_REQUEST = 'ACCOUNTS/LOAD_BUSINESS_TYPES_REQUEST'
export const LOAD_BUSINESS_TYPES_SUCCESS = 'ACCOUNTS/LOAD_BUSINESS_TYPES_SUCCESS'

export const HOME_LOADED = 'ACCOUNTS/HOME_LOADED'

export const LOGIN_LOADED = 'ACCOUNTS/LOGIN_LOADED'

export const CONNECT_STRIPE_ACCOUNT = 'ACCOUNTS/CONNECT_STRIPE_ACCOUNT'

export const SUBSCRIPTIONS_LOADED = 'ACCOUNTS/SUBSCRIPTIONS_LOADED'
export const INTENT_LOADED = 'ACCOUNTS/INTENT_LOADED'
export const CARD_CHANGED = 'ACCOUNTS/CARD_CHANGED'
export const CARD_LOADED = 'ACCOUNTS/CARD_LOADED'