import React, { Component } from "react"
import { Input, Button, Form, Icon } from "antd"

const FormItem = Form.Item

const ResetForm = Form.create()(
  class extends Component {
    state = {}

    compareToFirstPassword = (rule, value, callback) => {
      const form = this.props.form
      if (value && value !== form.getFieldValue("password")) {
        callback("Les deux mots de passes ne correspondent pas")
      } else {
        callback()
      }
    }

    validateToNextPassword = (rule, value, callback) => {
      const form = this.props.form
      if (value && this.state.confirmDirty) {
        form.validateFields(["confirm"], { force: true })
      }
      callback()
    }

    handleConfirmBlur = e => {
      const value = e.target.value
      this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    render() {
      const { handleSubmit, form, isSaved, message } = this.props

      const { getFieldDecorator } = form

      return (
        <Form layout="inline" onSubmit={handleSubmit}>
          <FormItem label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Veuillez saisir cette information",
                },
              ],
              initialValue: localStorage.getItem("email"),
            })(<Input type="email" placeholder="" maxLength="50" />)}
          </FormItem>
          <FormItem
            label={
              <React.Fragment>
                Mot de passe{" "}
                <span className="small-text">(minimum 8 caractères)</span>
              </React.Fragment>
            }
          >
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Veuillez saisir cette information",
                },
                {
                  min: 8,
                  message: "Le mot de passe doit faire au moins 8 caractères",
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input type="password" placeholder="" maxLength="50" />)}
          </FormItem>
          <FormItem label="Confirmez votre mot de passe">
            {getFieldDecorator("password_confirmation", {
              rules: [
                {
                  required: true,
                  message: "Veuillez confirmer votre mot de passe",
                },
                {
                  min: 8,
                  message: "Le mot de passe doit faire au moins 8 caractères",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input
                type="password"
                onBlur={this.handleConfirmBlur}
                maxLength="50"
              />
            )}
          </FormItem>
          <FormItem className="reinit">
            <Button type="primary" htmlType="submit">
              <div className="flex-center">
                <span>Réinitialiser</span>
                {!isSaved && (
                  <div className="loading">
                    <Icon type="loading" />
                  </div>
                )}
              </div>
            </Button>
          </FormItem>
          <FormItem className="message">
            {message && <div>{message}</div>}
          </FormItem>
        </Form>
      )
    }
  }
)

export default ResetForm
