import {
    http
} from '../../helpers/http'
import {
    LOAD_MEDIAS_REQUEST,
    LOAD_MEDIAS_SUCCESS,
    UPLOAD_MEDIA_REQUEST,
    UPLOAD_MEDIA_SUCCESS,
    DESTROY_REQUEST,
    DESTROY_SUCCESS,
    LOAD_DOCUMENTS_SUCCESS,
    ADD_DOCUMENT_SUCCESS,
    EDIT_DOCUMENT_SUCCESS,
} from './actionTypes'
import {
    getProfile
} from '../../helpers/local-storage'
import {
    getDocuments,
    createDocument,
    updateDocument,
    destroy,
} from '../../api/medias'
import {
    message
} from 'antd'

export const loadMedias = () => {
    return dispatch => {
        const profile = getProfile()
        if (profile) {
            dispatch({
                type: LOAD_MEDIAS_REQUEST,
            })

            const options = {
                method: 'get',
                url: '/api/medias',
                headers: {
                    Authorization: `${profile.token_type} ${profile.access_token}`,
                },
            }

            http(options).then(resultats => {
                dispatch({
                    type: LOAD_MEDIAS_SUCCESS,
                    payload: resultats,
                })
            })
        }
    }
}

export const uploadMedia = (file, account_id, product_id) => {
    return dispatch => {
        const profile = getProfile()
        if (profile) {
            const newFile = file.file
            dispatch({
                type: UPLOAD_MEDIA_REQUEST,
                payload: {
                    file,
                    product_id,
                },
            })

            const formData = new FormData()
            formData.append('file', newFile)
            formData.append('account_id', account_id)
            formData.append('stand_id', 0)

            const options = {
                method: 'post',
                url: '/api/medias',
                payload: formData,
                headers: {
                    Authorization: `${profile.token_type} ${profile.access_token}`,
                },
            }

            http(options).then(results => {
                dispatch({
                    type: UPLOAD_MEDIA_SUCCESS,
                    payload: {
                        ...results,
                        product_id,
                    },
                })
            })
        }
    }
}

export const destroyMedia = mediaId => async dispatch => {
    try {
        dispatch({
            type: DESTROY_REQUEST,
            payload: {
                mediaId
            }
        })
        await destroy(mediaId)
        dispatch({
            type: DESTROY_SUCCESS,
            payload: {
                mediaId
            }
        })
    } catch (e) {
        message.error('Impossible de supprimer le média. Veuillez réessayer plus tard.')
    }
}

export const loadDocuments = standId => async dispatch => {
    try {
        const results = await getDocuments(standId)
        dispatch({
            type: LOAD_DOCUMENTS_SUCCESS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger la liste des documents. Veuillez réessayer plus tard.')
    }
}

export const addDocument = (payload, progressCallback) => async dispatch => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            formData.append(k, v)
        })
        const result = await createDocument(formData, progressCallback)
        dispatch({
            type: ADD_DOCUMENT_SUCCESS,
            payload: result
        })
    } catch (e) {
        message.error('Impossible de créer le document. Veuillez réessayer plus tard.')
    }
}

export const editDocument = payload => async dispatch => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            formData.append(k, v)
        })
        const result = await updateDocument(formData)
        dispatch({
            type: EDIT_DOCUMENT_SUCCESS,
            payload: result
        })
    } catch (e) {
        message.error('Impossible de mettre à jour le document. Veuillez réessayer plus tard.')
    }
}