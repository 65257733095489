import {
    http
} from '../../helpers/http'
import {
    LOAD_AGENTS_REQUEST,
    LOAD_AGENTS_SUCCESS,
    EDIT_AGENT_REQUEST,
    EDIT_AGENT_SUCCESS,
    ADD_AGENT_REQUEST,
    ADD_AGENT_SUCCESS,
    ADD_AGENT_FAILURE,
    DELETE_AGENT_REQUEST,
    DELETE_AGENT_SUCCESS,
    HOME_LOADED,
} from './actionTypes'
import uuid from 'uuid'
import {
    updateAgent,
    createAgent
} from '../../api/agents'
import {
    getProfile
} from '../../helpers/local-storage'
import {
    message
} from 'antd'

export const loadAgents = () => dispatch => {
    const profile = getProfile()
    if (profile) {
        dispatch({
            type: LOAD_AGENTS_REQUEST,
            payload: {},
        })

        const options = {
            method: 'get',
            url: '/api/agents',
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        http(options).then(results => {
            dispatch({
                type: LOAD_AGENTS_SUCCESS,
                payload: results,
            })
        })
    }
}

export const deleteAgent = agent => dispatch => {
    const profile = getProfile()
    if (profile) {
        dispatch({
            type: DELETE_AGENT_REQUEST,
            payload: agent.id,
        })

        const options = {
            method: 'delete',
            url: `/api/agents/${agent.id}`,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        http(options).then(results => {
            dispatch({
                type: DELETE_AGENT_SUCCESS,
                payload: agent.id,
            })
        })
    }
}

export const addAgent = datas => async dispatch => {
    const profile = getProfile()
    const idTemporary = uuid.v4()
    if (profile) {
        dispatch({
            type: ADD_AGENT_REQUEST,
            payload: { ...datas,
                id: idTemporary
            },
        })
    }
    try {
        const formData = new FormData()
        Object.entries(datas).forEach(([k, v]) => {
            if (k === 'stands') {
                formData.append('stands', JSON.stringify(datas.stands))
            }
            if (v !== undefined && k !== 'stands') {
                formData.append(k, v)
            }
        })
        const results = await createAgent(formData)
        dispatch({
            type: ADD_AGENT_SUCCESS,
            payload: {
                ...results,
                oldId: idTemporary,
            },
        })
    } catch (e) {

        if (e.response && e.response.data && e.response.data.error) {
            message.error(e.response.data.error)
        } else {
            message.error("Impossible de créer l'agent. Veuillez réessayer plus tard.")
        }

        dispatch({
            type: ADD_AGENT_FAILURE,
            payload: {
                oldId: idTemporary,
                message: e.response && e.response.data && e.response.data.error ? e.response.data.error : "Impossible de créer l'agent. Veuillez réessayer plus tard."
            }
        })
    }
}

export const homeLoaded = () => dispatch => {
    dispatch({
        type: HOME_LOADED
    })
}

export const editAgent = payload => async dispatch => {
    dispatch({
        type: EDIT_AGENT_REQUEST
    })
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            if (k === 'stands') {
                formData.append('stands', JSON.stringify(payload.stands))
            }
            if (v !== undefined && k !== 'stands') {
                formData.append(k, v)
            }
        })
        const result = await updateAgent(formData)
        result.roles = payload.roles
        dispatch({
            type: EDIT_AGENT_SUCCESS,
            payload: result
        })
    } catch (e) {
        message.error(
            'Impossible de modifier les informations du vendeur. Veuillez réessayer plus tard.'
        )
    }
}