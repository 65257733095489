import {
    apiWithAuth
} from '../helpers/http'

export const getCarts = () => apiWithAuth('carts')

export const getPaidCarts = () => apiWithAuth('carts/paid')

export const updateCart = (cartId, data) =>
    apiWithAuth(`carts/${cartId}`, {
        method: 'PUT',
        data
    })