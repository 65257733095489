import { connect } from 'react-redux'
import React from 'react'
import { Form } from 'antd'
import { Redirect } from 'react-router-dom'
import { find } from 'lodash'
import Base from '../Base'
import EditAgentForm from '../../common/agents/EditAgentForm'
import { editAgent } from '../../../domains/agents/actions'

const WrappedEditAgentForm = Form.create()(EditAgentForm)

const EditAgentPage = ({ match, agents, stands, editAgent, redirectToHomePage, agentsLoading }) => {
  const agentId = JSON.parse(match.params.id)
  const agent = find(agents, { id: agentId })

  return (
    <Base
      breadcrumb={[
        { label: 'Accueil', to: '/' },
        { label: 'Mes vendeurs', to: '/vendeurs' },
        agent ? { label: `Editer le vendeur '${agent.name}'`, to: `/vendeurs/${agent.id}` } : {},
      ]}
    >
      <WrappedEditAgentForm
        agent={agent}
        stands={stands}
        editAgent={editAgent}
        agentsLoading={agentsLoading}
      />
      {redirectToHomePage && <Redirect to={'/vendeurs'} />}
    </Base>
  )
}

export default connect(
  state => ({
    stands: state.stands.stands,
    agents: state.agents.agents,
    redirectToHomePage: state.agents.redirectToHomePage,
    agentsLoading: state.agents.agentsLoading,
  }),
  {
    editAgent,
  }
)(EditAgentPage)
