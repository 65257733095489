import React from 'react'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'
import './AddAgentSection.css'

const AddAgentSection = () => (
  <div className="add-agent-section">
    <NavLink to="/vendeurs/ajouter">
      <Button type="primary">Ajouter un vendeur</Button>
    </NavLink>
  </div>
)

export default AddAgentSection
