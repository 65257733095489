import { connect } from 'react-redux'
import React, { Component } from 'react'
import Base from '../Base'
import { homeLoaded, deleteAgent } from '../../../domains/agents/actions'
import Agents from '../../common/agents/Agents'

import './AgentsPageContainer.css'

class AgentsPage extends Component {
  componentDidMount() {
    this.props.homeLoaded()
  }

  render() {
    const { agents, deleteAgent, agentsLoading } = this.props
    return (
      <Base
        customClass="no-margin"
        breadcrumb={[{ label: 'Accueil', to: '/' }, { label: 'Mes vendeurs', to: '/vendeurs' }]}
      >
        <Agents agents={agents} deleteAgent={deleteAgent} agentsLoading={agentsLoading} />
      </Base>
    )
  }
}

export default connect(
  state => ({
    agents: state.agents.agents,
    agentsLoading: state.agents.agentsLoading,
  }),
  {
    homeLoaded,
    deleteAgent,
  }
)(AgentsPage)
