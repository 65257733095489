import {
    http
} from '../../helpers/http'
import history from '../../helpers/history'
import {
    LOGIN_REQUEST,
    LOAD_PROFILE_REQUEST,
    LOAD_PROFILE_SUCCESS,
    LOAD_PROFILE_ABORTED,
    PROFILE_EDITED,
    LOGIN_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_ERROR,
    PROFILE_HOME_LOADED,
    TERMS_SERVICE_ACCEPTED,
    INFOS_REQUEST_SEND
} from './actionTypes'
import {
    updateProfile,
    termsServiceApproved
} from '../../api/profile'
import {
    getProfile
} from '../../helpers/local-storage'
import {
    message
} from 'antd'
import {
    getCurrentDefaultSource,
    infosRequest
} from '../../api/subscription'

export const loadProfile = () => {
    const profile = getProfile()

    if (profile) {
        const options = {
            method: 'get',
            url: `/api/auth/user`,
            headers: {
                Authorization: `${profile.token_type} ${profile.access_token}`,
            },
        }

        return (dispatch) => {
            dispatch({
                type: LOAD_PROFILE_REQUEST,
                payload: {},
            })

            http(options)
                .then((resultats) => {
                    dispatch({
                        type: LOAD_PROFILE_SUCCESS,
                        payload: resultats,
                    })

                    if (window.location.pathname === '/' || window.location.pathname === '/login') {
                        history.push('/')
                    }
                })
                .catch((err) => {
                    dispatch(logout())

                    dispatch({
                        type: LOGIN_ERROR,
                        payload: err.error || err || 'Une erreur est survenue, veuillez contacter mafoire.com',
                    })
                })
        }
    }

    return (dispatch) => {
        dispatch({
            type: LOAD_PROFILE_ABORTED,
            payload: {},
        })
    }
}

export const login = (data) => {
    return async (dispatch) => {
        dispatch({
            type: LOGIN_REQUEST,
            payload: {},
        })

        const options = {
            method: 'post',
            url: `/api/auth/login`,
            payload: {
                email: data.email,
                password: data.password,
                remember_me: data.remember_me || false,
            },
        }

        try {
            http(options)
                .then((resultats) => {
                    let values = {
                        access_token: resultats.access_token,
                        token_type: resultats.token_type,
                        expires_in: resultats.expires_in,
                    }
                    localStorage.setItem('profile', JSON.stringify(values))

                    const options = {
                        method: 'get',
                        url: `/api/auth/user`,
                        headers: {
                            Authorization: `${resultats.token_type} ${resultats.access_token}`,
                        },
                    }

                    http(options).then((user) => {
                        getCurrentDefaultSource()
                            .then((card) => {
                                dispatch({
                                    type: LOAD_PROFILE_SUCCESS,
                                    payload: user,
                                })
                                values = {
                                    ...values,
                                    hasPayementMethod: !!card,
                                }
                                localStorage.setItem('profile', JSON.stringify(values))
                                window.location.reload()
                            })
                            .catch((err) => {
                                dispatch({
                                    type: LOGIN_ERROR,
                                    payload: 'Une erreur est survenue lors de la récupération de vos informations de paiements, veuillez contacter mafoire.com.',
                                })
                                localStorage.setItem('profile', null)
                            })
                    })
                })
                .catch((err) => {

                    dispatch({
                        type: LOGIN_ERROR,
                        payload:
                            (err && err.response && err.response.status === 401) ?
                            'Identifiants incorrects, veuillez rééssayer ou cliquez sur Mot de  passe oublié' :
                            'Une erreur est survenue, veuillez contacter mafoire.com',
                    })
                })
        } catch (err) {
            dispatch({
                type: LOGIN_ERROR,
                payload: err,
            })

            history.push('/login')
        }
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch({
            type: LOGOUT_REQUEST,
            payload: {},
        })

        dispatch({
            type: LOGOUT_SUCCESS,
            payload: {},
        })

        localStorage.removeItem('profile')

        history.push('/login')
        window.location.reload()
    }
}

export const subscribe = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: SUBSCRIPTION_REQUEST,
            payload: payload,
        })

        const optionsPostNewVisitor = {
            method: 'post',
            url: `/api/visitors`,
            payload: payload,
        }

        try {
            const newVisitor = await http(optionsPostNewVisitor)

            const optionsLogin = {
                method: 'post',
                url: `/api/auth/login`,
                payload: {
                    email: payload.email,
                    password: payload.password,
                },
            }

            const visitorConnected = await http(optionsLogin)

            const values = {
                access_token: visitorConnected.access_token,
                token_type: visitorConnected.token_type,
                expires_in: visitorConnected.expires_in,
            }

            localStorage.setItem('profile', JSON.stringify(values))

            const optionsGetInfos = {
                method: 'get',
                url: `/api/auth/user`,
                headers: {
                    Authorization: `${visitorConnected.token_type} ${visitorConnected.access_token}`,
                },
            }

            http(optionsGetInfos).then((user) => {
                dispatch({
                    type: LOAD_PROFILE_SUCCESS,
                    payload: user,
                })
                dispatch({
                    type: SUBSCRIPTION_SUCCESS,
                    payload: newVisitor,
                })
                window.location.reload()
            })
        } catch (err) {
            dispatch({
                type: SUBSCRIPTION_ERROR,
                payload: err,
            })

            history.push('/login')
        }
    }
}

export const editProfile = (payload) => async (dispatch) => {
    try {
        const formData = new FormData()
        Object.entries(payload).forEach(([k, v]) => {
            if (v !== undefined) {
                formData.append(k, v)
            }
        })
        const result = await updateProfile(formData)
        result.roles = payload.roles
        dispatch({
            type: PROFILE_EDITED,
            payload: result
        })
    } catch (e) {
        message.error(
            'Impossible de modifier les informations du profil. Veuillez réessayer plus tard.'
        )
    }
}

export const profileHomeLoaded = () => (dispatch) => {
    dispatch({
        type: PROFILE_HOME_LOADED,
    })
}

export const termsServiceAccepted = () => async (dispatch) => {
    try {
        await termsServiceApproved()
        dispatch({
            type: TERMS_SERVICE_ACCEPTED,
        })
    } catch (e) {
        message.error(
            'Une erreur est survenue, veuillez contacter mafoire.com.'
        )
    }
}

export const infosRequestAction = data => async (dispatch) => {
    try {
        await infosRequest(data)
        dispatch({
            type: INFOS_REQUEST_SEND,
        })
    } catch (e) {
        message.error(
            'Une erreur est survenue, veuillez contacter mafoire.com.'
        )
    }
}