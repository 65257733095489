import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { Loader } from '@mafoire/components'
import { generateAndStoreStripeURLState } from '../../../helpers/stripe'
import './EditAccountBilling.css'

const EditAccountBilling = ({ account }) => {
  // Generate and store the stripe URL State only once for each EditAccountBilling component
  const [stripeURLState] = useState(generateAndStoreStripeURLState())

  return (
    <div className="account-billing-form-page center-container">
      <div className="account-billing-form-wrapper">
        <h3>Configuration de votre facturation e-commerce</h3>
        {!account ? (
          <Loader />
        ) : account.stripe_user_id ? (
          <React.Fragment>
            <p>
              Vous avez lié votre compte{' '}
              <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                Stripe
              </a>{' '}
              à votre compte mafoire.com. Editez vos informations de facturation sur votre tableau
              de bord de Stripe.
            </p>
            <Button>
              <a
                href="https://dashboard.stripe.com/dashboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accéder au tableau de bord de Stripe
              </a>
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Ajoutez ou créez un compte{' '}
              <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                Stripe
              </a>{' '}
              afin de le lier à votre compte mafoire.com
            </p>
            <Button type="primary" className="button-stripe-connect">
              <a
                href={`https://connect.stripe.com/oauth/authorize?state=${stripeURLState}&response_type=code&client_id=${window.process.env.APP_STRIPE_CLIENT_ID}&scope=read_write`}
              >
                Connectez-vous à Stripe
              </a>
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withRouter(EditAccountBilling)
