import React from 'react'
import { Icon } from 'antd'
import style1 from '../../../assets/images/chair_style_1.png'
import style4 from '../../../assets/images/table_style_4.png'
import chairProduct from '../../../assets/images/garden_chair.png'
import './CatalogueModal.css'

const CatalogueModal = ({ stand, showModal }) => {
  const standColor = stand ? JSON.parse(stand.colors)[0] : '#000'
  const standColor2 = stand ? JSON.parse(stand.colors)[1] : '#000'

  const getContrastYIQ = hexColor => {
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 180 ? '#535467' : 'white'
  }
  return (
    <div>
      <div className="modal-header">
        <span style={{ color: 'white', fontSize: '13.5px' }}>Votre catalogue de produits</span>
        <div className="close-button-container" onClick={showModal}>
          <div className="close-button"></div>
        </div>
      </div>
      <div className="slideshow-container">
        <div className="help-modal-slide fade">
          <p>
            Bienvenue dans l’espace de gestion de votre catalogue de produits. Grâce à votre
            catalogue, vous pouvez organiser et personnaliser vos produits afin de les rendre
            accessibles facilement aux visiteurs tout en conservant votre charte.
          </p>
          <div
            className="settings-navbar-container"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              marginBottom: '30px',
            }}
          >
            <div
              className="settings-navbar"
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: standColor,
                color: stand && getContrastYIQ(standColor.substring(1)),
                fontSize: '1.2em',
              }}
            >
              <span>Catalogue</span>
              <div
                style={{
                  height: '5px',
                  width: '60%',
                  backgroundColor: standColor,
                  borderBottomColor: standColor2,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '5px',
                  position: 'relative',
                  top: '31%',
                }}
              ></div>
            </div>
          </div>
          <p>
            Lorsque vous faites défiler la page vers le bas, vous remarquez une page blanche avec un
            bandeau gris sur votre gauche. Dans ce bandeau, vous allez créer l'arborescence des
            catégories de votre catalogue.
          </p>
          <p>Nous verrons successivement comment créer:</p>
          <ul>
            <li>Les catégories de votre catalogue</li>
            <li>Les fiches produit</li>
          </ul>
        </div>

        <div className="help-modal-slide fade">
          <h3>Les catégories du catalogue</h3>
          <p>
            Avant de pouvoir créer vos fiches produit, vous devez ajouter les catégories de votre
            catalogue en cliquant sur "Ajouter une catégorie"
          </p>
          <div className="modal-category-container">
            <div className="add-category-button">
              <Icon type="plus" />
              <span>Ajouter une catégorie</span>
            </div>
          </div>
          <p>Vous pouvez voir ci-dessous 3 exemples de catégories.</p>
          <div className="modal-category-container">
            <div className="categories">
              <div className="category">
                <span>Peinture</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
              <div className="category">
                <span>Plomberie</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
              <div className="category">
                <span>Electricité</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
            </div>
          </div>
          <p>
            Vous pouvez modifier ou supprimer une catégorie en cliquant sur les icônes du crayon ou
            de la corbeille.
          </p>
          <p>
            N.B. Si vous supprimer une catégorie, cela supprimera aussi tous les produits de cette
            catégorie.
          </p>
        </div>

        <div className="help-modal-slide fade">
          <h3>Les sous-catégories</h3>
          <p>
            Cliquez sur la catégorie que vous souhaitez classer, puis sur "Ajouter une
            sous-catégorie".
          </p>
          <p>
            Vous pouvez ajouter deux niveaux de sous-catégories comme nous le montre l’illustration
            ci-dessous.
          </p>
          <div className="modal-category-container">
            <div className="categories sub-categories">
              <div className="category">
                <span>Peinture</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
              <div className="category">
                <span>Terrasse et jardin</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
              <div className="category sub">
                <span>Salons de jardin</span>
                <div className="category-labels-icons">
                  <Icon type="edit" />
                  <Icon type="delete" />
                  <Icon type="down" />
                </div>
              </div>
              <div className="sub-categories-container">
                <div className="sub-category">
                  <span>Tables de jardin</span>
                  <div className="category-labels-icons">
                    <Icon type="edit" />
                    <Icon type="delete" />
                  </div>
                </div>
                <div className="sub-category">
                  <span>Chaises de jardin</span>
                  <div className="category-labels-icons">
                    <Icon type="edit" />
                    <Icon type="delete" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Les retraits à droite et les tirets matérialisent la hierarchie des sous-catégories.
          </p>
          <p>
            En cliquant sur une catégorie ou sous-catégorie, une flêche rose apparait. Celle-ci vous
            permet de déplacer la catégorie.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Les fiches produit</h3>
          <p>
            Dès que vous avez créé une catégorie, vous pouvez ajouter votre fiche produit. Cliquez
            sur la catégorie souhaitée, puis sur « Ajouter un nouveau produit » situé sur la droite
            de l’écran.
          </p>
          <div className="add-productsheet-button-container">
            <div className="add-productsheet-button">
              <span>Ajouter un nouveau produit</span>
            </div>
          </div>
          <p>Le formulaire qui apparait affiche 3 parties :</p>
          <ul>
            <li>Le style de la fiche produit</li>
            <li>Les fichiers de la fiche produit</li>
            <li>Les informations de votre produit</li>
          </ul>
          <p>
            N.B. : Après avoir créé votre fiche produit, vous pouvez supprimer ou modifier cette
            fiche (style, fichiers ou informations) grâce au même formulaire qu'à la création.
          </p>
          <p>
            Pour modifier ou supprimer une fiche produit, cliquez sur la catégorie dans laquelle se
            trouve votre produit. Ensuite, sur la carte du produit, cliquez sur "Voir le produit".
            Les boutons "Supprimer" et "Modifier" se situent en bas à gauche de votre fiche produit.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Le style de votre fiche produit</h3>
          <p>
            Sur la gauche du formulaire se trouve la section des styles. Vous pouvez choisir entre
            quatre styles différents.
          </p>
          <div className="catalogue-pictures-container">
            <img src={style1} alt="" />
            <img src={style4} alt="" />
          </div>
          <p>
            Ces photos correspondant à deux styles différents montrent notamment que les éléments ne
            sont pas positionnées aux mêmes endroits.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Les fichiers de votre fiche produit</h3>
          <p>
            Au centre du formulaire se trouve la section des fichiers. Vous pouvez ajouter des
            photos, des vidéos et des fichiers PDF.
          </p>
          <div className="catalogue-files-container">
            <div className="add-file-button">
              <Icon type="plus" />
              Télécharger un fichier
            </div>
          </div>
          <p>
            Notez que, une fois votre fiche créée, vous pouvez changer la photo de couverture en
            cliquant sur le bouton, situé en bas de chaque photo :
          </p>
          <div className="default-button-container">
            <div className="default-button">Définir comme photo de couverture</div>
          </div>
          <p>
            La photo de couverture correspond à la photo affichée par défaut sur les cartes des
            produits, comme représentée ci-dessous.
          </p>
          <div className="modal-product-card-container">
            <img className="modal-product-card" src={chairProduct} alt="" />
          </div>
        </div>
        <div className="help-modal-slide fade">
          <h3>Les informations de votre produit</h3>
          <h4>Sans pack ecommerce</h4>
          <p>Sur la droite du formulaire se trouve la section des informations.</p>
          <p>
            Trois informations sont toujours obligatoires: le nom, la référence et la description du
            produit. Les informations obligatoires sont précédées d'un astérisque rose, comme
            représenté ci-dessous:
          </p>
          <div className="product-name-container">
            <span className="product-name">Nom du produit :</span>
          </div>
          <p>Les autres informations sont facultatives.</p>
          <p>
            Sans pack e-commerce, même si vous renseignez les champs "Ancien prix" et/ou "Frais de
            port", seul le prix sera affiché, et précédé de la mention "À partir de"
          </p>
          <p>
            Notez également que le prix est affiché hors taxes pour l'exposant et toutes taxes pour
            le visiteur.
          </p>
        </div>
        <div className="help-modal-slide fade">
          <h3>Les informations de votre produit</h3>
          <h4>Avec pack e-commerce</h4>
          <p>
            Si vous avez souscrit un pack ecommerce, vous pouvez choisir de vendre votre produit en
            ligne. Pour vendre en ligne, il suffit de cocher la case située en haut à droite du
            formulaire :
          </p>
          <div className="modal-sellable-container">
            <span className="sellable-label">Vendre le produit en ligne</span>
            <div className="check-container">
              <Icon type="check" style={{ color: 'white' }} />
            </div>
          </div>
          <p>
            Si vous cochez la case "Vendre le produit en ligne", la marque, la mini description, la
            TVA ainsi que les frais de port deviennent obligatoires.
          </p>
          <p>
            Vous pouvez également appliquer une promotion à votre produit. En renseignant le champ
            "Ancien prix", la fiche produit affichera le nouveau prix, l'ancien prix et la
            différence entre les deux en pourcentage.
          </p>
          <p>
            Si vous ne cochez pas la case "Vendre le produit en ligne", même si vous renseignez les
            champs "Ancien prix" et/ou "Frais de port", seul le prix sera affiché, et précédé de la
            mention "À partir de". Vous ne pouvez pas non plus appliquer de promotion.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CatalogueModal
