import {
    LOAD_VISITOR,
    LOAD_VISITORS,
    LOAD_LEAD_CONTACTS,
    CHECK_LEADCONTACT_REQUEST,
    CHECK_LEADCONTACT_SUCCESS,
} from './actionTypes'
import {
    getVisitors,
    getVisitor,
    getTrackVisitorAgentView,
    getLeadsContacts,
} from '../../api/visitors'
import {
    getProfile
} from '../../helpers/local-storage'
import {
    message
} from 'antd'
import {
    http
} from '../../helpers/http'

export const loadVisitors = () => async (dispatch) => {
    try {
        const results = await getVisitors()
        dispatch({
            type: LOAD_VISITORS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger la liste de leads. Veuillez réessayer plus tard.')
    }
}

export const loadVisitor = (id) => async (dispatch) => {
    try {
        const results = await getVisitor(id)
        dispatch({
            type: LOAD_VISITOR,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger le lead. Veuillez réessayer plus tard.')
    }
}

export const loadVisitorByStandId = (id, standId) => async (dispatch) => {
    try {
        const results = await getTrackVisitorAgentView(id, standId)
        dispatch({
            type: LOAD_VISITOR,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger le lead. Veuillez réessayer plus tard.')
    }
}

export const loadLeadsContacts = () => async (dispatch) => {
    try {
        const results = await getLeadsContacts()
        dispatch({
            type: LOAD_LEAD_CONTACTS,
            payload: results
        })
    } catch (e) {
        message.error('Impossible de charger les messages. Veuillez réessayer plus tard.')
    }
}

export const checkLeadContact = (value, leadContact) => {
    return (dispatch) => {
        const profile = getProfile()
        if (profile) {
            dispatch({
                type: CHECK_LEADCONTACT_REQUEST,
                payload: {
                    value,
                    leadContact
                },
            })

            const options = {
                method: 'put',
                url: `/api/leads/contact/${leadContact.id}`,
                payload: {
                    checked: value ? 1 : 0
                },
                headers: {
                    Authorization: `${profile.token_type} ${profile.access_token}`,
                },
            }

            http(options).then((resultats) => {
                dispatch({
                    type: CHECK_LEADCONTACT_SUCCESS,
                    payload: resultats,
                })
            })
        }
    }
}