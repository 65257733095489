import React from 'react'
import { connect } from 'react-redux'
import Base from '../Base'
import EditAccountBilling from '../../common/account/EditAccountBilling'

const EditAccountBillingPage = ({ account, connectStripeAccount }) => (
  <Base
    breadcrumb={[
      { label: 'Accueil', to: '/' },
      { label: 'Mon compte', to: '/mon-compte' },
      {
        label: 'Configuration de votre facturation e-commerce',
      },
    ]}
  >
    <EditAccountBilling account={account} />
  </Base>
)

export default connect(
  state => ({
    account: state.account.account,
  })
)(EditAccountBillingPage)
