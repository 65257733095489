import {
    httpWithAuth,
    apiWithAuth
} from '../helpers/http'

export const getConversations = () => httpWithAuth(`/api/conversations`)

export const sendMessage = payload =>
    httpWithAuth(`/api/stands/${payload.stand_id}/messages`, payload, {
        method: 'POST'
    })

export const setConversationHasSeenAction = conversation_id =>
    apiWithAuth(`conversations/${conversation_id}/seen`, {
        method: 'PUT'
    })