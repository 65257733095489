import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { ProductContainer } from '@mafoire/components'
import { loadStand } from '../../../domains/stands/actions'
import { loadProducts } from '../../../domains/products/actions'
import { loadThemes } from '../../../domains/themes/actions'
import Base from '../Base'
import './ProductPage.css'

const ProductPage = ({ 
  stand,
  match,
  products,
  loadProducts,
  loadStand,
  loadThemes,
}) => {
  useEffect(() => {
    match.params.standId && loadProducts(match.params.standId)
    match.params.standId && loadStand(match.params.standId)
    loadThemes()
  }, [match.params.standId, match.params.department])

  const currentProduct = match && match.params && products && products.find(product => product.id === JSON.parse(match.params.productId))

  const alreadyInCart = false 

  return (
    <Base customClass="no-margin">
      <div className="center-container product">
        {currentProduct && stand && (
          <ProductContainer
            exposantPreview={true}
            department="06"
            asOwner={false}
            product={currentProduct && currentProduct}
            alreadyInCart={alreadyInCart}
            stand={stand && stand}
            match={match && match}
          />
        )}
      </div>
    </Base>
  )
}

const mapStateToProps = state => ({
  products: state.products.products,
  stand: state.stands.stand,
  themes: state.themes.themes,
  activeDepartments: state.stands.activeDepartments
})

const mapDispatchToProps = {
  loadProducts,
  loadStand,
  loadThemes,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
