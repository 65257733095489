import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Divider, Icon, Tooltip, Row, Col } from 'antd'
import { Loader } from '@mafoire/components'
import { formatDate } from '../../../helpers/functions'
import { generateAndStoreStripeURLState } from '../../../helpers/stripe'
import './Account.css'

const subscriptionStatuses = {
  intialized: 'En cours de création',
  'launch_offer:created': "La création de la facture pour l'offre d'ouverture est en cours",
  'launch_offer:finalized':
    "La facture pour l'offre d'ouverture a été envoyée, en attente de règlement",
  'launch_offer:paid': "La facture pour l'offre d'ouverture a bien été réglée",
  'launch_offer:failed': "Le règlement de la facture pour l'offre d'ouverture a échoué",
  'renewal:sent': 'La facture de renouvellement a été envoyée, en attente de règlement',
  'renewal:failed': 'La facture de renouvellement a échoué',
  'renewal:canceled': 'Le renouvellement a été annulé',
}

const isCancellable = ({ subscribed_until, status }) =>
  !!subscribed_until && new Date(subscribed_until) > new Date() && status !== 'renewal:canceled'

const Account = ({ account, profile, subscriptions, cancelSubscription }) => {
  const [stripeURLState] = useState(generateAndStoreStripeURLState())

  return (
    <div className="account-page center-container">
      {!account ? (
        <Loader fullPage />
      ) : (
        <div className="setting-cards-wrapper">
          <div className="setting-card connection">
            <h4>Connexion de l'utilisateur et sécurité</h4>
            <Link className="button-edit" to={`/mon-compte/connexion/editer`}>
              <Icon type="edit" />
            </Link>
            <div className="card-content medium">
              <div className="avatar-and-name">
                <div className="avatar-section">
                  <span>
                    {profile.avatar ? (
                      <img
                        className="user-avatar"
                        src={profile.avatar}
                        alt="avatar de l'utilisateur"
                      />
                    ) : (
                      <Tooltip title="Vous n'avez pas encore d'avatar">
                        <div className="no-avatar-info">
                          <Icon className="info-circle-avatar" type="user" />
                        </div>
                      </Tooltip>
                    )}
                  </span>
                </div>
                <div className="name-section">{profile.name}</div>
              </div>
              <div className="email-password">
                <div className="login-item">
                  <label>
                    <strong>Email</strong> :
                  </label>
                  <span>{profile.email}</span>
                </div>
                <div className="login-item">
                  <label>
                    <strong>Mot de passe</strong> :
                  </label>
                  <span>********</span>
                </div>
              </div>
            </div>
          </div>

          {/* {profile.roles[0] === 'EXPOSANT' && (
            <div className="setting-card subscription">
              <h4>Abonnement Mafoire.com</h4>
              <Link className="button-edit" to={`/mon-compte/abonnement/editer`}>
                <Icon type="edit" />
              </Link>
              
              {!subscriptions ? (
                <Loader />
              ) : (
                <div className="subscriptions">
                  {subscriptions.map(sub => (
                    <div key={sub.id} className="subscription-item">
                      <div className="subscription-item__details">
                        {sub.subscribed_until
                          ? `Abonné jusqu'au ${formatDate(sub.subscribed_until)}`
                          : 'Non-abonné'}
                        - {subscriptionStatuses[sub.status]}
                      </div>
                      {isCancellable(sub) && (
                        <Button onClick={() => cancelSubscription(sub.id)}>
                          Suspendre la prochaine échéance
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )} */}

          {account.has_ecommerce && profile.roles[0] === 'EXPOSANT' && (
            <div className="setting-card billing">
              <h4>Configuration de votre facturation e-commerce</h4>
              <Link className="button-edit" to={`/mon-compte/facturation/editer`}>
                <Icon type="edit" />
              </Link>
              <div className="card-content">
                <p className="text-center">
                  {account.stripe_user_id ? (
                    'Votre compte mafoire.com a déjà été lié à un compte stripe'
                  ) : (
                    <Row gutter={{ md: 24, lg: 24, xl: 24 }}>
                      <Col span={18}>
                        <React.Fragment>
                          Afin de recevoir le règlement de vos clients vous devez configurez votre
                          compte de facturation. Frais appliqués à chaque règlement: 1,4% + 0,25cts
                          €
                        </React.Fragment>
                      </Col>
                      <Col span={6}>
                        <Button type="primary" className="button-stripe-connect">
                          <a
                            href={`https://connect.stripe.com/oauth/authorize?state=${stripeURLState}&response_type=code&client_id=${window.process.env.APP_STRIPE_CLIENT_ID}&scope=read_write`}
                          >
                            Configurer
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </p>
              </div>
            </div>
          )}

          {profile.roles[0] === 'EXPOSANT' && (
            <div className="setting-card account">
              <h4>Informations du compte</h4>
              <Link className="button-edit" to={`/mon-compte/editer`}>
                <Icon type="edit" />
              </Link>
              <div className="card-content with-two-columns">
                <div className="column">
                  <Divider>Siège social</Divider>
                  <div className="field">
                    <label>
                      <strong>Entreprise</strong> :
                    </label>
                    <span>{account.name}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Secteur(s) d'activité</strong> :
                    </label>
                    {account.business_types.map((business_type, index) => (
                      <span key={index} className="business-type">
                        {business_type.name}
                      </span>
                    ))}
                  </div>
                  <div className="field">
                    <label>
                      <strong>Marque(s)/Produit(s) représenté.e(s)</strong> :
                    </label>
                    <span>{account.products_marks}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Adresse</strong> :
                    </label>
                    <span>{account.contact_address}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Code postal</strong> :
                    </label>
                    <span>{account.contact_zip}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Ville</strong> :
                    </label>
                    <span>{account.contact_city}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Pays</strong> :
                    </label>
                    <span>{account.contact_country}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Téléphone</strong> :
                    </label>
                    <span>{account.contact_phone}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Téléphone portable</strong> :
                    </label>
                    <span>{account.contact_mobile_phone}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>E-mail de contact</strong> :
                    </label>
                    <span>{account.contact_mail}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Site internet</strong> :
                    </label>
                    <span>{account.url_website}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Description</strong> :
                    </label>
                    <span className="account-description">{account.description}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Numéro SIREN</strong> :
                    </label>
                    <span>{account.siren_number}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Numéro TVA intracommunautaire</strong> :
                    </label>
                    <span>{account.vat_number}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Kbis</strong> :
                    </label>
                    {account.kbis_url ? (
                      <span>
                        <a href={account.kbis_url} target="_blank" rel="noopener noreferrer">
                          <Button title="Consulter" icon="eye">
                            Consulter
                          </Button>
                        </a>
                        {account.kbis_status === 1 ? (
                          <div className="no-logo-info">
                            <Icon
                              className="info-circle-logo"
                              type="check-circle"
                              theme="twoTone"
                              twoToneColor="#f49e42"
                            />
                            Kbis verifié
                          </div>
                        ) : (
                          <div className="no-logo-info">
                            <Icon
                              className="info-circle-logo"
                              type="close-circle"
                              theme="twoTone"
                              twoToneColor="#f49e42"
                            />
                            Kbis non verifié
                          </div>
                        )}
                      </span>
                    ) : (
                      <div className="no-logo-info">
                        <Icon
                          className="info-circle-logo"
                          type="info-circle"
                          theme="twoTone"
                          twoToneColor="#f49e42"
                        />
                        Vous n'avez pas encore entré votre Kbis
                      </div>
                    )}
                  </div>
                </div>

                <div className="column">
                  <Divider>Responsable du dossier</Divider>
                  <div className="field">
                    <label>
                      <strong>Nom du contact</strong> :
                    </label>
                    <span>{account.in_charge_name}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Téléphone du responable</strong> :
                    </label>
                    <span>{account.in_charge_phone}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Téléphone mobile du responsable</strong> :
                    </label>
                    <span>{account.in_charge_mobile_phone}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>E-mail du responsable</strong> :
                    </label>
                    <span>{account.in_charge_mail}</span>
                  </div>

                  <Divider>Agence</Divider>
                  <div className="field">
                    <label>
                      <strong>Adresse</strong> :
                    </label>
                    <span>{account.contact_address}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Code postal</strong> :
                    </label>
                    <span>{account.contact_zip}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Ville</strong> :
                    </label>
                    <span>{account.contact_city}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Téléphone</strong> :
                    </label>
                    <span>{account.contact_phone}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>E-mail de contact</strong> :
                    </label>
                    <span>{account.contact_mail}</span>
                  </div>
                  <div className="field">
                    <label>
                      <strong>Site internet</strong> :
                    </label>
                    <span>{account.url_website}</span>
                  </div>

                  <Divider>Options</Divider>
                  <div className="field">
                    <label>
                      <strong>Logo</strong> :
                    </label>
                    <span>
                      {account.url_logo ? (
                        <img
                          className="account-logo"
                          src={account.url_logo}
                          alt="logo de l'entreprise"
                        />
                      ) : (
                        <div className="no-logo-info">
                          <Icon
                            className="info-circle-logo"
                            type="info-circle"
                            theme="twoTone"
                            twoToneColor="#f49e42"
                          />
                          Vous n'avez pas encore de logo
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Account
