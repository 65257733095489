import React, { Component } from 'react'
import { Form, Input, Button, Spin, Icon, Upload, message } from 'antd'
import { checkFile } from '../../../helpers/functions'
import './EditConnexionAccountForm.css'

class EditConnexionAccountForm extends Component {
  state = {
    file: null,
    fileLocalUrl: null,
    loading: false,
  }

  componentDidUpdate(_, prevState) {
    if (this.state.file && prevState.file !== this.state.file) {
      this.computePreview()
    }
  }

  storeFile = file => {
    this.setState({ file })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { form, profile, editProfile } = this.props
    const { file } = this.state

    form.validateFields(async (err, values) => {
      if (err) return
      this.setState({ loading: true })
      const user = { ...values, file, id: profile.id, roles: profile.roles, avatar: profile.avatar }

      await editProfile(user)
      this.setState({ file: null, loading: false })
    })
  }

  computePreview = async () => {
    this.setState(prevState => ({ fileLocalUrl: URL.createObjectURL(prevState.file) }))
  }

  render() {
    const { form, profile, maxFileSize } = this.props
    const { getFieldDecorator } = form
    const { loading, file, fileLocalUrl } = this.state

    const antIcon = <Icon type="loading" style={{ fontSize: 48, color: 'blue' }} spin />

    const compareToFirstPassword = (rule, value, callback) => {
      const form = this.props.form
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!')
      } else {
        callback()
      }
    }

    const validateToNextPassword = (rule, value, callback) => {
      const form = this.props.form
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    }

    const handleConfirmBlur = e => {
      const value = e.target.value
      this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    return (
      <div className="add-account-wrapper center-container">
        <div className="form-title-wrapper">
          <h3>Identifiants de connexion</h3>
          <p>Utilisez le formulaire ci-dessous pour modifier vos paramètres de connexion</p>
        </div>
        {!profile ? (
          <div className="loader-wrapper">
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <Form className="add-account-form" onSubmit={this.handleSubmit}>
            <Form.Item label="Nom">
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Veuillez saisir cette information' }],
                initialValue: profile ? profile.name : '',
              })(<Input type="text" placeholder="Jean-pierre Dupont" />)}
            </Form.Item>

            <div className="picture">
              <div>
                <span className="avatar-label">Avatar:</span>

                {file && fileLocalUrl ? (
                  <div className="profile-avatar">
                    <img src={fileLocalUrl} alt={file.name} />
                  </div>
                ) : profile && profile.avatar ? (
                  <div className="profile-avatar">
                    <img src={profile.avatar} alt="Produit" />
                  </div>
                ) : (
                  <div className="no-avatar-info">
                    <Icon
                      className="info-circle-avatar"
                      type="info-circle"
                      theme="twoTone"
                      twoToneColor="#f49e42"
                    />
                    Vous n'avez pas encore d'avatar
                  </div>
                )}
              </div>

              <Upload
                name="file"
                beforeUpload={image => {
                  if (checkFile(image, message.error, ['image'], maxFileSize)) this.storeFile(image)
                  return false
                }}
                disabled={this.state.loading}
                showUploadList={false}
                customRequest={() => false}
              >
                <Button type="primary">
                  <Icon type="upload" />
                  <span className="label">Télécharger un fichier</span>
                </Button>
              </Upload>
            </div>

            <Form.Item label="E-mail">
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Veuillez saisir cette information' }],
                initialValue: profile ? profile.email : '',
              })(<Input type="text" placeholder="philippe.nicolas@gmail.com" />)}
            </Form.Item>
            <Form.Item label="Mot de passe">
              {getFieldDecorator('password', {
                rules: [
                  { required: false, message: 'Veuillez saisir cette information' },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(<Input type="password" placeholder="" />)}
            </Form.Item>
            <Form.Item label="Confirmez votre mot de passe">
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: false,
                    message: 'Veuillez confirmer votre mot de passe',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input type="password" onBlur={handleConfirmBlur} />)}
            </Form.Item>
            <Form.Item style={{ textAlign: 'right', marginTop: 20 }}>
              <Button type="primary" htmlType="submit">
                Enregistrer{loading && <Icon type="loading" />}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    )
  }
}

export default Form.create()(EditConnexionAccountForm)
