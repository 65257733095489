import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Icon } from 'antd'
import './Menu.css'

const RightMenu = ({ logout, newMessageCount }) => (
  <React.Fragment>
    <div className="nav-item">
      <NavLink to="/chat">
        <Icon type="message" />
        {!!newMessageCount && <span className="notification-dot">{newMessageCount}</span>}
      </NavLink>
    </div>
    <span className="nav-item">
      <NavLink activeClassName="active" className="link" to="/mon-compte">
        <Icon type="idcard" />
        <span className="label">Mon compte</span>
      </NavLink>
    </span>
    <span className="nav-item">
      <Button
        type="primary"
        className="logout-button"
        onClick={() => logout()}
        title="Déconnexion"
        shape="circle"
        icon="logout"
      />
    </span>
  </React.Fragment>
)

export default RightMenu
