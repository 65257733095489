import {
    httpWithAuth
} from '../helpers/http'

export const getStands = () => httpWithAuth('/api/stands')

export const getStand = async id => await httpWithAuth(`/api/stands/${id}`)

export const createStand = async payload => httpWithAuth('/api/stands', payload, {
    method: 'POST'
})

export const updateStand = payload =>
    httpWithAuth(`/api/stands/${payload.get('id')}/update`, payload, {
        method: 'POST'
    })

export const getSubscriptionEstimate = payload =>
    httpWithAuth(`/api/subscriptions/getSubscriptionEstimate`, payload, {
        method: 'POST'
    })

export const massEditStands = payload =>
    httpWithAuth(`/api/stands/updateSubscriptionStands`, payload, {
        method: 'POST'
    })

export const updateStandNoFormData = payload =>
    httpWithAuth(`/api/stands/${payload.id}/update`, payload, {
        method: 'POST'
    })

export const addStandCategory = payload =>
    httpWithAuth(`/api/stands/${payload.stand_id}/categories`, payload, {
        method: 'POST'
    })

export const updateStandCategory = payload =>
    httpWithAuth(`/api/stands/${payload.standId}/categories/${payload.id}`, payload, {
        method: 'PUT',
    })

export const deleteStandCategory = (standId, categoryId) =>
    httpWithAuth(`/api/stands/${standId}/categories/${categoryId}`, null, {
        method: 'DELETE'
    })

export const moveStandCategory = (standId, categoryId, direction) =>
    httpWithAuth(
        `/api/stands/${standId}/categories/${categoryId}/move`, {
            direction
        }, {
            method: 'PUT'
        }
    )

export const createShippingFees = async payload => httpWithAuth(`/api/stands/${payload.stand_id}/shippingfees`, payload, {
    method: 'POST'
})