import {
    httpWithAuth
} from '../helpers/http'

export const updateProfile = formDataProfile =>
    httpWithAuth(`/api/users/${formDataProfile.get('id')}`, formDataProfile, {
        method: 'POST',
    })

export const termsServiceApproved = () => httpWithAuth('/api/account/cgv', {}, {
    method: 'POST'
})