import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Base from '../Base'
import NewAccountForm from '../../common/login/NewAccountForm'
import { getBusinessTypes } from '../../../api/subscription'
import { getActiveDepartments} from '../../../api/departments'
import { message } from 'antd'


class NewAccountPage extends Component {

  state = {
    businessTypes: null,
    activeDepartments: null,
  }

  async componentDidMount() {
    try {
      const businessTypes = await getBusinessTypes()
      const activeDepartments = await getActiveDepartments()
      if (businessTypes.error && activeDepartments.error) throw new Error()
      this.setState({ businessTypes, activeDepartments })
    } catch (e) {
      message.error("Impossible d'afficher votre formulaire correctement")
      throw new Error(e)
    }
  }

  render() {
    const {
      newExposantIsSaving,
    } = this.props

    const { businessTypes } = this.state
    const { activeDepartments } = this.state

    return(
      <Base 
        breadcrumb={[{ label: 'Créer votre compte', to: '/login/creer' }]}>
        {businessTypes &&
          <NewAccountForm
            businessTypes={businessTypes}
            exposantsLoading={newExposantIsSaving}
            activeDepartments={activeDepartments}
          />
        }
      </Base>
    )
  }
}

export default connect(
  state => ({
    newExposantIsSaving: state.account.newExposantIsSaving,
    activeDepartments: state.stands.activeDepartments,
  }),
)(withRouter(NewAccountPage))
