export const LOAD_AGENTS_REQUEST = 'LOAD_AGENTS_REQUEST'
export const LOAD_AGENTS_SUCCESS = 'LOAD_AGENTS_SUCCESS'

export const EDIT_AGENT_REQUEST = 'EDIT_AGENT_REQUEST'
export const EDIT_AGENT_SUCCESS = 'EDIT_AGENT_SUCCESS'

export const ADD_AGENT_REQUEST = 'ADD_AGENT_REQUEST'
export const ADD_AGENT_SUCCESS = 'ADD_AGENT_SUCCESS'
export const ADD_AGENT_FAILURE = 'ADD_AGENT_FAILURE'

export const DELETE_AGENT_REQUEST = 'DELETE_AGENT_REQUEST'
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS'

export const HOME_LOADED = 'HOME_LOADED'